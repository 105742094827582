<template>
  <div>
    <div class="">
      <div class="row">

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="name">Name <star></star></label>
          <b-form-group id="name" label-for="name" class="akkurate-dark">
            <b-form-input v-model="form.name" id="name" class="mb-3 mt-2 field-container fields" type="text"
              placeholder="Enter Name" required>
            </b-form-input>
            <errors v-if="form.errors.name">
              {{ form.errors.name[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="interest_rate">Interest Rate %<star></star></label>
          <b-form-group id="interest_rate" label-for="interest_rate" class="akkurate-dark">
            <b-form-input v-model="form.interest_rate" id="interest_rate" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Interest Rate" step="any">
            </b-form-input>
            <errors v-if="form.errors.interest_rate">
              {{ form.errors.interest_rate[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="min_principal">Min Principal Amount<star></star></label>
          <b-form-group id="min_principal" label-for="min_principal" class="akkurate-dark">
            <b-form-input id="min_principal" v-model="form.min_principal" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Min Principal" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.min_principal">
              {{ form.errors.min_principal[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="max_principal">Max Principal Amount<star></star></label>
          <b-form-group id="max_principal" label-for="max_principal" class="akkurate-dark">
            <b-form-input id="max_principal" v-model="form.max_principal" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Max Principal" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.max_principal">
              {{ form.errors.max_principal[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="min_duration">Min Duration (Days)<star></star></label>
          <b-form-group id="min_duration" label-for="min_duration" class="akkurate-dark">
            <b-form-input id="min_duration" v-model="form.min_duration" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Min Duration" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.min_duration">
              {{ form.errors.min_duration[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="max_duration">Max Duration <star></star></label>
          <b-form-group id="max_duration" label-for="max_duration" class="akkurate-dark">
            <b-form-input id="max_duration" v-model="form.max_duration" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Max Duration " required>
            </b-form-input>
            <errors v-if="form.errors.max_duration">
              {{ form.errors.max_duration[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="min_membership_duration">Min Membership Duration (Days)<star></star></label>
          <b-form-group id="min_membership_duration" label-for="min_membership_duration" class="akkurate-dark">
            <b-form-input id="min_membership_duration" v-model="form.min_membership_duration"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Min Membership Duration"
              step="any" required>
            </b-form-input>
            <errors v-if="form.errors.min_membership_duration">
              {{ form.errors.min_membership_duration[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="min_share_balance">Min Share Balance <star></star></label>
          <b-form-group id="min_share_balance" label-for="min_share_balance" class="akkurate-dark">
            <b-form-input id="min_share_balance" v-model="form.min_share_balance"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.min_share_balance">
              {{ form.errors.min_share_balance[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="loan_processing_fee">Loan Processing Fee %<star></star></label>
          <b-form-group id="loan_processing_fee" label-for="loan_processing_fee" class="akkurate-dark">
            <b-form-input id="loan_processing_fee" v-model="form.loan_processing_fee"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.loan_processing_fee">
              {{ form.errors.loan_processing_fee[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="loan_premium_plan">Loan Premium Plan %<star></star></label>
          <b-form-group id="loan_premium_plan" label-for="loan_premium_plan" class="akkurate-dark">
            <b-form-input id="loan_premium_plan" v-model="form.loan_premium_plan"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.loan_premium_plan">
              {{ form.errors.loan_premium_plan[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="loan_form_amount">Loan Form Amount <star></star></label>
          <b-form-group id="loan_form_amount" label-for="loan_form_amount" class="akkurate-dark">
            <b-form-input id="loan_form_amount" v-model="form.loan_form_amount" class="mb-3 mt-2 field-container fields"
              type="text" placeholder="">
            </b-form-input>
            <errors v-if="form.errors.loan_form_amount">
              {{ form.errors.loan_form_amount[0] }}
            </errors>
          </b-form-group>
        </div>



        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="supervisor_approval_limit">Supervisor Approval Limit <star></star></label>
          <b-form-group id="supervisor_approval_limit" label-for="supervisor_approval_limit" class="akkurate-dark">
            <b-form-input id="supervisor_approval_limit" v-model="form.supervisor_approval_limit"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.supervisor_approval_limit">
              {{ form.errors.supervisor_approval_limit[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="branch_approval_limit">Branch Approval Limit <star></star></label>
          <b-form-group id="branch_approval_limit" label-for="branch_approval_limit" class="akkurate-dark">
            <b-form-input id="branch_approval_limit" v-model="form.branch_approval_limit"
              class="mb-3 mt-2 field-container fields" type="number" placeholder="" step="any" required>
            </b-form-input>
            <errors v-if="form.errors.branch_approval_limit">
              {{ form.errors.branch_approval_limit[0] }}
            </errors>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="branches">Branch <star></star></label>
          <b-form-group id="branches" label-for="branches" class="akkurate-dark">
            <multiselect v-model="form.branch" :options="branch_options" placeholder="Select Branches" track-by="name"
              label="name">
            </multiselect>
            <errors v-if="form.errors.branch_id">
              {{ form.errors.branch_id[0] }}
            </errors>
          </b-form-group>
        </div>
        <div class="row">

          <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="loan_approval_level">Loan Approval Levels <star></star></label>
            <b-form-group id="loan_approval_level" label-for="loan_approval_level" class="akkurate-dark">
              <multiselect v-model="form.loan_approval_level" :options="approval_options"
                placeholder="Select Loan Approval Level(s)" track-by="id" :limit-text="limitText" :limit="2"
                :multiple="true" label="name">
              </multiselect>
              <errors v-if="form.errors.loan_approval_level">
                {{ form.errors.loan_approval_level[0] }}
              </errors>
            </b-form-group>
          </div>


        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark" for="status">Status <star></star></label>
            </template>
            <b-form-radio v-model="form.status" name="status" value="1">Active</b-form-radio>
            <b-form-radio v-model="form.status" name="status" value="0">Inactive</b-form-radio>
            <errors v-if="form.errors.status">
              {{ form.errors.status[0] }}
            </errors>
          </b-form-group>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: ["form", "add_or_edit", 'approval_options', 'branch_options'],
  methods: {
    limitText(count) {
      return `and ${count} other approval levels`
    },
  }
}
</script>