<template>
  <div>
    <div class="container">
      <div class="row">
        <!-- <form @submit.prevent="searchMember"> -->
        <div class="card card-shape home-box mb-3 border border-success">
          <div class="card-body">
            <div class="row">
              <!-- <div class="col">
                <div class="input-group">
                  <multiselect class="field-container custom-border" v-model="code" :options="code_option" label="name"
                    selectLabel="" deselectLabel="">
                  </multiselect>
                </div>
              </div> -->
              <div class="col">
                <b-form-group id="search" label-for="search">
                  <b-input-group class="">
                    <b-form-input class="field-container fields" type="text" v-model="search" placeholder="Search by code, phone, fullname..."
                      ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group id="account_number" label-for="account_number">
                  <b-input-group class="">
                    <b-form-input class="field-container fields" type="text" v-model="account_number" placeholder="Account number"
                      ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-2">
                <div class="d-grid gap-2">
                  <b-button @click="searchMember" :disabled="is_searching"
                    class="fw-semibold akkurate-auth-size akkurate-green-btn">
                    <span class="small"><font-awesome-icon :icon="['fas', 'search']" /> </span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- </form> -->
        <div class="col-12 col-md-6 mb-3">
          <label class="akkurate-dark small" for="member_name">Member Select <star></star></label>
          <b-form-group id="account_number" label-for="member">
            <multiselect class="mt-2 field-container" :custom-label="fullName" v-model="form.member"
              :options="member_options" label="name" selectLabel="" deselectLabel=""
              @select="onSelect_member(form.member)">
            </multiselect>
            <errors v-if="form.errors.member">
              {{ form.errors.member[0] }}
            </errors>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="account_type">Account Type <star></star></label>
          <b-form-group id="account_type" label-for="account_type">
            <multiselect class="mt-2 field-container" v-model="form.account_type" :options="account_options"
              label="name">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="account_balance">Balance <star></star></label>
          <b-form-group id="account_balance" label-for="account_balance" class="akkurate-dark small">
            <b-form-input id="account_balance" v-model="form.account_balance" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Balance" disabled>
            </b-form-input>
          </b-form-group>
        </div>
        <!-- <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="member">Member <star></star></label>
          <b-form-group id="member" label-for="member">
            <multiselect class="mt-2 field-container" v-model="form.member" :options="member_options"
              :custom-label="fullName" placeholder="Select member by name" label="name" track-by="id"
               >
            </multiselect>
          </b-form-group>
        </div> -->
        <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="status">Status <star></star></label>
          <b-form-group id="status" label-for="status">
            <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <label class="akkurate-dark small" for="balance">Branch</label>
          <b-form-group label-for="branch" class="akkurate-dark small">
            <b-form-input id="branch" class="mb-3 mt-2 field-container fields" type="text" v-model="form.branch.name"
              placeholder="Enter Branch" required disabled>
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 mt-2">
          <b-form-group label-for="notes" class="mb-2">
            <template #label>
              <label class="akkurate-dark small" for="notes">Comment</label>
            </template>
            <b-form-textarea class="mt-2 fields" id="notes" v-model="form.notes"
              placeholder="Enter Notes..."></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="withdrawal_limit">Withdrawal Limit </label>
          <b-form-group label-for="withdrawal_limit" class="akkurate-dark small">
            <b-form-input id="withdrawal_limit" class="mb-3 mt-2 field-container fields" type="text"
              v-model="form.withdrawal_limit" placeholder="Enter Withdrawal limit">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark small" for="maximum_withdrawal_frequency">Maximum withdrawal frequency
          </label>
          <b-form-group id="maximum_withdrawal_frequency" label-for="maximum_withdrawal_frequency">
            <multiselect class="mt-2 field-container" v-model="form.maximum_withdrawal_frequency"
              :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>

        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark small" for="apply_commission">Apply interest</label>
            </template>
            <b-form-radio @change="interestSeleted" v-model="form.apply_interest" name="apply_interest"
              value="1">Yes</b-form-radio>
            <b-form-radio @change="interestSeleted" v-model="form.apply_interest" name="apply_interest"
              value="0">No</b-form-radio>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="apply_interest_show">
          <label class="akkurate-dark small" for="interest_rate">Interest rate </label>
          <b-form-group label-for="interest_rate" class="akkurate-dark small">
            <b-form-input id="interest_rate" class="mb-3 mt-2 field-container fields" type="text"
              v-model="form.interest_rate" placeholder="Enter Interest Rate">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-4" v-show="apply_interest_show">
          <label class="akkurate-dark small" for="interest_frequency">Interest frequency
          </label>
          <b-form-group id="interest_frequency" label-for="interest_frequency">
            <multiselect class="mt-2 field-container" v-model="form.interest_frequency" :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="apply_interest_show">
          <label class="akkurate-dark small" for="account_interest_start_date">Interest Start Date</label>
          <b-form-group id="account_interest_start_date" label-for="account_interest_start_date">
            <b-form-input class="mt-2 field-container fields" type="date"
              v-model="form.account_interest_start_date"></b-form-input>
          </b-form-group>
        </div>



        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark small" for="apply_commission">Apply commission</label>
            </template>
            <b-form-radio @change="commissionSeleted" v-model="form.apply_commission" name="apply_commission"
              value="1">Yes</b-form-radio>
            <b-form-radio @change="commissionSeleted" v-model="form.apply_commission" name="apply_commission"
              value="0">No</b-form-radio>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="apply_show">
          <label class="akkurate-dark small" for="commission">Commission Amount </label>
          <b-form-group label-for="commission" class="akkurate-dark small">
            <b-form-input id="commission" class="mb-3 mt-2 field-container fields" type="text" v-model="form.commission"
              placeholder="Enter Commission Amount">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-4" v-show="apply_show">
          <label class="akkurate-dark small" for="commission_frequency">Commision frequency
          </label>
          <b-form-group id="commission_frequency" label-for="commission_frequency">
            <multiselect class="mt-2 field-container" v-model="form.commission_frequency" :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="apply_show">
          <label class="akkurate-dark small" for="account_commission_start_date">Commission Start Date</label>
          <b-form-group id="account_commission_start_date" label-for="account_commission_start_date">
            <b-form-input class="mt-2 field-container fields" type="date"
              v-model="form.account_commission_start_date"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark small" for="auto_transfer">Auto transfer</label>
            </template>
            <b-form-radio @change="autoTransferSelected" v-model="form.auto_transfer" name="auto_transfer"
              value="1">Yes</b-form-radio>
            <b-form-radio @change="autoTransferSelected" v-model="form.auto_transfer" name="auto_transfer"
              value="0">No</b-form-radio>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="auto_transfer_show">
          <label class="akkurate-dark small" for="auto_transfer_frequency">Auto Transfer frequency
          </label>
          <b-form-group id="auto_transfer_frequency" label-for="auto_transfer_frequency">
            <multiselect class="mt-2 field-container" v-model="form.auto_transfer_frequency"
              :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="auto_transfer_show">
          <label class="akkurate-dark small" for="auto_transfer_amount">Auto Transfer Amount</label>
          <b-form-group id="auto_transfer_amount" label-for="auto_transfer_amount">
            <b-form-input class="mt-2 field-container fields" type="text"
              v-model="form.auto_transfer_amount"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-4" v-show="auto_transfer_show">
          <label class="akkurate-dark small" for="">Member Accounts
          </label>
          <b-form-group id="auto_transfer_account" label-for="auto_transfer_account">
            <multiselect class="mt-2 field-container" :custom-label="accountName" v-model="form.auto_transfer_account"
              :options="auto_transfer_account_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="auto_transfer_show">
          <label class="akkurate-dark small" for="auto_transfer_start_date">Auto Transfer Start Date</label>
          <b-form-group id="auto_transfer_start_date" label-for="auto_transfer_start_date">
            <b-form-input class="mt-2 field-container fields" type="date"
              v-model="form.auto_transfer_start_date"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";

export default {
  name: 'AccountForm',
  components: {
    Multiselect
  },
  data() {
    return {
      frequency_options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Mid-year', 'Annually'],
      code: { name: "Filter", value: "filter" },
      code_option: [
        { name: "Filter", value: "filter" },
        { name: "Account #", value: "account_number" },
      ],
      search: "",
      account_number:"",
      is_searching: false,
      search_btn: "Search",
      auto_transfer_show: false,
      member_options: [],
      apply_show: false,
      apply_interest_show: false,
      auto_transfer_show: false,
      auto_transfer_account_options:[],
    }
  },
  computed: {
    getBranch() {
      if (this.form.branch) {
        return this.form.branch.name ?? ""
      }
    }

  },
  methods: {
    fullName({ first_name, last_name, code }) {
      let name = ""
      if (first_name) {
        name = name + first_name;
      }

      if (last_name) {
        name = name + " " + last_name;
      }

      if (code) {
        name = name + ` (${code})`;
      }

      return `${name}`
    },
    accountName({account_type,account_number}){
      return `${account_type.name} (${account_number})`
    },
    onSelect_member(member) {
      this.form.branch = member.branch
      this.searchMemberAccount(member.external_code);
    },
    async searchMember() {
      this.is_searching = true;
      this.search_btn = "processing...";
      // let ac = "";
      // let filter = "";
      // if (this.code?.value == "account_number") {
      //   ac = this.search.toString();
      //   this.search.toString();
      // } else if (this.code?.value == "filter") {
      //   filter = this.search.toString();
      // } else {
      //   filter = this.search.toString();
      // }
      await ApiService.post("/customers/paginate", {
        page: 1,
        per_page: 10,
        filter: this.search,
        branch_id: "",
        region_id: "",
        status: "", //Inactive,	Active, Dormant, Closed
        account_number: this.account_number, //Inactive,	Active, Dormant, Closed
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0])
            this.form.member = response.data.data[0]
            this.member_options = response.data.data
          } else {
            swal.fire({
              icon: "error",
              title: "Not Found",
              text: "This account was not found please try again!!!",
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
          this.is_searching = false;
          this.search_btn = "Search";
        })
        .catch((error) => {
          this.is_searching = false;
          this.search_btn = "Search";
        });
    },
    async searchMemberAccount(code = null){
      await ApiService.post("/accounts/paginate", {
        page: 1,
        per_page: 10,
        filter: code,
        branch_id: "",
        region_id: "",
        status: "", //Inactive,	Active, Dormant, Closed
        account_number: "", //Inactive,	Active, Dormant, Closed
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data)
            this.auto_transfer_account_options = response.data.data
          } else {
            swal.fire({
              icon: "error",
              title: "Not Found",
              text: "This account was not found please try again!!!",
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
          this.is_searching = false;
          this.search_btn = "Search";
        })
        .catch((error) => {
          this.is_searching = false;
          this.search_btn = "Search";
        });
    },
    commissionSeleted(event) {
      if (event == "1") {
        this.apply_show = true
      } else {
        this.apply_show = false
      }
    },
    interestSeleted(event) {
      if (event == "1") {
        this.apply_interest_show = true
      } else {
        this.apply_interest_show = false
      }
    },
    autoTransferSelected(event) {
      if (event == "1") {
        this.auto_transfer_show = true
      } else {
        this.auto_transfer_show = false
      }
    }


  },
  props: ["form", "add_or_edit", "account_options"],
}
</script>