<!-- import EditAccountTypeVue from '../../member/accounts/account-type/edit/EditAccountType.vue'; -->
<template>
  <div v-if="form.status != 'REJECTED'" id="wrapper">
    <SidebarView />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <form @submit.prevent="approveVoucher">
            <div class="row">
              <div class="col-md-12">
                <button :disabled="isSaving" type="submit" class="btn akkurate-green-btn text-white btn-size mb-4">
                  <font-awesome-icon class="me-2" :icon="['fas', 'save']" />Approve
                </button>
              </div>
              <div class="col-md-12">
                <b-card-group deck>
                  <b-card>
                    <template #header>
                      <div class="py-1">
                        <h6 class="m-0 fw-bold text-green">Approve Voucher</h6>
                      </div>
                    </template>
                    <b-card-body>
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="search_term">Beneficiary Name</label>
                          <b-form-group id="search_term" label-for="search_term">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.beneficiary_name" read-only></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="beneficiary_phone">Beneficiary Phone</label>
                          <b-form-group id="beneficiary_phone" label-for="beneficiary_phone">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.beneficiary_phone"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="cheque_number">Cheque Number</label>
                          <b-form-group id="cheque_number" label-for="cheque_number">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.cheque_number">
                            </b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="amount_requested">Amount Requested</label>
                          <b-form-group id="amount_requested" label-for="amount_requested">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text" disabled
                              v-model="form.amount_requested"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="amount_approved">Amount Approved</label>
                          <b-form-group id="amount_approved" label-for="amount_approved">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text" required
                              v-model="form.amount_approved">
                            </b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="status">Status</label>
                          <b-form-group id="status" label-for="status">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.status"></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="reason">Reason</label>
                          <b-form-group id="reason" label-for="reason">
                            <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason">
                            </b-form-textarea>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="receipt_name">Receipt Name</label>
                          <b-form-group id="receipt_name" label-for="receipt_name">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.receipt_name" disabled></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="receipt_location">Receipt Location</label>
                          <b-form-group id="receipt_location" label-for="receipt_location">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.receipt_location" disabled></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="narration">Narration</label>
                          <b-form-group id="narration" label-for="narration">
                            <b-form-textarea class="mt-2 fields" id="narration"
                              v-model="form.narration"></b-form-textarea>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="recorded_date">Recorded Date</label>
                          <b-form-group id="recorded_date" label-for="recorded_date">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text" disabled
                              v-model="form.recorded_date"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <label class="akkurate-dark" for="branch">Branch</label>
                          <b-form-group id="branch" label-for="branch">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                              v-model="form.branch.name" disabled></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-card-group>
              </div>
              <div class="col-md-12 mt-4">
                <div class="err-accounts text-center">
                  <div class="err-accounts">
                    <errors v-if="errors">
                      {{ message }}
                    </errors>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h6 class="m-0 fw-bold text-green">Accounts</h6>
                  </div>
                  <div class="card-body">
                    <div>
                      <vue-good-table :columns="columns" :rows="form.vouch_journ_coa_accounts">
                        <!-- Add your custom delete button column -->
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field == 'action'">
                            <update-approve-account :data="props.row" :coa_subhead_options="coa_subhead_options"
                              @addAccount="addAccount"></update-approve-account>
                          </span>
                        </template>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <button :disabled="isSaving" type="submit" class="btn akkurate-green-btn text-white btn-size mb-4">
                  <font-awesome-icon class="me-2" :icon="['fas', 'save']" />Approve
                </button>
              </div>
            </div>
          </form>
          <voucher-receipt ref="showVoucherReceipt" :report_data="report_data"></voucher-receipt>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import ApiService from "@/core/services/api.service";
import UpdateApproveAccount from "./modal/UpdateApproveAccount.vue";
import VoucherReceipt from "./modal/VoucherReceipt.vue";
// import EditNextOfKin from '../edit/modal/EditNextOfKin.vue'

export default {
  components: {
    SidebarView,
    TopbarView,
    UpdateApproveAccount,
    VoucherReceipt
  },
  mounted() {
    // this.loadPageOptions();
    this.getMemberView();

  },
  data() {
    return {
      ...this.initiateState(),
      report_data: {},
      isSaving: false,
      message: "",
      //Accounts
      columns: [
        {
          label: '#',
          field: 'id',
        },
        {
          label: 'COA',
          field: 'coa_subhead.name',
        },
        // {
        //     label: 'Requested Amount',
        //     field: 'amount_requested',
        // },
        // {
        //     label: 'Amount Approved',
        //     field: 'amount_approved',
        // },
        {
          label: 'Dr Amount',
          field: 'dr_amount',
        },
        {
          label: 'Cr Amount',
          field: 'cr_amount',
        },
        {
          label: 'Dr Approved',
          field: 'dr_amount_approved',
        },
        {
          label: 'Cr Approved',
          field: 'cr_amount_approved',
        },
        {
          label: 'Action',
          field: 'action',

        }
      ],
    };
  },
  methods: {
    async getMemberView() {
      this.$Progress.start();
      await ApiService.get("/vouchers/view/" + this.$route.params.id)
        .then(response => {
          let data = response.data.data;
          this.form = {
            id: data.id,
            code: data.code,
            type: data.type,
            beneficiary_name: data.beneficiary_name,
            beneficiary_phone: data.beneficiary_phone,
            cheque_number: data.cheque_number,
            amount_requested: data.amount_requested,
            amount_approved: data.amount_requested,
            status: data.status,
            receipt_name: data.receipt_name,
            receipt_location: data.receipt_location,
            narration: data.narration,
            recorded_date: data.recorded_date,
            branch: data.branch,
            vouch_journ_coa_accounts: data.vouch_journal_coaaccounts.map((index) => {
              return {
                id: index.id,
                vouch_journ_id: index.vouch_journ_id,
                coa_subhead_id: index.coa_subhead_id,
                amount_requested: index.amount_requested,
                amount_approved: index.amount_approved,
                dr_amount: index.dr_amount,
                cr_amount: index.cr_amount,
                dr_amount_approved: index.dr_amount,
                cr_amount_approved: index.cr_amount,
                coa_subhead: index.coa_sub_head,
              }
            }),
          }
          //   console.log(response);
          this.branches = response.data.branches;
          this.coa_subhead_options = response.data.coa_subheads;
          this.$Progress.finish();
          // console.log(response.data);
        }).catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
    },
    addAccount(acc) {
      // console.log(acc);
      var account = [acc];
      // this.form.vouch_journ_coa_accounts.push(index);
      account.forEach(item_2 => {
        var index = this.form.vouch_journ_coa_accounts.findIndex(item_1 => item_1.id === item_2.id);
        if (index !== -1) {
          // if (item_2.dr_amount_approved == 0) {
          this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.cr_amount_approved;
          this.form.vouch_journ_coa_accounts[index].cr_amount_approved = item_2.cr_amount_approved;
          // } else {
          this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.dr_amount_approved;
          this.form.vouch_journ_coa_accounts[index].dr_amount_approved = item_2.dr_amount_approved;
          // }
          // this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.dr_amount_approved;
          // this.form.vouch_journ_coa_accounts[index].amount_approved = item_2.cr_amount_approved;
          this.form.vouch_journ_coa_accounts[index].coa_subhead = item_2.coa_subhead;
        }
      });
      // console.log(this.form);
    },
    async approveVoucher() {
      // console.log(this.form);
      this.$Progress.start();
      this.isSaving = true;
      await ApiService.post(`/vouchers/approve/${this.form.id}`, this.form)
        .then((response) => {
          this.isSaving = false;
          this.$Progress.finish();
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();
          this.$router.push({ path: '/voucher' });
          this.message = null;
        })
        .catch((error) => {
          // console.log(error);
          if (error.response.data.message) {
            this.message = error.response.data.message;
          }
          else {
            this.message = null;
          }
          this.isSaving = false;
          this.$Progress.fail();
          // if (error.response.data.errors) {
          //     this.form.errors = error.response.data.errors;
          // }
        });
    },
    // loadPageOptions() {
    //     this.$Progress.start();
    //     this.isLoading = true;
    //     ApiService.get("/vouchers/dropdown")
    //         .then((response) => {
    //             // console.log(response.data);
    //             this.coa_subhead_options = response.data.coa_subheads;
    //             // this.branch_options = response.data.branches;
    //             this.isLoading = false;
    //             this.$Progress.finish();
    //         })
    //         .catch((error) => {
    //             this.isLoading = false;
    //             this.$Progress.fail();
    //         });
    // },

    initiateState() {
      return {
        form: {
          id: "",
          code: "",
          type: "",
          beneficiary_name: "",
          beneficiary_phone: "",
          cheque_number: null,
          amount_requested: "",
          amount_approved: "",
          status: "",
          receipt_name: "",
          receipt_location: "",
          narration: "",
          recorded_date: "",
          branch: {},
          vouch_journ_coa_accounts: [],
          // coa_subheads:[],
        },
        branches: [],
        coa_subhead_options: [],
        branches: [],
        coa_subhead_options: [],
        errors: [],
        message: "",
      }
    }
    //   });
    // console.log(this.form);
  },
  async getInstitution() {
    await ApiService.get("/settings/institution")
      .then((response) => {
        // console.log(response);
        this.institution = response.data.data;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  async approveVoucher() {
    // console.log(this.form);
    this.$Progress.start();
    this.isSaving = true;
    // console.log(this.form);

    // await ApiService.post(`/vouchers/approve/${this.form.id}`, this.form)
    //   .then((response) => {
    //     console.log(response);
    //     this.isSaving = false;
    //     this.$Progress.finish();
    swal
      .fire({
        // title: response.data.transaction.trans_status,
        title: "Successfull Saved",
        // text: 'CODE : ' + response.data.transaction.code,
        text: "CODE : 14345664565",
        confirmButtonColor: "#018673",
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: "OK", // Customize the text for the "OK" button
        cancelButtonText: "Print", // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton:
            "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
          cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ path: '/voucher' });
        } else if (result.isDismissed) {
          // this.$bvModal.hide(this.data.account_number + this.transaction);
          this.$refs.showVoucherReceipt.showModal();
          // let data = response.data?.transaction
          console.log(this.form);
          this.report_data = {
            institution: this.institution,
            coa_accounts: this.form.vouch_journ_coa_accounts,
          };
          // this.$router.push({ path: '/voucher' });
        }
      }).catch((error) => {

        console.log(error);

      });
    this.isSaving = false;
    // this.form.reset();
    // this.$router.push({ path: '/voucher' });
    // })
    // .catch((error) => {
    //   // console.log(error);
    //   this.isSaving = false;
    //   this.$Progress.fail();
    //   if (error.response?.data) {
    //     this.errors = error.response.data;
    //     console.log(error.response.data);
    //     this.message =  error.response.data.message;
    //   }
    //   //error message
    //   // if (error.response) {
    //   //   this.form.errors = error.response.data;
    //   //   this.form.message = error.response.data.message;
    //   // }


    // });
  },
  // loadPageOptions() {
  //     this.$Progress.start();
  //     this.isLoading = true;
  //     ApiService.get("/vouchers/dropdown")
  //         .then((response) => {
  //             // console.log(response.data);
  //             this.coa_subhead_options = response.data.coa_subheads;
  //             // this.branch_options = response.data.branches;
  //             this.isLoading = false;
  //             this.$Progress.finish();
  //         })
  //         .catch((error) => {
  //             this.isLoading = false;
  //             this.$Progress.fail();
  //         });
  // },

  //   },
};
</script>