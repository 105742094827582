<template>
    <div>
        <errors v-if="error_message" style="text-align: center;">
            {{ error_message }}
        </errors>
        <form @submit.prevent="saveLoanAdjustment()">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="m-0 fw-bold text-green">Loan Adjustment</h6>
                        <!-- <b-button class="btn btn-sm akkurate-green-btn" type="submit" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-plus"></i> {{ save }}</span>
                        </b-button> -->
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="adjustment_type">
                                <template #label>
                                    <label class="akkurate-dark small" for="adjustment_type">Adjustment Type <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="adjustment_type" :options="adjustment_type_options" selectLabel=""
                                    deselectLabel="" :show-labels="false"
                                    class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.adjustment_type">
                                    {{ form.errors.adjustment_type[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2"
                            v-show="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL' || this.adjustment_type == 'LOAN TERM'">
                            <b-form-group label-for="interest_method">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_method">Interest Method <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_method" :options="interest_method_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" @select="interestMethod"
                                    class="mt-2 field-container" placeholder="Pick a value"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'">
                                </multiselect>
                                <errors v-if="form.errors.interest_method">
                                    {{ form.errors.interest_method[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2"
                            v-show="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL' || this.adjustment_type == 'LOAN TERM'">
                            <b-form-group label-for="interest_balance">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_balance">Interest Balance <star>
                                        </star></label>
                                </template>
                                <b-form-input id="interest_balance" name="interest_balance"
                                    class="mt-2 field-container fields" type="text"
                                    v-model="form.interest_balance"></b-form-input>
                                <errors v-if="form.errors.interest_balance">
                                    {{ form.errors.interest_balance[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2"
                            v-show="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL' || this.adjustment_type == 'LOAN TERM'">
                            <b-form-group label-for="interest_due">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_due">Interest Due <star></star>
                                        </label>
                                </template>
                                <b-form-input id="interest_due" name="interest_due" class="mt-2 field-container fields"
                                    type="text" v-model="form.interest_due"></b-form-input>
                                <errors v-if="form.errors.interest_due">
                                    {{ form.errors.interest_due[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2"
                            v-show="this.adjustment_type == 'INTEREST AND PRINCIPAL' || this.adjustment_type == 'LOAN TERM'">
                            <b-form-group label-for="amount">
                                <template #label>
                                    <label class="akkurate-dark small" for="amount">Principal <star></star></label>
                                </template>
                                <b-form-input id="amount" name="amount" class="mt-2 field-container fields" type="text"
                                    v-model="form.principal_amount"></b-form-input>
                                <errors v-if="form.errors.principal_amount">
                                    {{ form.errors.principal_amount[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2"
                            v-show="this.adjustment_type == 'INTEREST AND PRINCIPAL' || this.adjustment_type == 'LOAN TERM'">
                            <b-form-group label-for="principal_due">
                                <template #label>
                                    <label class="akkurate-dark small" for="principal_due">Principal Due</label>
                                </template>
                                <b-form-input id="principal_due" name="principal_due"
                                    class="mt-2 field-container fields" type="text"
                                    v-model="form.principal_due"></b-form-input>
                                <errors v-if="form.errors.principal_due">
                                    {{ form.errors.principal_due[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate">Interest Rate % <star></star>
                                    </label>
                                </template>
                                <b-form-input id="interest_rate" name="interest_rate"
                                    class="mt-2 field-container fields" type="text" step="any"
                                    v-model="form.interest_rate"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"></b-form-input>
                                <errors v-if="form.errors.interest_rate">
                                    {{ form.errors.interest_rate[0] }}
                                </errors>

                            </b-form-group>
                        </div>


                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_rate_frequency">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate
                                        Frequency
                                        <star></star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_rate_frequency"
                                    :options="interest_rate_frequency_options" selectLabel="" deselectLabel=""
                                    :show-labels="false" class="mt-2 field-container"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.interest_rate_frequency">
                                    {{ form.errors.interest_rate_frequency[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_start_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_start_date">Interest Start Date
                                        <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="interest_start_date" name="interest_start_date"
                                    class="mt-2 field-container fields" type="date" v-model="form.interest_start_date"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'">
                                </b-form-input>
                                <errors v-if="form.errors.interest_start_date">
                                    {{ form.errors.interest_start_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <label class="akkurate-dark small">Tenure <star></star></label>
                            <b-form-group>
                                <b-form-input id="tenor" name="tenor" class="mt-2 field-container fields" type="number"
                                    v-model="form.tenor"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"></b-form-input>
                                <errors v-if="form.errors.tenor">
                                    {{ form.errors.tenor[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="tenor_interval">
                                <template #label>
                                    <label class="akkurate-dark small" for="tenor_interval">Tenure Interval <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.tenor_interval" :options="tenor_interval_options"
                                    selectLabel="" deselectLabel="" :show-labels="false"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"
                                    class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.tenor_interval">
                                    {{ form.errors.tenor_interval[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="repayment_cycle">
                                <template #label>
                                    <label class="akkurate-dark small" for="repayment_cycle">Repayment Cycle <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.repayment_cycle" :options="repayment_cycle_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'">
                                </multiselect>
                                <errors v-if="form.errors.repayment_cycle">
                                    {{ form.errors.repayment_cycle[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="maturity_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="maturity_date">Maturity Date</label>
                                </template>
                                <b-form-input id="maturity_date" name="maturity_date" v-model="form.maturity_date"
                                    class="mt-2 field-container fields" type="date" disabled></b-form-input>
                                <errors v-if="form.errors.maturity_date">
                                    {{ form.errors.maturity_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="penalty_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="penalty_rate">Penalty Rate <star></star>
                                    </label>
                                </template>
                                <b-form-input id="penalty_rate" name="penalty_rate" class="mt-2 field-container fields"
                                    type="number" v-model="form.penalty_rate"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"></b-form-input>
                                <errors v-if="form.errors.penalty_rate">
                                    {{ form.errors.penalty_rate[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="reason">
                                <template #label>
                                    <label class="akkurate-dark small" for="reason">Reason <star></star></label>
                                </template>
                                <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason"
                                    placeholder="Enter Reason..."
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"></b-form-textarea>
                                <errors v-if="form.errors.reason">
                                    {{ form.errors.reason[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <label class="akkurate-dark small" for="purpose">Purpose</label>
                            <b-form-group id="type" label-for="purpose" class="akkurate-dark">
                                <multiselect class="field-container mt-2" v-model="form.purpose" name="purpose"
                                    placeholder="Search or select a Purpose"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"
                                    :options="purpose_options">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <label class="akkurate-dark small" for="lpp_type">LPP Type</label>
                            <b-form-group id="lpp_type" name="lpp_type" label-for="lpp_type"
                                :description="lpp_description">
                                <multiselect id="lpp_type" track-by="id" name="lpp_type" class="mt-2 field-container"
                                    @select="selectLPP" label="name"
                                    :disabled="this.adjustment_type == 'INTEREST' || this.adjustment_type == 'INTEREST AND PRINCIPAL'"
                                    v-model="form.lpp_type" :options="lpp_type_options">
                                </multiselect>
                            </b-form-group>
                            <b-form-invalid-feedback>
                                {{ veeErrors.first('lpp_type') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="lpp_value" :description="interest_expected_description">
                                <template #label>
                                    <label class="akkurate-dark small" for="lpp_value">LPP Value <star></star></label>
                                </template>
                                <b-form-input id="lpp_value" name="lpp_value" class="mt-2 field-container fields"
                                    type="text" v-model="form.lpp_value" disabled></b-form-input>
                                <errors v-if="form.errors.lpp_value">
                                    {{ form.errors.lpp_value[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="lpf_value" :description="loan_lpf_description">
                                <template #label>
                                    <label class="akkurate-dark small" for="lpf_value">LPF Value <star></star></label>
                                </template>
                                <b-form-input id="lpf_value" name="lpf_value" class="mt-2 field-container fields"
                                    type="text" v-model="form.lpf_value" disabled></b-form-input>
                                <errors v-if="form.errors.lpf_value">
                                    {{ form.errors.lpf_value[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <hr class="mt-3" />
                        <div class="col-12 text-end">
                            <button type="submit" :disabled="isSaving"
                                class="btn akkurate-green-btn text-white btn-size me-2"><font-awesome-icon class="me-2"
                                    :icon="['fas', 'save']" />{{ save }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {{ permissionVisibility }}


    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { hasPermission } from "@/helper";
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect
    },
    props: ['data', 'institution', 'adjustment'],
    data() {
        return {
            ...this.initialState(),
            interest_method_options: ["straight-line", "reducing-balance", "hybrid"],
            interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            variable_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            variable_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            tenor_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            repayment_cycle_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            purpose_options: ['Education', 'Agriculture', 'Trading', 'Realestate', 'Car', 'Health', 'Marriage', 'Funeral', 'Litigation', 'Other Purpose'],
            straight_line: false,
            reducing_balance: false,
            hybrid: true,
            save: "Adjust",
            isSaving: false,
            error_message: "",
            lpp_type_options: [
                { id: 'cua_standard', name: 'CUA STANDARD' },
                { id: 'incure_interest', name: 'INCURE INTEREST' },
                { id: 'custom', name: 'CUSTOM' }
            ],
            lpp_description: "",
            interest_expected_description: "",
            loan_lpf_description: "",
            adjustment_type_options: [],
            adjustment_type: ""
        }
    },
    computed: {
        permissionVisibility() {
            if (hasPermission('loan_management_adjust_term')) {
                this.adjustment_type_options = ['INTEREST', 'INTEREST AND PRINCIPAL', 'LOAN TERM'];
                this.adjustment_type = 'LOAN TERM'
            } else if (hasPermission('loan_management_interest_principal')) {
                this.adjustment_type_options = ['INTEREST', 'INTEREST AND PRINCIPAL'];
                this.adjustment_type = 'INTEREST AND PRINCIPAL'
            } else if (hasPermission('loan_management_interest_only')) {
                this.adjustment_type_options = ['INTEREST'];
                this.adjustment_type = 'INTEREST';
            } else {
                this.adjustment_type_options = ['INTEREST'];
                this.adjustment_type = 'INTEREST';
            }

            // if(hasPermission('loan_management_adjust_term')){
            //     this.show_interest_method = false
            //     this.show_principal = false
            //     this.show_interest_rate = false
            //     this.show_tenor = false
            //     this.show_repayment_cycle = false,
            //     this.show_the_rest = false
            // } else if(hasPermission('loan_management_interest_principal')){
            //     this.show_interest_method = false
            //     this.show_principal = false
            //     this.show_interest_rate = false
            //     this.show_tenor = true
            //     this.show_repayment_cycle = true,
            //     this.show_the_rest = true
            // }else if(hasPermission('loan_management_interest_only')){
            //     this.show_interest_method = false
            //     this.show_principal = true
            //     this.show_interest_rate = false
            //     this.show_tenor = true
            //     this.show_repayment_cycle = true
            //     this.show_the_rest = true
            // }
        },
        // principalVisible() {
        //     if(hasPermission('loan_management_interest_only') || hasPermission('loan_management_interest_principal')){
        //         this.interest_only = true
        //         this.interest_principal = true
        //     }
        //     // Returns true or false based on permission logic
        //     // return !hasPermission('loan_management_interest_principal')
        // }
    },
    methods: {
        saveLoanAdjustment() {
            this.isSaving = true;
            this.$Progress.start();
            this.save = "Forwarding..."
            let adjustment = ""
            switch (this.adjustment_type) {
                case 'INTEREST':
                    adjustment = 'interest';
                    break;
                case 'INTEREST AND PRINCIPAL':
                    adjustment = 'interest_principal';
                    break;
                case 'LOAN TERM':
                    adjustment = 'loan_term';
                    break;
            }
            ApiService.post('/loans/loanAdjustment/' + adjustment, this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    this.save = "Adjust"
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    let loan_id = response.data?.data?.id;
                    // this.$emit('loan_no', loan_id);
                    this.$emit('loanAdjustmenetSaved');
                    this.form = this.initialState().form;
                    this.error_message = "";

                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.save = "Adjust"
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                        // console.log(error)
                    }

                    if (error.response.data.message) {
                        this.error_message = error.response.data.message;
                    }
                });
        },

        initialState() {
            return {
                form: {
                    loan_number: this.data.loan.loan_number,
                    expected_interest: this.data.loan.expected_interest,
                    // loanproduct_id: this.data.loan.account_number,
                    interest_method: this.data.loan.interest_method, // ['straight_line', 'reducing-balance', 'hybrid']
                    principal_amount: this.data.loan.principal_amount,
                    interest_rate: this.data.loan.interest_rate,
                    interest_rate_frequency: this.data.loan.interest_rate_frequency, // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    interest_start_date: this.data.loan.interest_start_date,
                    lpp_type: this.setLppTypes(this.data.loan.lpp_type),
                    lpp_value: this.data.loan.lpp_value,
                    lpf_value: this.data.loan.lpf_value,
                    tenor: this.data.loan.tenor, // integer 
                    tenor_interval: this.data.loan.tenor_interval, //['Days', 'Weeks', 'Months', 'Years']
                    repayment_cycle: this.data.loan.repayment_cycle, // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    maturity_date: this.data.loan.maturity_date,
                    penalty_rate: this.data.loan.penalty_rate, // Penalty fee
                    reason: this.data.loan.reason,
                    purpose: this.data.loan.purpose,
                    interest_balance: this.data.loan.interest_balance,
                    interest_due: this.data.loan.interest_due,
                    principal_due: this.data.loan.principal_due,
                    credit_officer: 1, // "User ID"
                    recovery_officer: 2, // "User ID"
                    errors: [],
                }

            }
        },
        setLppTypes(lpp_type_value) {
            if (lpp_type_value == 'cua_standard') {
                return { id: lpp_type_value, name: 'CUA STANDARD' };

            } else if (lpp_type_value == 'incure_interest') {
                return { id: lpp_type_value, name: 'INCURE INTEREST' }
            } else if (lpp_type_value == 'custom') {
                return { id: lpp_type_value, name: 'CUSTOM' }

            } else {
                return { id: '', name: '' }
            }
        },
        interestMethod(value) {
            // console.log(value);
            // switch (value) {
            //     case 'straight-line':
            //         this.straight_line = false;
            //         this.hybrid = true;
            //         break;
            //     case 'reducing-balance':
            //         this.straight_line = true;
            //         this.hybrid = true;
            //         break;
            //     case 'hybrid':
            //         this.straight_line = true;
            //         this.hybrid = false;
            //         break;
            // }
        },

        selectLPP(value) {
            console.log(this.data)
            let principal = this.form.principal_amount ?? 0;
            let no_of_months = this.lppMonths() ?? 0;
            let tenor_pecent = this.form.interest_rate / 100;
            let expected_interest = principal * tenor_pecent * this.form.tenor;
            let principal_interest = Number(principal) + Number(expected_interest);
            console.log(principal_interest)
            if (principal != 0 && this.form.tenor != "" && this.form.tenor_interval != "") {
                switch (value.id) {
                    case 'cua_standard':
                        this.lpp_description = "Principal * Nò of Month/1000"
                        this.interest_expected_description = ""
                        // console.log(this.lppMonths())
                        this.form.lpp_value = principal * (no_of_months / 1000);
                        break;
                    case 'incure_interest':
                        // console.log('Tenor Pecentage', tenor_pecent);
                        // console.log('Expected Interest', expected_interest);
                        this.interest_expected_description = "Expected Interest : " + expected_interest;
                        this.lpp_description = "Principal + Interest * Nò of Month/1000"
                        this.form.lpp_value = principal_interest * (no_of_months / 1000);
                        break;
                    case 'custom':
                        this.lpp_description = "Principal * loan_premium_plan %"
                        this.form.lpp_value = principal * (this.data.loan?.loan_product?.loan_premium_plan / 100)
                        this.interest_expected_description = "Loan Premium plan : " + this.data.loan?.loan_product?.loan_premium_plan + "%"
                        break;
                }
                this.form.lpf_value = principal * (this.data.loan?.loan_product?.loan_processing_fee / 100);
                this.loan_lpf_description = "Loan Processing fees : " + this.data.loan?.loan_product?.loan_processing_fee + "%"

            }

        },
        lppMonths() {
            if (this.form.tenor_interval === 'Months') {
                return this.form.tenor;
            }
        }
    },
}
</script>