<!-- <template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="text-center">
      
    </div>
  </div>
</template> -->
<template>
  <div id="wrapper">
    <SidebarView />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <!-- begin row  -->
          <div class="row">
            <div class="col-lg-12 text-center vh-100">
              <div class="alert alert-danger">
                <h2 class="alert-heading">Access Denied!</h2>
                <p>You do not have the necessary permissions to view this page.</p>
                <hr>
                <p class="mb-0">
                  Please contact your system administrator if you believe this is a mistake.
                </p>
              </div>
              <router-link to="/" class="btn akkurate-green-btn mt-3 text-white">
                <span class="akkurate-small"> <i class="fas fa-house-damage"></i> Dashboard</span>
              </router-link>

            </div>
          </div>

        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';

export default {
  
  name: "Unauthorized",
  components:{
    SidebarView,
    TopbarView,
    Footer
  }
};
</script>

<style scoped>
/* Ensure the page takes full height and centers content */
.container {
  height: 100vh;
}
</style>
