<template>
  <div id="wrapper">
    <SidebarView :all-transaction="true" :transaction="true" :transVisible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />

        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold text-green">Deposits / Withdrawals</h6>

              <div class="d-flex align-items-center">
                <b-button class="btn-sm ms-5 me-2 akkurate-green-btn akkurate-small" @click="generateExcel">
                  <font-awesome-icon :icon="['fas', 'file-excel']" />
                </b-button>
              </div>
            </div>
            <div class="card-body">
              <div class="card card-shape home-box mb-3 border border-success">
                <div class="row text-center my-2">
                  <div class="col-12" v-if="this.form.users.length > 0">
                    <p class="mb-0 my-3 small">Selected Users: <span class="fw-bold">{{ coa_name }}</span></p>
                  </div>
                  <div class="col">
                    <p class="mb-0 small"><font-awesome-icon :icon="['fas', 'circle']" class="akkurate-green" /> Total
                      Completed Deposits: <span class="fw-bold">{{ displayNumber(total_complete_deposits) }}</span></p>

                  </div>
                  <div class="col-4">
                    <p class="mb-0 small"><font-awesome-icon :icon="['fas', 'circle']" class="akkurate-danger" /> Total
                      Completed Withdrawals: <span class="fw-bold">{{ displayNumber(total_complete_withdrawals)
                        }}</span>
                    </p>
                  </div>
                  <div class="col-4">
                    <p class="mb-0 small"><font-awesome-icon :icon="['fas', 'circle']" class="akkurate-grey" /> Total
                      Balance: <span class="fw-bold">{{ displayNumber(total_difference) }}</span></p>

                  </div>




                </div>
                <hr class="m-0" />
                <div class="card-body">
                  <transaction-form :form="form" :users="users" @submitFilters="submitFormFilters"></transaction-form>
                </div>
              </div>
              <div>
                <vue-good-table styleClass="vgt-table bordered striped" mode="remote" ref="allAccountTable"
                  :columns="trans_columns" :rows="rows" :isLoading.sync="isLoading" :totalRows="totalRecords"
                  :pagination-options="{
                    enabled: true,
                    perPage: serverParams.perPage,
                    setCurrentPage: serverParams.page,
                  }" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange">
                  <!-- Add your custom delete button column -->
                  <!-- <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'action'">

                                        <div class="dropdown no-arrow d-inline">
                                            <a class="btn btn-sm me-2 akkurate-ash text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <font-awesome-icon :icon="['fas', 'money-bill']" /> <span>deposit</span>
                                            </a>
                                            <div class="dropdown-menu shadow animated--fade-in"
                                                aria-labelledby="dropdownMenu">
                                                <div class="dropdown-header">Deposit</div>
                                                <a class="dropdown-item" href="#">Cash</a>
                                                <a class="dropdown-item" href="#">Cheque</a>
                                                <a class="dropdown-item" href="#">Mobile Money</a>
                                                <a class="dropdown-item" href="#">Direct Debit</a>
                                            </div>
                                        </div>

                                        <div class="dropdown no-arrow d-inline">
                                            <a class="btn btn-sm me-2 akkurate-ash text-white dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <font-awesome-icon :icon="['fas', 'money-bill']" /> <span>withdraw</span>
                                            </a>
                                            <div class="dropdown-menu shadow animated--fade-in"
                                                aria-labelledby="dropdownMenu">
                                                <div class="dropdown-header">Withdraw</div>
                                                <a class="dropdown-item" href="#">Cash</a>
                                                <a class="dropdown-item" href="#">Cheque</a>
                                                <a class="dropdown-item" href="#">Mobile Money</a>
                                                <a class="dropdown-item" href="#">Direct Debit</a>
                                            </div>
                                        </div>
                                    </span>
                                    </template> -->

                  <template slot="table-row" slot-scope="props">
                    <span class="d-flex" v-if="props.column.field == 'action'">
                      <div title="Print" class="dropdown no-arrow">
                        <a title="Print" class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                          id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span class="akkurate-small">
                            <font-awesome-icon :icon="['fas', 'book']" />
                          </span>
                        </a>
                        <div class="dropdown-menu shadow animated--fade-in" aria-labelledby="dropdownMenu">
                          <div class="dropdown-header">Printout</div>
                          <button v-if="props.row.trans_status == 'COMPLETED'" class="dropdown-item"
                            @click="printReceipt(props.row)">
                            Print receipts
                          </button>
                          <button @click="chequePrintout(props.row)" v-if="
                            props.row.transaction_mode == 'Cheque' &&
                            props.row.transaction_type == 'Withdrawal'
                          " class="dropdown-item">
                            Cheque print
                          </button>
                          <button @click="chequeTemplate(props.row)" v-if="
                            props.row.transaction_mode == 'Cheque' &&
                            props.row.transaction_type == 'Withdrawal'
                          " class="dropdown-item">
                            Cheque voucher
                          </button>
                        </div>
                      </div>

                      <!-- <button :key="props.row.id + 'APPROVE'" title="APPROVE" class="btn btn-sm akkurate-green-btn" v-if="props.row.trans_status == 'PENDING'" @click="approveWithdrawal(props.row)">
                                                <span class="akkurate-small" style="color:white"><font-awesome-icon :icon="['fas', 'check']" /> </span>
                                            </button> -->

                      <!-- <button :key="props.row.id + 'REJECT'" title="REJECT" class="btn btn-sm btn-danger" @click="rejectWithdrawal(props.row)" v-if="props.row.trans_status == 'PENDING'">
                                                <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'cancel']" /> </span>
                                            </button> -->

                      <all-trans-view :key="props.row.id + 'View'" :data="props.row"
                        :transaction="'View'"></all-trans-view>

                      <a v-if="
                        props.row.is_reversed == 0 &&
                        props.row.trans_status == 'COMPLETED'
                      " title="Reverse" class="btn btn-sm akkurate-primary text-white"
                        @click="reverseTransaction(props.row)">
                        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'backward']" /></span>
                      </a>

                      <a v-if="props.row.trans_status == 'PENDING' &&
                        props.row.transaction_type == 'Withdrawal'" title="Approve"
                        class="btn btn-sm akkurate-green-btn text-white" @click="approveWithdrawal(props.row)">
                        <span class="akkurate-small">
                          <font-awesome-icon :icon="['fas', 'check']" />
                        </span>
                      </a>
                    </span>


                    <span class="d-flex" v-else-if="props.column.field == 'user'">
                      {{ props.row.created_user?.first_name }} {{ props.row.created_user?.surname }}
                    </span>

                    <span class="d-flex" v-else-if="props.column.field == 'trans_status'">
                      <span class="badge "
                        :class="props.row.trans_status == 'COMPLETED' ? 'bg-success' : (props.row.trans_status == 'REVERSED' ? 'bg-danger' : 'bg-orange')">{{
                          props.row.trans_status }}</span>
                    </span>


                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>

    <view-transaction-modal :showViewTransaction="showViewTransaction"></view-transaction-modal>
    <cash-deposit ref="showCashDeposit" :report_data="report_data"></cash-deposit>
    <cash-withdrawal ref="showCashWithdrawal" :report_data="report_data"></cash-withdrawal>
    <cheque-deposit ref="showChequeDeposit" :report_data="report_data"></cheque-deposit>
    <cheque-withdrawal ref="showChequeWithdrawal" :report_data="report_data"></cheque-withdrawal>
    <momo-deposit ref="showMomoDeposit" :report_data="report_data"></momo-deposit>
    <momo-withdrawal ref="showMomoWithdrawal" :report_data="report_data"></momo-withdrawal>
    <direct-debit-deposit ref="showDirectDebitDeposit" :report_data="report_data"></direct-debit-deposit>
    <direct-debit-withdrawal ref="showDirectDebitWithdrawal" :report_data="report_data"></direct-debit-withdrawal>
    <cheque-printout ref="showChequePrintout" :report_data="report_data"></cheque-printout>
    <cheque-template ref="showChequeTemplate" :report_data="report_data"></cheque-template>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TransactionForm from "./form/TransactionForm.vue";
import ApiService from "@/core/services/api.service";
import ViewTransactionModal from "./modals/ViewTransactionModal.vue";
import AllTransView from "./view/AllTransView.vue";
import ChequeDeposit from "@/views/main/pages/transactions/reports/cheque/ChequeDeposit.vue";
import ChequeWithdrawal from "@/views/main/pages/transactions/reports/cheque/ChequeWithdrawal.vue";
import CashDeposit from "@/views/main/pages/transactions/reports/cash/CashDeposit.vue";
import CashWithdrawal from "@/views/main/pages/transactions/reports/cash/CashWithdrawal.vue";
import MomoDeposit from "@/views/main/pages/transactions/reports/momo/MomoDeposit.vue";
import MomoWithdrawal from "@/views/main/pages/transactions/reports/momo/MomoWithdrawal.vue";
import DirectDebitDeposit from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitDeposit.vue";
import DirectDebitWithdrawal from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitWithdrawal.vue";
import ChequePrintout from "../reports/printout/ChequePrintout.vue";
import ChequeTemplate from "../reports/printout/ChequeTemplate.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    TransactionForm,
    ViewTransactionModal,
    AllTransView,
    CashDeposit,
    CashWithdrawal,
    ChequeDeposit,
    ChequeWithdrawal,
    MomoDeposit,
    MomoWithdrawal,
    DirectDebitDeposit,
    DirectDebitWithdrawal,
    ChequePrintout,
    ChequeTemplate,
  },
  data() {
    return {
      ...this.initialState(),
      trans_columns: [
        {
          label: "Reference",
          field: "code",
        },
        {
          label: "Full_Name",
          //field: 'customer.first_name',
          field: this.getFullname,
          title: this.getFullname,
        },
        {
          label: "Amount",
          field: "trans_amount",
        },
        {
          label: "Account_Number",
          field: "account.account_number",
        },
        {
          label: "Trans_Type",
          field: "transaction_type",
        },
        {
          label: "Phone_Number",
          field: "customer.mobile",
        },
        {
          label: "Ext_Ref",
          field: "external_reference",
        },
        {
          label: "Status",
          field: "trans_status",
        },
        {
          label: "Date",
          field: 'trans_date',
          // field: this.getCreatedAt,
        },
        {
          label: "Source",
          field: "source",
        },
        {
          label: "User",
          field: "user",
        },
        {
          label: "Action",
          field: "action",
          html: true,
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "", // example: 'name'
            type: "", // 'asc' or 'desc'
          },
        ],
        page: 1, // what page I want to show
        perPage: 20, // how many items I'm showing per page
      },
    };
  },
  mounted() {
    this.getAccountDropdown();
    this.getInsistution();
    this.loadTransactions();
  },
  methods: {
    submitFormFilters() {
      //this.serverParams.page = 1;
      //currentPage
      this.updateParams({ page: 1 });
      this.loadTransactions();
      this.coa_name = this.form.users.map(index => {
        return `${index.first_name} ${index.surname}`;
      }).join(', ');
      // console.log(this.form.users);
    },
    updateParams(newProps) {
      //console.log("new parms", newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadTransactions();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadTransactions();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadTransactions();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadTransactions();
    },
    async getAccountDropdown() {
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // console.log(response);
          this.form.branch_option = response.data.branches;
          this.form.acc_types_option = response.data.account_types;
          this.users = response.data.users;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async getInsistution() {
      await ApiService.get("/settings/institution")
        .then((response) => {
          // console.log(response);
          this.institution = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async loadTransactions(searchFilter = null) {
      this.isLoading = true;
      var branch_id = "";
      var acc_id = "";
      if (this.form.branch != null) {
        branch_id = this.form.branch.id;
      }
      if (this.form.acc_types != null) {
        acc_id = this.form.acc_types.id;
      }

      // var source = null
      // if (this.form.source) {
      //   source = this.form.source["id"];
      // }

      this.$Progress.start();
      this.isLoading = true;

      //  let ac = "";
      // let filter = "";
      // let phone = "";
      // if (this.code?.value == "account_number") {
      //   ac = this.search.toString();
      //   this.search.toString();
      // } else if (this.code?.value == "filter") {
      //   filter = this.search.toString();
      // } else if (this.code?.value == "phone_number") {
      //   phone = this.search.toString();
      // } else {
      //   filter = this.search.toString();
      // }
      let ac = "";
      let filter = "";
      let code = "";
      let phone = "";

      if (this.form?.code?.value == "account_number") {
        ac = this.form?.search;
      } else if (this.form?.code?.value == "filter") {
        phone = this.form?.search;
      } else if (this.form?.code?.value == "phone_number") {
        phone = this.form?.search;
      } else if (this.form?.code?.value == "code") {
        code = this.form?.search;
      } else {
        filter = this.form?.search;
      }

      // let transaction = "";
      let source = "";
      // if(this.form?.trans_source.value === "deposit" || this.form?.trans_source.value === "withdrawal"){
      //   transaction = this.form?.trans_source.value;
      // }
      if (this.form?.trans_source?.value === "ussd" || this.form?.trans_source?.value === "web" || this.form?.trans_source?.value === "mobile_banker") {
        source = this.form?.trans_source.value;
      }


      // console.log(this.form?.trans_source.value);

      // console.log(this.form.search);

      await ApiService.post("/transactions/all", {
        page: this.serverParams.page,
        per_page: this.serverParams.perPage,
        filter: filter,
        branch_id: branch_id,
        account_type_id: acc_id,
        account_number: ac,
        mobile: phone,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        status: this.form.status,
        code: code,
        transaction_type: this.form.transaction_type,
        external_reference: this.form.external_reference,
        source: source,
        users: this.form.users,
      })
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
          this.total_complete_deposits = response.data.total_complete_deposits
          this.total_complete_withdrawals = response.data.total_complete_withdrawals
          this.totalRecords = response.data.meta.total;
          // this.all_data = response.data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
          this.$Progress.fail();
        })
        .finally(() => {
          this.isLoading = false;
          this.$Progress.finish();
        });
    },
    initialState() {
      return {
        totalRecords: 0,
        showViewTransaction: false,
        searchFilter: "",
        total_complete_deposits: "",
        total_complete_withdrawals: "",
        coa_name: "",
        form: {
          branch: null,
          branch_option: [],
          acc_types: "",
          acc_types_option: [],
          start_date: "",
          end_date: "",
          status: "",
          code: { name: "Customer code", value: "filter" },
          external_reference: "",
          users: [],
          status_option: ["Active", "Inactive", "Dormant", "Closed"],
          search: "",
          trans_source: {},
          transaction_type: ""
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: "add",
        report_data: {},
        institution: {},
        users: [],
      };
    },

    reverseTransaction(trans) {
      swal
        .fire({
          title: "Reverse Transaction",
          text: `Are you sure you want to reverse transaction ${trans.code}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: true,
          customClass: {
            confirmButton: "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
            cancelButton: "btn akkurate-danger-btn modal-btn-width text-white", // Custom class for the custom button
          },
        })
        .then((result) => {
          if (result.value) {
            //   window.location.reload();
            let url = "/transactions/deposits/reverse";
            if (trans.transaction_type == "Deposit") {
              url = "/transactions/deposits/reverse";
            } else if (trans.transaction_type == "Withdrawal") {
              url = "/transactions/withdrawals/reverse";
            }

            this.$Progress.start();
            ApiService.post(url, {
              code: trans.code,
              source: "Web",
            })
              .then((respp) => {
                // console.log(respp);
                //this.isLoading = false;
                this.$Progress.finish();
                toast.fire({
                  icon: "success",
                  title: respp.data.message,
                });

                this.loadTransactions();
              })
              .catch((error) => {
                this.$Progress.fail();
              });
          }
        });
    },
    viewTransaction(trans) {
      // console.log("Veeeew");
      this.showViewTransaction = true;
    },
    getFullname(trans) {
      return `${trans.customer?.first_name} ${trans.customer?.last_name}`;
    },
    getCreatedAt(trans) {
      return this.removeAfterDot(trans.created_at);
    },
    approveWithdrawal(trans) {
      swal
        .fire({
          title: "Approve Withdrawal!",
          // title: "Successfull Saved",
          text: `Are you sure you want to approve withdrawal transaction ${trans.code}?`,
          // text: 'CODE : 14345664565',
          confirmButtonColor: "#018673",
          icon: "info",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: true, // Show the "Cancel" button
          confirmButtonText: "Yes", // Customize the text for the "OK" button
          cancelButtonText: "No", // Text for the custom button
          buttonsStyling: false, // Disable SweetAlert2 styling for buttons
          customClass: {
            confirmButton:
              "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
            cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await ApiService.post("/transactions/withdrawals/approve", {
              transaction_id: trans.id,
              code: trans.code,
            })
              .then((respp) => {
                toast.fire({
                  icon: "success",
                  title: respp.data.message,
                });

                this.loadTransactions();
              })
              .catch((error) => {
                if (error.response.data.message) {
                  toast.fire({
                    icon: "error",
                    title: error.response.data.message,
                  });
                } else {
                  toast.fire({
                    icon: "error",
                    title: "An error occurred",
                  });
                }
              })
              .finally((resp) => {
                this.isLoading = false;
              });
          } else if (result.isDismissed) {
          }
        });
    },
    removeAfterDot(inputString) {
      if (inputString.includes(".")) {
        return inputString.split(".")[0];
      }
      return inputString; // If there's no dot, return the original string
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file

      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = [
          "References",
          "Full name",
          "Amount",
          "Account Number",
          "Transaction type",
          "Phone Number",
          "Status",
          "Date",
          "Source",
          "Users",
        ];



        // Add header row starting from row 13 (or the next row after the image and details)
        const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        // Process each item
        this.rows.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.code,
            item.customer?.first_name + ' ' + item.customer?.last_name,
            item.trans_amount,
            item.account.account_number, // Remove extra spaces
            item.transaction_type,
            item.customer.mobile,
            item.trans_status,
            item.trans_date,
            item.source,
            item.user,
          ];
          row.commit();
          currentRow++;
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Excel_Transactions.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },
    // approveWithdrawal(data)
    // {
    //     swal.fire({
    //                     title: "Confirm Withdrawal Aproval",
    //                     // text: 'CODE : ' + response.data.transaction.code,
    //                     text: 'Are you sure you want to approve this withdrawal?',
    //                     confirmButtonColor: "#018673",
    //                     icon: 'question',
    //                     allowOutsideClick: false,
    //                     allowEscapeKey: false,
    //                     showCancelButton: true, // Show the "Cancel" button
    //                     confirmButtonText: 'Yes', // Customize the text for the "OK" button
    //                     cancelButtonText: 'No', // Text for the custom button
    //                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
    //                     customClass: {
    //                         confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
    //                         cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
    //                     }
    //                 }).then((result) => {
    //                     console.log(result)
    //                     // this.$Progress.start();
    //                     if (result.isConfirmed) {
    //                         this.isLoading = true;
    //                         // this.getAccountStatement();
    //                         // // this.isLoading = false;
    //                         // this.$Progress.finish();

    //                         this.loadTransactions();
    //                         // this.$bvModal.hide(String(this.loan_no + this.statement));
    //                     } else if (result.isDismissed) {
    //                         this.isLoading = false;
    //                         // this.$Progress.finish();

    //                     }
    //                 })
    // },
    rejectWithdrawal(data) {
      swal
        .fire(
          {
            title: "Confirm Withdrawal Rejection",
            // text: 'CODE : ' + response.data.transaction.code,
            text: "Are you sure you want to reject this withdrawal?",
            confirmButtonColor: "#018673",
            icon: "question",
            type: "input",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true, // Show the "Cancel" button
            confirmButtonText: "Yes", // Customize the text for the "OK" button
            cancelButtonText: "No", // Text for the custom button
            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
            customClass: {
              confirmButton: "btn btn-danger modal-btn-width text-white me-2", // Custom class for the "OK" button
              cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
            },
          },
          function (inputValue) {
            if (inputValue === null) return false;

            if (inputValue === "") {
              // swal.showInputError("You need to write something!");
              return false;
            }

            // swal("Nice!", "You wrote: " + inputValue, "success");
          }
        )
        .then((result) => {
          console.log("THEN");
          // this.$Progress.start();
          if (result.isConfirmed) {
            this.isLoading = true;
            // this.getAccountStatement();
            // // this.isLoading = false;
            // this.$Progress.finish();

            this.loadTransactions();
            // this.$bvModal.hide(String(this.loan_no + this.statement));
          } else if (result.isDismissed) {
            this.isLoading = false;
            // this.$Progress.finish();
          }
        });
    },
    printReceipt(data) {
      // console.log(data.item);
      this.report_data = {
        trans_date: data.trans_date,
        account_number: data.account?.account_number,
        fullname: data.customer?.first_name + " " + data.customer?.last_name,
        transaction_mode: data.transaction_mode,
        transaction_type: data.transaction_type,
        amount: data.trans_amount,
        cheque_no: "not avaliable",
        bank_name: "not avaliable",
        branch_name: data.branch,
        narration: data.narration,
        institution: this.institution,
        receipt_no: data.id,
        teller: data.item?.created_user?.first_name + ' ' + data.item?.created_user?.surname,
        denomination: data.denominations,
        mobile: data.mobile,
        network: data.network,
        external_reference: data.external_reference,
        momo_reference: data.momo_reference,
      };
      switch (data.transaction_mode) {
        case "Cash":
          if (data.transaction_type == "Deposit") {
            this.$refs.showCashDeposit.showModal();
            // console.log(this.form);
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showCashWithdrawal.showModal();
          }

          break;
        case "Cheque":
          if (data.transaction_type == "Deposit") {
            this.$refs.showChequeDeposit.showModal();
            // console.log(this.form);
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showChequeWithdrawal.showModal();
          }
          break;
        case "Momo":
          if (data.transaction_type == "Deposit") {
            this.$refs.showMomoDeposit.showModal();
            // console.log(this.form);
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showMomoWithdrawal.showModal();
          }
          break;
        case "Direct Debit":
          if (data.transaction_type == "Deposit") {
            this.$refs.showDirectDebitDeposit.showModal();
            // console.log(this.form);
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showDirectDebitWithdrawal.showModal();
          }
          break;
      }
    },
    chequePrintout(data) {
      this.report_data = {
        trans_date: data.trans_date,
        fullname: data.customer?.first_name + " " + data.customer?.last_name,
        amount: data.trans_amount,
        institution: this.institution,
      };
      this.$refs.showChequePrintout.showModal();
    },
    chequeTemplate(data) {
      this.report_data = {
        trans_date: data.trans_date,
        fullname: data.customer?.first_name + " " + data.customer?.last_name,
        amount: data.trans_amount,
        // institution: this.institution,
      };
      this.$refs.showChequeTemplate.showModal();
    },
  },
  computed: {
    total_difference() {
      return (this.total_complete_deposits - this.total_complete_withdrawals)
    }
  }
};
</script>
