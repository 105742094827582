var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrapper"}},[_c('SidebarView',{attrs:{"loan":true,"repayLoan":true,"loanVisible":true}}),_c('div',{staticClass:"d-flex flex-column",attrs:{"id":"content-wrapper"}},[_c('div',{attrs:{"id":"content"}},[_c('TopbarView'),_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"card card-shape home-box"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',[_c('vue-good-table',{ref:"allAccountTable",attrs:{"styleClass":"vgt-table bordered striped","mode":"remote","columns":_vm.trans_columns,"rows":_vm.rows,"isLoading":_vm.isLoading,"totalRows":_vm.totalRecords,"pagination-options":{
                  enabled: true,
                  perPage: _vm.serverParams.perPage,
                  setCurrentPage: _vm.serverParams.page,
                }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"d-flex"},[(
                      props.row.is_reversed == 0 &&
                      props.row.trans_status == 'COMPLETED'
                    )?_c('a',{staticClass:"btn btn-sm akkurate-primary text-white",attrs:{"title":"Reverse"},on:{"click":function($event){return _vm.reverseLoanRepaymt(props.row)}}},[_c('span',{staticClass:"akkurate-small"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'backward']}})],1)]):_vm._e()]):(props.column.field == 'user')?_c('span',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(props.row.created_user?.first_name)+" "+_vm._s(props.row.created_user?.surname)+" ")]):_vm._e()]}}])})],1)])])])],1),_c('footer')]),_vm._m(2),_c('cash-deposit',{ref:"showCashDeposit",attrs:{"report_data":_vm.report_data}}),_c('cash-withdrawal',{ref:"showCashWithdrawal",attrs:{"report_data":_vm.report_data}}),_c('cheque-deposit',{ref:"showChequeDeposit",attrs:{"report_data":_vm.report_data}}),_c('cheque-withdrawal',{ref:"showChequeWithdrawal",attrs:{"report_data":_vm.report_data}}),_c('momo-deposit',{ref:"showMomoDeposit",attrs:{"report_data":_vm.report_data}}),_c('momo-withdrawal',{ref:"showMomoWithdrawal",attrs:{"report_data":_vm.report_data}}),_c('direct-debit-deposit',{ref:"showDirectDebitDeposit",attrs:{"report_data":_vm.report_data}}),_c('direct-debit-withdrawal',{ref:"showDirectDebitWithdrawal",attrs:{"report_data":_vm.report_data}}),_c('cheque-printout',{ref:"showChequePrintout",attrs:{"report_data":_vm.report_data}}),_c('cheque-template',{ref:"showChequeTemplate",attrs:{"report_data":_vm.report_data}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center"},[_c('h6',{staticClass:"m-0 fw-bold text-green"},[_vm._v("All Loan Repayments")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-inline scroll-to-top",attrs:{"href":"#page-top"}},[_c('i',{staticClass:"fas fa-angle-up"})])
}]

export { render, staticRenderFns }