<template>
    <div>
        <b-modal v-model="showModal" @hidden="$emit('closeModal')" size="xl" title="INTER UNION TRANSFER">
            <div class="container-fluid" ref="pdfContent">
                <!-- First Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <div class="row ps-2">
                        <div class="col-10 col-md-10">
                            <div class="w-100 p-2" style="border: 1px solid #039a67">
                                <p class="fw-bold mb-3 report-l-text">INTER-UNION TRANSFER TRANSACTION RECEIPT</p>
                            </div>
                        </div>
                        <div class="col-md-2 col-2">
                            <div class="d-flex align-items-center justify-content-center">
                                <img class="img-fluid img-visibility" src="@/assets/img/default_logo.png">
                            </div>
                            <div class="text-center">
                                <p class="small m-0 fw-bold fst-italic">HAPPY FAMILY !</p>
                            </div>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">INTER-UNION TRANSFER DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> BRANCH: HEAD OFFICE</p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">4198348</span></p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> 32/10/2023 10:11am</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> BEATRICE AGYEMANG</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{ report_data.account_no }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase"> {{ report_data.full_name }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> CASH DEPOSIT BY SELF</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DEPOSIT BY</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> ELLEN OWUSU</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">RECEIVING ACCOUNT DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">UNION CODE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.union_code }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">UNION NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.union_name }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">CUSTOMER NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.customer_no }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green akkurate-green mb-2">CUSTOMER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green"> {{ report_data.customer_name }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">TRANSACTION ID</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text  akkurate-green mb-2">: {{ report_data.transaction_id }}</p>
                                </div>


                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold"> {{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt should be communicated our customer care line on</span> 233302985691 or
                                email at info@phccu.com</p>
                        </div>
                    </div>
                </div>

                <hr style="border-top: dotted 1px;" class="my-4" />

                <!-- Second Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <div class="row ps-2">
                        <div class="col-10 col-md-10">
                            <div class="w-100 p-2" style="border: 1px solid #039a67">
                                <p class="fw-bold mb-3 report-l-text">INTER-UNION TRANSFER TRANSACTION RECEIPT</p>
                            </div>
                        </div>
                        <div class="col-md-2 col-2">
                            <div class="d-flex align-items-center justify-content-center">
                                <img class="img-fluid img-visibility" src="@/assets/img/default_logo.png">
                            </div>
                            <div class="text-center">
                                <p class="small m-0 fw-bold fst-italic">HAPPY FAMILY !</p>
                            </div>
                        </div>
                    </div>
                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">INTER-UNION TRANSFER DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> BRANCH: HEAD OFFICE</p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">4198348</span></p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> 32/10/2023 10:11am</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> BEATRICE AGYEMANG</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{ report_data.account_no }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase"> {{ report_data.full_name }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> CASH DEPOSIT BY SELF</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DEPOSIT BY</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2"> ELLEN OWUSU</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">: {{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">RECEIVING ACCOUNT DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">UNION CODE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.union_code }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">UNION NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.union_name }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">CUSTOMER NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2"> {{ report_data.customer_no }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green akkurate-green mb-2">CUSTOMER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green"> {{ report_data.customer_name }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">TRANSACTION ID</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text  akkurate-green mb-2">: {{ report_data.transaction_id }}</p>
                                </div>


                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold"> {{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt should be communicated our customer care line on</span> 233302985691 or
                                email at info@phccu.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                 class="float-left"
                 v-if="isLoading"
                 :isLoading="isLoading"
                 ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="generatePDF" type="button"
                        class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon
                                :icon="['fas', 'book']" /> Print</span></b-button>
                    <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
    props: ['showModal', 'report_data'],
    methods: {
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
    },
}
</script>