<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="beneficiary_name">Beneficiary Name <star></star></label>
      <b-form-group id="beneficiary_name" label-for="beneficiary_name">
        <b-form-input class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter First Name"
          v-model="form.beneficiary_name"></b-form-input>
        <errors v-if="errors.beneficiary_name">
          {{ errors.beneficiary_name[0] }}
        </errors>
      </b-form-group>
    </div>
    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="branch">Branch <star></star></label>
      <b-form-group id="branch" label-for="branch">
        <multiselect class="mt-2 field-container" v-model="form.branch" :options="branch_options" label="name">
        </multiselect>
        <errors v-if="errors.branch">
          {{ errors.branch[0] }}
        </errors>
      </b-form-group>
    </div>
    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="beneficiary_phone">Phone</label>
      <b-form-group id="beneficiary_phone" label-for="beneficiary_phone">
        <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Beneficiary Phone"
          v-model="form.beneficiary_phone"></b-form-input>
        <errors v-if="errors.beneficiary_phone">
          {{ errors.beneficiary_phone[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="cheque_number">Cheque Number</label>
      <b-form-group id="cheque_number" label-for="cheque_number">
        <b-form-input class="mb-3 mt-2 field-container fields" type="number" placeholder="Enter Cheque Number"
          v-model="form.cheque_number"></b-form-input>
        <errors v-if="errors.cheque_number">
          {{ errors.cheque_number[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="amount_requested">Amount Requested <star></star></label>
      <b-form-group id="amount_requested" label-for="amount_requested">
        <b-form-input class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter Requested Amount"
          v-model="form.amount_requested" required></b-form-input>
        <errors v-if="errors.amount_requested">
          {{ errors.amount_requested[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="narration">Narration <star></star></label>
      <b-form-group id="narration" label-for="narration">
        <b-form-textarea class="mt-2 fields" id="narration" placeholder="Enter Narration..."
          v-model="form.narration"></b-form-textarea>
        <errors v-if="errors.narration">
          {{ errors.narration[0] }}
        </errors>
      </b-form-group>
    </div>
    <div class="col-12 col-md-6">
      <label class="akkurate-dark" for="recorded_date">Recorded Date<star></star></label>
      <b-form-group id="recorded_date" label-for="recorded_date">
        <b-form-input class="mb-3 mt-2 field-container fields" type="date" v-model="form.recorded_date"
          required></b-form-input>
        <errors v-if="errors.recorded_date">
          {{ errors.recorded_date[0] }}
        </errors>
      </b-form-group>
    </div>

    <!-- <div class="col-md-6 col-12">
      <div class="akkurate-dark small">Choose File</div>
      <div class="border mt-2 d-flex bd-highlight">
        <label class="m-0 label" :class="{ 'pe-none': is_disabled, 'akkurate-green-btn': !is_disabled }">
          <input class="d-none" type="file" accept=".jpg,.jpeg,.png,.csv,.txt,.xlx,.xls,.xlsx,.pdf"
            @change="handleFileChange" />
          <span class="akkurate-small">{{ upload }}</span>
        </label>
        <span class="ms-2 d-flex flex-grow-1 align-items-center">{{ file_name }}</span>
      </div>
      <p class="akkurate-danger mt-2 small">{{ upload_error }}</p>
      <errors v-if="errors.receipt_location">
        {{ errors.receipt_location[0] }}
      </errors>
    </div> -->
    <div class="col-12 col-md-4 mt-2">
      <label class="akkurate-dark d-flex justify-content-between mb-2" for="recorded_date"><div>Choose File<star></star></div>
        <a class="btn btn-sm akkurate-danger-btn" @click="removeFile"><span class="akkurate-small text-white">
            <font-awesome-icon :icon="['fas', 'trash']" /></span></a>
      </label>
      <b-form-group id="choose_file" label-for="choose_file">
        <vue-dropzone ref="myVueDropzone" @vdropzone-files-added="onFileAdded" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
      </b-form-group>


    </div>

  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  name: "VoucherForm",
  props: {
    form: Object,
    errors: Object,
    branch_options: Array,
    file_name: String,
    upload: String,
  },
  components: {
    Multiselect,
    vueDropzone,
  },
  data() {
    return {
      file: null,
      upload_error: "",
      is_disabled: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 100,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      }
    };
  },
  methods: {
    onFileAdded(event) {
      console.log(event)
      this.file = event[0];
      if (this.file) {
        this.$emit("documentFile", this.file);  // Emit the file to the parent
      }
    },
    removeFile() {
      const dropzone = this.$refs.myVueDropzone;
      const files = dropzone.dropzone.files; // Get the list of files

      if (files.length > 0) {
        const fileToRemove = files[files.length - 1]; // For example, remove the last file
        dropzone.removeFile(fileToRemove); // Call removeFile on the dropzone instance
      }
    },
  },
  // methods: {
  //   handleFileChange(event) {
  //     this.file = event.target.files[0];
  //     if (this.file) {
  //       this.file_name = this.file.name;
  //       this.doDocumentUpload();
  //     }

  //   },
  //   async doDocumentUpload() {
  //     if (this.file != null) {
  //       this.upload = "Proccessing..."
  //       this.is_disabled = true;
  //       this.upload_error = "";
  //       var data = new FormData();
  //       data.append('file', this.file);
  //       data.append('is_document', '1');
  //       await ApiService.post('/images/store', data)
  //         .then((response) => {
  //           this.upload = "Uploaded";
  //           this.is_disabled = false;
  //           this.file = null;
  //           this.file_name = "No file chosen";
  //           this.$emit('documentFile', response.data);
  //         }).catch((error) => {
  //           this.upload = "Failed"
  //           this.is_disabled = false;
  //           console.log(error);
  //         })

  //     } else {
  //       this.upload_error = "Upload file is a required field"
  //     }
  //   }
  // }

}
</script>