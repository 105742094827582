<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <label class="akkurate-dark small" for="search_term">Search Term</label>
                <b-form-group id="search_term" label-for="search_term">
                    <b-form-input class="mt-2 field-container fields" type="text"
                        v-model="form.search" placeholder="Search by code... "></b-form-input>
                </b-form-group>
            </div>

            <div class="col">
                <label class="akkurate-dark small" for="type">Users</label>
                <multiselect class="mt-2 field-container" v-model="form.users" :options="users_options"
                    track-by="first_name" :multiple="true" placeholder="Select User"
                    label="first_name">
                </multiselect>
            </div>

            <div class="d-grid gap-2 col-md-2 d-flex align-content-center justify-content-center mt-auto ">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100 d-flex align-items-center justify-content-center"><i
                        class="fas fa-search "></i><span class="d-none d-lg-block ms-2">Search</span></b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>
          
        </div>
        <div class="row" v-if="more_details">
            <div class="col-md-3">
                <label class="akkurate-dark small" for="start_date">From Date</label>
                <b-form-group label-for="start_date" class="akkurate-dark">
                    <b-form-input id="start_date" class="mt-2 field-container fields" type="date"
                        v-model="form.start_date">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-3">
                <label class="akkurate-dark small" for="end_date">To Date</label>
                <b-form-group label-for="end_date" class="akkurate-dark">
                    <b-form-input id="end_date" class="mt-2 field-container fields" type="date" v-model="form.end_date">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-3">
                <label class="akkurate-dark small" for="type">Status</label>
                <multiselect class="mt-2 field-container" v-model="form.status" :options="status_options"
                     :multiple="true" placeholder="Select Status">
                </multiselect>
            </div>
            <div class="col-md-3">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" v-model="form.branch" :options="branch_options"
                    track-by="name" :multiple="true" placeholder="Select branch" label="name">
                </multiselect>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    components:{
        Multiselect
    },
    props: ['form','users_options','branch_options'],
    data() {
        return {

            status_options: ['PENDING', 'APPROVED', 'REJECTED', 'DISBURSED'],
            more_details: false,
        }
    },
    methods: {
        show_more() {
            this.more_details = !this.more_details
        }
    }
}
</script>