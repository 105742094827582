<template>
    <div id="wrapper">
        <SidebarView :journal="true" :add-journal="true" :journal-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="mx-3">
                    <div class="general-error d-flex justify-content-center pt-2 pb-2">
                        <errors v-if="form.message">
                            {{ form.message }}
                        </errors>
                    </div>
                    <div class="card card-shape home-box">
                        <div class="card-header d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 fw-bold text-green">All Journals Member Tansfer</h6>
                        </div>
                        <div class="card-body">
                            <div class="home-box border border-success p-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="akkurate-dark small" for="search">Search</label>
                                        <b-form-group id="filter" label-for="filter" class="akkurate-dark">
                                            <b-form-input id="filter" class="mt-2 field-container fields" type="text"
                                                v-model="filter.search" placeholder="Search by code...">
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col">
                                        <label class="akkurate-dark small" for="account_number">Account number</label>
                                        <b-form-group id="account_number" label-for="account_number"
                                            class="akkurate-dark">
                                            <b-form-input id="account_number" class="mt-2 field-container fields"
                                                type="text" v-model="filter.account_number"
                                                placeholder="Enter Account number">
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-2 mt-auto">
                                        <div class="d-grid gap-2">
                                            <b-button @click="searchAccount" :disabled="is_searching"
                                                class="fw-semibold akkurate-auth-size akkurate-green-btn">
                                                <span class="small"><font-awesome-icon :icon="['fas', 'search']" />
                                                </span> {{ search_btn }}
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="submitJournalTransfer">
                                <div class="row">
                                    <div class="col-2 col-md-2 mb-3">
                                        <b-form-group class="akkurate-dark" label="Entry type"
                                            v-slot="{ ariaDescribedby }">
                                            <b-form-radio class="akkurate-dark" @change="entryClicked"
                                                v-model="form.payment_selected" :aria-describedby="ariaDescribedby"
                                                name="type" value="RECEIPT_ENTRY">Receipt Entry</b-form-radio>
                                            <b-form-radio class="akkurate-dark" @change="entryClicked"
                                                v-model="form.payment_selected" :aria-describedby="ariaDescribedby"
                                                name="type" value="PAYMENT_ENTRY">Payment Entry</b-form-radio>
                                        </b-form-group>
                                    </div>

                                    <div class="col mb-3">
                                        <b-form-group label-for="category">
                                            <template #label>
                                                <label class="akkurate-dark" for="code">Category<star></star>
                                                </label>
                                            </template>
                                            <multiselect class="field-container mt-2" v-model="form.member_category"
                                                :options="select_category_options" label="name" selectLabel=""
                                                deselectLabel="" @select="onSelect_category" :preselect-first="true">
                                            </multiselect>
                                        </b-form-group>
                                    </div>
                                    <div class="col mb-3">
                                        <label class="akkurate-dark small" for="member_name">Member Name <star></star>
                                        </label>
                                        <b-form-group id="member" label-for="member" class="akkurate-dark">
                                            <b-form-input id="member" class="mt-2 field-container fields" type="text"
                                                v-model="form.member" :disabled="true" placeholder="Enter member">
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <label class="akkurate-dark" for="product">{{ source }} <star></star>
                                        </label>
                                        <b-form-group id="product" label-for="product">
                                            <multiselect class="mt-2 field-container" v-model="form.product"
                                                :options="accounts_options" label="account_number"
                                                track-by="account_number" selectLabel="" :preselect-first="true"
                                                 deselectLabel="" :disabled="accDisabled"
                                                :custom-label="accountDetails">
                                            </multiselect>
                                            <errors v-if="form.errors.product">
                                                {{ form.errors.product[0] }}
                                            </errors>
                                        </b-form-group>
                                    </div>
                                    <div class="col-4">
                                        <b-form-group id="amount" label-for="amount">
                                            <template #label>
                                                <label class="akkurate-dark" for="code">Amount<star></star>
                                                </label>
                                            </template>
                                            <b-input-group class="">
                                                <b-form-input class="field-container fields mt-2" type="text"
                                                    v-model="form.amount" placeholder="amount " required></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                    <div class="col-4">
                                        <label class="akkurate-dark" for="coa">{{ destination }} <star></star>
                                        </label>
                                        <b-form-group id="coa" label-for="coa">
                                            <multiselect class="mt-2 field-container" v-model="form.coa"
                                                :options="coa_subhead_options" label="name" selectLabel=""
                                                 :custom-label="customLabel" deselectLabel=""
                                                :preselect-first="true">
                                            </multiselect>
                                            <errors v-if="form.errors.coa">
                                                {{ form.errors.coa[0] }}
                                            </errors>
                                        </b-form-group>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <div class="text-center">
                                    <button type="submit" :disabled="isSaving"
                                        class="btn akkurate-green-btn text-white btn-size">
                                        <font-awesome-icon class="me-2" :icon="['fas', 'forward']" /> Transfer
                                    </button>
                                    <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 ms-3">
                                        <font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />
                                        Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import SaveButton from "@/views/main/components/SaveButton.vue";

export default {
    name: "JournalCreate",
    mounted() {
        this.getVoucherDropdown()
    },
    components: {
        SidebarView,
        Footer,
        TopbarView,
        // JournalForm,
        // AddAccount,
        Multiselect,
        SaveButton
    },
    data() {
        return {
            ...this.initialState(),
            coa_subhead_options: [],
            branch_options: [],
            add_or_edit: 'add',
            save: "Save",
            isSaving: false,
            acc_error: "",
            search_btn: 'Search',
            search: '',
            select_category_options: [{ id: 'member', name: 'Member' }, { id: 'non_member', name: 'Non Member' }],
            // selected: 'RECEIPT_ENTRY',
            // code: { name: "Cust. Code", value: "filter" },
            source: "Source",
            destination: "Destination",
            // code_option: [
            //     { name: "Cust. Code", value: "filter" },
            //     { name: "Account #", value: "account_number" },
            //     // { name: "Filter", value: "filter" },
            //     { name: "Mobile", value: "phone_number" },
            // ],
            accDisabled: false,
            // member_options: [],
            accounts_options: [],
            coa_subhead: "",
            coa_subhead_options: [],
            is_searching: false,
            // field: {
            //     member_name: '',
            //     telephone: ''
            // },
            filter: {
                search: "",
                account_number: "",
            },
            // code: { name: "Filter", value: "filter" },
            // code_option: [
            //     { name: "Filter", value: "filter" },
            //     { name: "Account #", value: "account_number" },
            // ],
        };
    },
    methods: {
        // fullName({ customer }) {
        //     let name = ""
        //     if (customer?.first_name) {
        //         name = name + customer?.first_name;
        //     }

        //     if (customer?.last_name) {
        //         name = name + " " + customer?.last_name;
        //     }

        //     if (customer?.code) {
        //         name = name + ` (${customer?.code})`;
        //     }

        //     return `${name}`
        // },
        accountDetails({ account_number, account_type, account_balance }) {
            return `${account_number} - ${account_type.name}(GHC ${account_balance})`
        },
        customLabel({ name, code, amount }) {
            return `${name} - ${code}(GHC ${amount})`
        },
        async searchAccount() {
            // console.log("Member Search clicked")
            // console.log({code: this.code, search: this.search})
            this.is_searching = true;
            this.search_btn = "processing...";

            await ApiService.post("/accounts/paginate", {
                page: 1,
                per_page: 10,
                filter: this.filter.search.toString(),
                branch_id: "",
                account_type_id: "",
                account_number: this.filter.account_number.toString(),
                phone_number: "",
                start_date: "",
                end_date: "",
                status: "", //Inactive,	Active, Dormant, Closed
            })
                .then((response) => {
                    if (response.data.data.length > 0) {
                        console.log(response.data.data);
                        // this.field.member_name = response.data?.data[0]?.customer?.first_name + " " + response.data?.data[0]?.customer?.last_name;
                        // this.field.telephone = response.data?.data[0]?.customer.mobile;
                        const res = response.data.data
                        this.accounts_options = res;
                        // this.member_options = res;

                        if (!this.accDisabled) {
                            if (res.length > 0) {
                                this.form.product = {
                                    account_number: res[0].account_number,
                                    account_type: res[0].account_type,
                                    account_balance: res[0].account_balance,
                                }
                            }
                            if (res.length > 0) {
                                this.form.member = res[0]?.customer?.first_name + ' ' + res[0]?.customer?.last_name
                            }
                        }


                    } else {
                        swal.fire({
                            icon: "error",
                            title: "Not Found",
                            text: "This account was not found please try again!!!",
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: "OK", // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton:
                                    "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                            },
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                    this.is_searching = false;
                    this.search_btn = "Search";
                })
                .catch((error) => {
                    this.is_searching = false;
                    this.search_btn = "Search";
                });
        },
        // accountSelected(event) {
        //     this.field.product = event?.product_type
        //     // this.field.member_name = event?.customer_name
        //     // this.field.telephone = event?.customer_mobile

        //     // const value = this.accounts_options.find(event.name);
        // },
        async getVoucherDropdown() {
            this.$Progress.start();
            await ApiService.get('/journals/dropdown')
                .then((response) => {
                    this.$Progress.finish();
                    console.log(response);
                    this.coa_subhead_options = response.data.coa_subheads
                    // this.branch_options = response.data.branches;
                }).catch((error) => {
                    this.$Progress.fail();
                    // console.log(error);
                })
        },
        
        // async searchMember() {
        //     this.is_searching = true;
        //     this.search_btn = "processing...";
        //     await ApiService.post("/customers/paginate", {
        //         page: 1,
        //         per_page: 10,
        //         filter: this.filter.search.toString(),
        //         branch_id: "",
        //         region_id: "",
        //         status: "", //Inactive,	Active, Dormant, Closed
        //         account_number: this.filter.account_number.toString(), //Inactive,	Active, Dormant, Closed
        //     })
        //         .then((response) => {
        //             if (response.data.data.length > 0) {
        //                 this.member_options = response.data.data
        //                 if(this.member_options.length > 0){
        //                     // console.log(this.member_options[0]);
        //                     this.form.member = {
        //                         id:this.member_options[0].id,
        //                         first_name:this.member_options[0].first_name,
        //                         last_name:this.member_options[0].last_name,
        //                         code:this.member_options[0].code,
        //                     }
        //                 }

        //                 this.searchAccount(this.filter.search.toString());
        //             } else {
        //                 swal.fire({
        //                     icon: "error",
        //                     title: "Not Found",
        //                     text: "This member account was not found please try again!!!",
        //                     showCancelButton: false, // Show the "Cancel" button
        //                     confirmButtonText: "OK", // Customize the text for the "OK" button
        //                     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        //                     customClass: {
        //                         confirmButton:
        //                             "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
        //                     },
        //                     // footer: '<a href="#">Why do I have this issue?</a>'
        //                 });
        //                 this.form.member = ""
        //             }
        //             this.is_searching = false;
        //             this.search_btn = "Search";
        //         })
        //         .catch((error) => {
        //             this.is_searching = false;
        //             this.search_btn = "Search";
        //         });
        // },

        entryClicked(event) {
            if (event === 'RECEIPT_ENTRY') {
                this.source = "Source"
                this.destination = "Destination"
            } else if (event == "PAYMENT_ENTRY") {
                this.source = "Destination"
                this.destination = "Source"
            } else {
                this.source = "Source"
                this.destination = "Destination"
            }
        },

        submitJournalTransfer() {
            this.isSaving = true;
            this.$Progress.start();
            this.save = "Saving..."
            // console.log(this.form);
            console.log(this.form)
            ApiService.post("/journals/transfer/store", this.form)
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();
                    this.save = "Save"
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });

                    this.form = this.initialState().form;
                    this.isSaving = false;
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.save = "Save"
                    // console.log(error);
                    if (error.response.data) {
                        this.form.errors = error.response.data.message;
                    }

                    //error message
                    if (error.response.data.message) {
                        this.form.message = error.response.data.message;
                    }
                    // console.log(error.response.data)
                });
        },


        initialState() {
            return {
                form: {
                    amount: "",
                    recorded_date: new Date(),
                    narration: "",
                    coa: "",
                    product: "",
                    payment_selected: "RECEIPT_ENTRY",
                    errors: [],
                    member: "",
                },

            }
        },
        onSelect_category(event) {
            if (event.id === 'non_member') {
                this.accDisabled = true
                this.form.member = "";
                this.form.product = "";
            } else {
                this.accDisabled = false
            }
        }
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>