<template>
    <div class="container">
        <div class="row">
            <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
        </div>
        <div class="row px-3">
            <div class="card card-shape home-box mb-3 border border-success">
                <div class="card-body py-2">
                    <form @submit.prevent="getSingleFixedDeposit">
                        <div class="row">
                            <div class="col">
                                <b-form-group id="filter" name="filter" label-for="filter">
                                    <multiselect id="filter" name="filter" class="mt-2 field-container"
                                        aria-describedby="filter" v-model="form_filter.filter" :allow-empty="false"
                                        :options="filter_options">
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <div class="col">
                                <b-form-group id="filter_value" label-for="filter_value">
                                    <b-form-input id="filter_value" name="filter_value"
                                        class="mb-2 mt-2 field-container fields" type="text"
                                        placeholder="Enter Search Value..." v-model="form_filter.filter_value"
                                        aria-describedby="filter_value" required></b-form-input>
                                </b-form-group>

                            </div>
                            <div class="col-3 my-auto">
                                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                                        class="fas fa-search me-2"></i>Search</b-button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
        <div class="row px-3">
            <div class="card card-shape home-box p-0">
                <div class="rounded-0 card-header py-0 pe-0 akkurate-skyblue-bg">
                    <div class="row align-items-center">

                        <div class="col-2 col-md-2">
                            <h6 class="m-0 fw-bold text-white">ACCOUNT PREVIEW</h6>
                        </div>
                        <div class="me-auto px-2 py-2 col text-white" v-if="fixed_deposit.status !== 'RUNNING'">
                            Current Stage: <span class="text-uppercase">{{ fd_stage }}</span>
                        </div>
                        <div class="px-2 py-2 col text-white" v-else>
                            Current Status: <span class="text-uppercase">{{ fixed_deposit.status }}</span>
                        </div>
                        <div class="col text-end"
                            v-if="fixed_deposit.status !== 'RUNNING' && fixed_deposit.status !== 'APPROVED'"
                            v-show="getFdApproval">
                            <b-button class="akkurate-danger-btn btn-size" @click="rejectFD">
                                Reject
                            </b-button>
                            <b-button class="akkurate-green-btn btn-size" @click="approveFD(fixed_deposit.code)">
                                Approve
                            </b-button>
                        </div>
                        <!-- <div class="col text-end"
                            v-if="fixed_deposit.status !== 'RUNNING' && fixed_deposit.status !== 'APPROVED'"
                            v-show="getFdDisbursement">
                            <b-button class="akkurate-danger-btn btn-size" @click="rejectFD">
                                Reject
                            </b-button>

                            <b-button class="akkurate-green-btn btn-size"
                                v-b-modal="approval.id + 'approved'">Approve</b-button>
                        </div> -->

                        <!-- When the user is a Branch Manager -->
                        <!-- <div class="col text-end"
                            v-if="user_role === 'Branch Manager' || user_role === 'General Manager' && fixed_deposit.created_by != current_user_id && fixed_deposit.status != 'RUNNING'">
                            <b-button v-if="fd_stage === 'Awaiting Branch Manager Approval' || fd_stage === 'Awaiting General Manager Approval'"
                                :disabled="!canUpdateStatus" class="akkurate-danger-btn btn-size" @click="rejectFD">
                                Reject
                            </b-button>

                            <b-button
                                v-if="fd_stage === 'Awaiting Branch Manager Approval' || fd_stage === 'Awaiting General Manager Approval'"
                                :disabled="!canUpdateStatus" @click="approveFD(fixed_deposit.code)"
                                class="akkurate-green-btn btn-size">
                                Approve
                            </b-button>
                        </div> -->
                        <!-- When the user is a Accountant -->
                        <!-- <div class="col text-end"
                            v-if="user_role === 'Accountant' && fixed_deposit.status != 'RUNNING'">
                            <b-button
                                v-if="fd_stage === 'Awaiting Disbursement' || fd_stage === 'Awaiting Accountant Approval'"
                                :disabled="!canUpdateStatus" class="akkurate-danger-btn btn-size" @click="rejectFD">
                                Reject
                            </b-button>

                            <b-button
                                v-if="fd_stage === 'Awaiting Disbursement' || fd_stage === 'Awaiting Accountant Approval'"
                                :disabled="!canUpdateStatus" @click="approveFD(fixed_deposit.code)"
                                class="akkurate-green-btn btn-size">
                                Approve
                            </b-button>
                        </div> -->
                        <!-- When the user is a Accountant -->
                        <!-- <div class="col text-end"
                            v-if="user_role === 'Accountant' && fixed_deposit.status != 'RUNNING' && fixed_deposit.status != 'APPROVED' && fixed_deposit.status != 'UNDER_REVIEW'">
                            <b-button v-if="fd_stage === 'Awaiting Approval'" :disabled="!canUpdateStatus"
                                class="akkurate-danger-btn btn-size" @click="rejectFD">
                                Reject
                            </b-button>
                            <b-button v-if="fd_stage === 'Awaiting Approval'" class="akkurate-green-btn btn-size"
                                v-b-modal="approval.id + 'approved'">Approve</b-button>

                        </div> -->
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-center">
                            <img :src="fixed_deposit?.account?.customer?.passport_pic_path" style="height:200px"
                                class="img-fluid" alt="user-profile" />
                            <p class="akkurate-green fw-bold akkurate-small mb-0">Profile Image</p>
                        </div>
                        <div class="col text-center">
                            <img :src="fixed_deposit?.account?.customer?.gh_card_front_path" alt="Default Image"
                                style="height:200px" class="img-fluid text-center" />
                            <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Front</p>
                        </div>
                        <div class="col text-center">
                            <img :src="fixed_deposit?.account?.customer?.gh_card_back_path" alt="Default Image"
                                style="height:200px" class="img-fluid text-center" />
                            <p class="akkurate-green fw-bold akkurate-small mb-0">ID Card Back</p>
                        </div>
                        <div class="col">
                            <p class="mb-0 fw-bold">{{ getFullName }}<span class="ms-3 fw-light">{{
                                fixed_deposit?.account?.customer?.gender
                                    }}</span></p>
                            <p class="mb-0">{{ getCustomer.branch?.name }}</p>
                            <p class="mb-0"><span class="fw-bold">ADDRESS: </span> {{
                                getCustomer.address }}</p>
                            <p class="mb-0"><span class="fw-bold">TEL: </span>{{
                                getCustomer.mobile }}</p>
                            <p class="mb-0"><span class="fw-bold">EMAIL: </span>{{
                                getCustomer.email }}</p>
                            <p class="mb-0"><span class="fw-bold">REG DATE: </span>{{ new
                                Date(getCustomer.registered_date).toDateString() }}</p>
                            <p class="mb-0"><span class="fw-bold">STAFF ID: </span>{{
                                getCustomer.staff_id }}
                            </p>
                        </div>
                    </div>
                    <div class="row akkurate-skyblue-bg text-white akkurate-small fw-bold">
                        <div class="col text-center py-2">
                            Principal Amount: {{ displayNumber(fixed_deposit.principal_amount) }}
                        </div>
                        <div class="col text-center py-2">
                            Maturity Value: {{ displayNumber(fixed_deposit.maturity_value) }}
                        </div>
                        <div class="col text-center py-2">
                            Interest Rate: {{ displayNumber(fixed_deposit.interest_rate) }}
                        </div>
                        <div class="col text-center py-2">
                            Total Interest: {{ displayNumber(fixed_deposit.total_interest) }}
                        </div>

                        <div class="col text-center py-2">
                            Interest Due: {{ displayNumber(fixed_deposit.interest_due) }}
                        </div>


                    </div>
                    <!-- Tabs Begins -->
                    <div class="row mt-1">
                        <b-tabs content-class="mt-3" justified>
                            <b-tab active title="Statements" :disabled="fixed_deposit.status === 'PENDING'"
                                title-link-class="akkurate-small">
                                <fd-statement :institution="institution"
                                    :fixed_deposit="this.data.fixed_deposit"></fd-statement>
                                <!-- <Statement :institution="institution" :loan="loan" /> -->
                            </b-tab>
                            <b-tab title="Entries" :disabled="fixed_deposit.status === 'PENDING'"
                                title-link-class="akkurate-small">
                                <fd-entries :institution="institution"
                                    :fixed_deposit="this.data.fixed_deposit"></fd-entries>
                                <!-- <Entries :institution="institution" :loan="loan" /> -->
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import Multiselect from 'vue-multiselect'
import FdStatement from "../form/FdStatement.vue";
import FdEntries from "../form/FdEntries.vue";
//  import LoanDisbursement from "../disbursement/LoanDisbursement.vue";
export default {
    props: ['data', 'institution', 'coa_subheads', 'user_role', 'current_user_id', 'user_pemissions'],
    components: {
        BeatLoaderComponent,
        Multiselect,
        FdStatement,
        FdEntries
    },
    data() {
        return {
            // ...this.initialState(),
            form_filter: {
                filter: "Fixed Deposit Code",
                filter_value: this.data.fixed_deposit?.code,
            },
            filter_options: ['FD Code'],
            fixed_deposit: [],
            // term_active_menu: false,
            // statement_active_menu: false,
            // repayment_active_menu: false,
            // other data properties

            savings_accounts_options: [],
            isLoading: false,
            isBusy: false,
            full_name: "",
            search: "Search",
            isSearching: false,
            isSaving: false,
            products: [],
            canUpdateStatus: false,
            total: "",
            approval: {
                id: 'fd_approval'
            },
            //   hybrid: false,
            //   straight_line: false,
            //   disbursement: {
            //      id: 'loan_disbursement'
            //   },
            fd_stage: '',  // Example, this will be dynamic based on the FD stag
            fields: [
                { key: 'payment_terms', label: 'Payment Terms' },
                { key: 'after_payments', label: 'After Payment' },
            ],
            items: [
                { payment_terms: { title: 'Interest Amount', value: "" }, after_payments: { title: 'Interest Outstanding', value: "" } },
                { payment_terms: { title: 'Principal Amount', value: "" }, after_payments: { title: 'Principal Outstanding', value: "" } },
                { payment_terms: { title: 'Total Repayment', value: "" }, after_payments: { title: 'Total Outstanding', value: "" } },
            ],
            account: "",
        }
    },
    computed: {
        getFullName() {
            const first_name = this.fixed_deposit?.account?.customer?.first_name != undefined ? this.fixed_deposit?.account?.customer?.first_name : "";
            const middle_name = this.fixed_deposit?.account?.customer?.middle_name != undefined ? this.fixed_deposit?.account?.customer?.middle_name : "";
            const last_name = this.fixed_deposit?.account?.customer?.last_name != undefined ? this.fixed_deposit?.account?.customer?.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getTeller() {
            let first_name = this.user?.first_name != null ? this.user?.first_name : '';
            let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
            let surname = this.user?.surname != null ? this.user?.surname : '';
            return first_name + ' ' + middle_name + ' ' + surname;
        },
        getCustomer() {
            const customer = this.fixed_deposit?.account?.customer ?? {}
            return customer
        },
        getFdApproval() {
            // console.log(this.user_pemissions);
            if (this.user_role === 'Branch Manager') { // Check Branch manager role
                if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
                    if (this.fd_stage == 'Awaiting Branch Manager Approval') { // Check for Branch Manager Stage
                        const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
                        return approval.length > 0;
                    }
                }
                return false; // Return false if user_pemissions is null or undefined
            } else if (this.user_role === 'General Manager') {
                if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
                    if (this.fd_stage == 'Awaiting General Manager Approval') { // Check for Branch Manager Stage
                        const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
                        return approval.length > 0;
                    }
                }
                return false;
            } else if (this.user_role === 'Head Of Credit') {
                if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
                    if (this.fd_stage == 'Awaiting Head Of Credit Approval') { // Check for Branch Manager Stage
                        const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
                        return approval.length > 0;
                    }
                }
                return false;
            } else if (this.user_role === 'Accountant') {
                if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
                    if (this.fd_stage == 'Awaiting Approval' || this.fd_stage == 'Awaiting Accountant Approval') { // Check for Branch Manager Stage
                        const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
                        return approval.length > 0;
                    }
                }
                return false;
            }
        },
        // getFdDisbursement() {
        //     console.log(this.user_role);
        //     if (this.user_role === 'Head Of Credit') { // Check Branch manager role
        //         if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
        //             if (this.fd_stage == 'Awaiting Head Of Credit Approval') { // Check for Branch Manager Stage
        //                 const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
        //                 return approval.length > 0;
        //             }
        //         }
        //         return false; // Return false if user_pemissions is null or undefined
        //     } else if (this.user_role === 'Accountant') {
        //         if (this.user_pemissions && Array.isArray(this.user_pemissions)) { // Check for user permission
        //             if (this.fd_stage == 'Awaiting Approval' || this.fd_stage == 'Awaiting Accountant Approval') { // Check for Branch Manager Stage
        //                 const approval = this.user_pemissions.filter(index => index === 'operation_management_fixed_deposit_approve');
        //                 return approval.length > 0;
        //             }
        //         }
        //         return false;
        //     }
        // },
    },
    mounted() {
        if (this.data?.fixed_deposit?.code != null) {
            this.getSingleFixedDeposit()
        }
    },
    methods: {
        getSingleFixedDeposit() {
            this.isLoading = true;
            this.isBusy = true,
                ApiService.get(`/investments/fixed-term-deposits/${this.form_filter.filter_value}`)
                    .then((response) => {
                        this.isLoading = false;
                        this.isBusy = false;
                        this.fixed_deposit = response.data.data;
                        this.fd_stage = this.fixed_deposit.fixed_deposit_approval_level?.name
                        let the_status = response?.data?.data?.status;
                        if (the_status == "RUNNING" || the_status == "EXPIRED" || the_status == "CLOSED" || the_status == "REJECTED" || the_status == "DISBURSED") {
                            this.canUpdateStatus = false
                        } else {
                            this.canUpdateStatus = true
                        }
                    }).catch((error) => {
                        this.search = "Search";
                        this.isSearching = false;
                        // console.log(error);
                        if (error.response?.status == 404) {
                            swal.fire({
                                icon: "error",
                                title: error.response.statusText,
                                text: "Something went wrong!",
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'OK', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                                // footer: '<a href="#">Why do I have this issue?</a>'
                            });
                        }
                    });
        },
        approveFD(filter = null) {
            this.isSaving = true;
            this.$Progress.start();
            swal.fire({
                title: "Fixed Deposit Approval",
                text: 'Are you sure you want to approve fixed deposit ' + filter + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.post('/investments/approveFD', { 'fd_code': this.form_filter.filter_value })
                        .then((response) => {
                            this.$Progress.finish();
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.getSingleFixedDeposit();
                            this.$emit('approvedFD')
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        },

        rejectFD() {

        },

        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },
    },
}
</script>