UsersIndex

<template>
  <div id="wrapper">
    <SidebarView :journal-transfer="true" :journal-transfer-index="true" :journal-transfer-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="mx-3">

          <!-- begin row  -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-shape home-box">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 fw-bold text-green">All Journals Member Tansfer</h6>
                  <b-button class="btn btn-sm akkurate-green-btn" href="/journal/transfer/create" variant="primary">
                    <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Journal Member Tansfer</span>
                  </b-button>
                </div>
                <div class="card-body py-3 default_bg" style="border-bottom: 1px solid rgb(1, 134, 115);">
                  <div class="home-box border border-success p-3 bg-white">
                    <form @submit.prevent="filterTable">
                      <journal-transfer-filter :form="form" :users="users"></journal-transfer-filter>
                    </form>

                  </div>
                </div>
                <div class="card-body table_text_size">
                  <b-table striped bordered responsive id="journal-transfer-table" :busy.sync="isBusy" :items="rows"
                    :fields="fields" :tbody-tr-class="rowClass" show-empty>
                    <template #empty="scope">
                      <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <!-- A custom formatted column -->
                    <template #cell(teller_name)="data">
                      <div>{{ data.item?.created_user?.first_name }} {{ data.item?.created_user?.surname }}</div>
                    </template>
                    <template #cell(trans_status)="data">
                      <span :class="statusBadge(data.item.trans_status)">{{ data.item.trans_status }}</span>
                    </template>
                    <template #cell(ac_no_or_coa_code)="data">
                      <span v-if="data.item.account?.account_number != undefined">
                        {{ data.item.account?.account_number }}
                      </span>
                      <span v-else>
                        {{ data.item.non_member_coa_transfer?.code }}
                      </span>
                    </template>
                    <template #cell(ac_pro_or_coa_name)="data">
                      <span v-if="data.item?.account?.account_type?.name != undefined">
                        {{ data.item.account?.account_type?.name }}
                      </span>
                      <span v-else>
                        {{ data.item.non_member_coa_transfer?.name }}
                      </span>
                    </template>
                    <template #cell(actions)="data">
                      <a v-if="data.item.trans_status == 'PENDING' &&
                        data.item.transaction_type == 'Withdrawal'" title="Approve"
                        class="btn btn-sm akkurate-green-btn text-white" @click="approveWithdrawal(data.item)">
                        <span class="akkurate-small">
                          <font-awesome-icon :icon="['fas', 'check']" />
                        </span>
                      </a>
                      <a v-if="
                        data.item.is_reversed == 0 &&
                        data.item.trans_status == 'COMPLETED'
                      " title="Reverse" class="btn btn-sm akkurate-primary text-white"
                        @click="reverseTransaction(data.item)">
                        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'backward']" /></span>
                      </a>
                    </template>

                  </b-table>

                  <div class="d-flex justify-content-between">
                    <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage" :total-rows="totalRecords"
                      :per-page="perPage" aria-controls="member-table"></b-pagination>
                    <div class="col text-end mt-1 akkurate-dark">
                      <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                      <b-form-group label="Per Page" label-size="sm" class="mb-0">
                        <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                          :options="per_page_options" size="sm"></b-form-select>
                      </b-form-group>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- end row  -->
        </div>
      </div>
      <footer></footer>
    </div>
    <cash-deposit ref="showCashDeposit" :report_data="report_data"></cash-deposit>
    <cash-withdrawal ref="showCashWithdrawal" :report_data="report_data"></cash-withdrawal>
    <cheque-deposit ref="showChequeDeposit" :report_data="report_data"></cheque-deposit>
    <cheque-withdrawal ref="showChequeWithdrawal" :report_data="report_data"></cheque-withdrawal>
    <momo-deposit ref="showMomoDeposit" :report_data="report_data"></momo-deposit>
    <momo-withdrawal ref="showMomoWithdrawal" :report_data="report_data"></momo-withdrawal>
    <direct-debit-deposit ref="showDirectDebitDeposit" :report_data="report_data"></direct-debit-deposit>
    <direct-debit-withdrawal ref="showDirectDebitWithdrawal" :report_data="report_data"></direct-debit-withdrawal>
    <cheque-printout ref="showChequePrintout" :report_data="report_data"></cheque-printout>
    <cheque-template ref="showChequeTemplate" :report_data="report_data"></cheque-template>
    <deposit-transfer ref="showTransferDeposit" :report_data="report_data"></deposit-transfer>
    <withdrawal-transfer ref="showTransferWithdrawal" :report_data="report_data"></withdrawal-transfer>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import Multiselect from 'vue-multiselect'
import Reject from '../forms/modal/Reject.vue'
import JournalView from '../view/JournalView.vue'

import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import CashDeposit from "../../transactions/reports/cash/CashDeposit.vue";
import CashWithdrawal from "../../transactions/reports/cash/CashWithdrawal.vue";
import ChequeDeposit from "../../transactions/reports/cheque/ChequeDeposit.vue";
import ChequeWithdrawal from "../../transactions/reports/cheque/ChequeWithdrawal.vue";
import MomoDeposit from "../../transactions/reports/momo/MomoDeposit.vue";
import MomoWithdrawal from "../../transactions/reports/momo/MomoWithdrawal.vue";
import DirectDebitDeposit from "../../transactions/reports/direct-debit/DirectDebitDeposit.vue";
import DirectDebitWithdrawal from "../../transactions/reports/direct-debit/DirectDebitWithdrawal.vue";
import ChequePrintout from "../../loan/reports/printout/ChequePrintout.vue";
import ChequeTemplate from "../../loan/reports/printout/ChequeTemplate.vue";
import DepositTransfer from "../../transactions/reports/transfers/DepositTransfer.vue";
import WithdrawalTransfer from "../../transactions/reports/transfers/WithdrawalTransfer.vue";
import JournalTransferFilter from "./form/JournalTransferFilter.vue";

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    Multiselect,
    Reject,
    JournalView,
    CashDeposit,
    CashWithdrawal,
    ChequeDeposit,
    ChequeWithdrawal,
    MomoDeposit,
    MomoWithdrawal,
    DirectDebitDeposit,
    DirectDebitWithdrawal,
    ChequePrintout,
    ChequeTemplate,
    DepositTransfer,
    WithdrawalTransfer,
    JournalTransferFilter

  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },

  data() {
    return {
      totalRecords: 0,
      showViewTransaction: false,
      searchFilter: "",
      total_complete_deposits: "",
      total_complete_withdrawals: "",
      coa_name: "",
      form: {
        branch: null,
        branch_option: [],
        acc_types: "",
        acc_types_option: [],
        start_date: "",
        end_date: "",
        status: "",
        code: { name: "Customer code", value: "filter" },
        external_reference: "",
        users: [],
        status_option: ["Active", "Inactive", "Dormant", "Closed"],
        search: "",
        trans_source: {},
        transaction_type: "",
        member_category: "",
      },
      isBusy: true,
      currentPage: 1,
      perPage: 10,
      per_page_options: ['10', '20', '50', '100'],
      per_page_selected: "10",
      isLoading: true,
      isSaving: false,
      add_or_edit: "add",
      report_data: {},
      institution: {},
      users: [],
      fields: [
        {
          label: 'Reference',
          key: 'code',
        },
        {
          label: 'Account_Number/COA_Code',
          key: 'ac_no_or_coa_code',
        },
        {
          label: 'Member_Product/COA_Name',
          key: 'ac_pro_or_coa_name',
        },
        {
          label: 'Destination_GL',
          key: 'transfered_coa_subhead.name',
        },

        {
          label: 'Amount',
          key: 'trans_amount',
        },
        {
          label: 'Teller',
          key: 'teller_name',
        },
        {
          label: 'Status',
          key: 'trans_status',
        },
        {
          label: 'Type',
          key: 'transaction_type',
        },
        {
          label: 'Date',
          key: 'trans_date',
        },
        {
          label: 'Actions',
          key: 'actions',
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
      // serverParams: {
      //   // a map of column filters example: {name: 'john', age: '20'}
      //   columnFilters: {
      //   },
      //   sort: [
      //     {
      //       field: '', // example: 'name'
      //       type: '' // 'asc' or 'desc'
      //     }
      //   ],
      //   page: 1, // what page I want to show
      //   perPage: 20 // how many items I'm showing per page
      // },
    };
  },
  async created() {
    this.loadItems();
    this.getAccountDropdown()
    this.getInsistution();
  },
  methods: {
    statusBadge(item) {
      if (item === 'COMPLETED') {
        return 'badge akkurate-green-bg'
      } else if (item === 'REVERSED') {
        return 'badge akkurate-danger-bg'
      } else if (item === 'REJECTED') {
        return 'badge akkurate-danger-bg'
      } else if (item === 'PENDING') {
        return 'badge akkurate-warning-bg'
      } else {
        return 'badge akkurate-warning-bg'
      }
    },
    async getInsistution() {
      await ApiService.get("/settings/institution")
        .then((response) => {
          // console.log(response);
          this.institution = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    rowClass() {
      return 'custom-row-height'
    },
    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.loadItems()
    },
    selectPerPage(index) {
      this.perPage = index
      this.loadItems()
    },
    filterTable() {
      // console.log(this.form.filter)
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true;
      this.isBusy = true;
      let users = []
      let branches = [];
      //Reduce Users Array Object For Filters
      users = this.form.users?.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.id);
        return accumulator;
      }, []);
      //Reduce Branch Array Object For Filters
      branches = this.form.branch?.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.id);
        return accumulator;
      }, []);
      await ApiService.post("/journals/transfer/pagination",
        {
          "page": this.currentPage,
          "per_page": this.perPage,
          "filter": this.form.search,
          "branch_ids": branches, //Eg. 1, 2, etc
          "status": this.form.status,
          "start_date": this.form.start_date,
          "end_date": this.form.end_date,
          "transaction_type": this.form.transaction_type,
          "users": users,
          "source": this.form.trans_source
        })
        .then(response => {
          // Handle response and update table data
          this.isBusy = false;  // Set isBusy to false when the data is loaded
          this.$Progress.finish();  // Finish the progress bar

          this.totalRecords = response.data.meta.total;
          this.rows = response.data.data;  // Return data to populate the table

        }).catch(() => {
          console.error(error);
          this.isLoading = false
          this.isBusy = false;  // Set isBusy to false in case of an error
          this.$Progress.finish();  // Finish the progress bar even if there's an error

          return [];  // Return an empty array in case of error to prevent the table from breaking
        })
        .finally(() => {
          // this.isLoading = false
          this.isLoading = false
          this.isBusy = false;
        });
    },
    rejected() {
      this.loadItems();
    },
    async getAccountDropdown() {
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // console.log(response);
          this.form.branch_option = response.data.branches;
          this.form.acc_types_option = response.data.account_types;
          this.users = response.data.users;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    approveWithdrawal(trans) {
      swal
        .fire({
          title: "Approve Withdrawal!",
          // title: "Successfull Saved",
          text: `Are you sure you want to approve withdrawal transaction ${trans.code}?`,
          // text: 'CODE : 14345664565',
          confirmButtonColor: "#018673",
          icon: "info",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCancelButton: true, // Show the "Cancel" button
          confirmButtonText: "Yes", // Customize the text for the "OK" button
          cancelButtonText: "No", // Text for the custom button
          buttonsStyling: false, // Disable SweetAlert2 styling for buttons
          customClass: {
            confirmButton:
              "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
            cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await ApiService.post("/transactions/withdrawals/approve", {
              transaction_id: trans.id,
              code: trans.code,
            })
              .then((respp) => {
                toast.fire({
                  icon: "success",
                  title: respp.data.message,
                });

                this.loadItems();
              })
              .catch((error) => {
                if (error.response.data.message) {
                  toast.fire({
                    icon: "error",
                    title: error.response.data.message,
                  });
                } else {
                  toast.fire({
                    icon: "error",
                    title: "An error occurred",
                  });
                }
              })
              .finally((resp) => {
                this.isLoading = false;
              });
          } else if (result.isDismissed) {
          }
        });
    },
    reverseTransaction(trans) {
      swal
        .fire({
          title: "Reverse Transaction",
          text: `Are you sure you want to reverse transaction ${trans.code}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          allowOutsideClick: true,
          customClass: {
            confirmButton: "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
            cancelButton: "btn akkurate-danger-btn modal-btn-width text-white", // Custom class for the custom button
          },
        })
        .then((result) => {
          if (result.value) {
            //   window.location.reload();
            let url = "/transactions/deposits/reverse";
            if (trans.transaction_type == "Deposit") {
              url = "/transactions/deposits/reverse";
            } else if (trans.transaction_type == "Withdrawal") {
              url = "/transactions/withdrawals/reverse";
            }

            this.$Progress.start();
            ApiService.post(url, {
              code: trans.code,
              source: "Web",
            })
              .then((respp) => {
                // console.log(respp);
                //this.isLoading = false;
                this.$Progress.finish();
                toast.fire({
                  icon: "success",
                  title: respp.data.message,
                });

                this.loadItems();
              })
              .catch((error) => {
                this.$Progress.fail();
              });
          }
        });
    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.userTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    },
    printReceipt(data) {
      this.report_data = {
        trans_date: data.trans_date,
        account_number: data.account?.account_number,
        fullname: data.customer?.first_name + " " + data.customer?.last_name,
        transaction_mode: data.transaction_mode,
        transaction_type: data.transaction_type,
        amount: data.trans_amount,
        cheque_no: "not avaliable",
        bank_name: "not avaliable",
        branch_name: data.branch,
        narration: data.narration,
        institution: this.institution,
        receipt_no: data.id,
        teller: data.created_user?.first_name + ' ' + data.created_user?.surname,
        denomination: data.denominations,
        mobile: data.mobile,
        network: data.network,
        external_reference: data?.external_reference,
        momo_reference: data?.momo_reference,
        coa_subhead: data?.coa_subhead,
        reference: data.code


      };
      switch (data.transaction_mode) {
        case "Cash":
          if (data.transaction_type == "Deposit") {
            this.$refs.showCashDeposit.showModal();
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showCashWithdrawal.showModal();
          }

          break;
        case "Cheque":
          if (data.transaction_type == "Deposit") {
            this.$refs.showChequeDeposit.showModal();
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showChequeWithdrawal.showModal();
          }
          break;
        case "Momo":
          if (data.transaction_type == "Deposit") {
            this.$refs.showMomoDeposit.showModal();
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showMomoWithdrawal.showModal();
          }
          break;
        case "Direct Debit":
          if (data.transaction_type == "Deposit") {
            this.$refs.showDirectDebitDeposit.showModal();
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showDirectDebitWithdrawal.showModal();
          }
          break;
        case "Transfer":
          if (data.transaction_type == "Deposit") {
            this.$refs.showTransferDeposit.showModal();
          } else if (data.transaction_type == "Withdrawal") {
            this.$refs.showTransferWithdrawal.showModal();
          }
          break;
      }
    },
  }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
