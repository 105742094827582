import CryptoJS from 'crypto-js';

const secretKey = 'cbafrontend-encryped@2024'; // Keep this key safe

// Encrypt data
function encryptData(data) {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  return encrypted;
}

// Decrypt data
function decryptData(encryptedData) {
  try {
    if (!encryptedData || encryptedData.trim() === '') {
      throw new Error('Encrypted data is empty or invalid');
    }

    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

    // Check if the decrypted string is non-empty and valid JSON
    // console.log('Decrypted string:', encryptedData); // Debugging output

    if (!decryptedString) {
      throw new Error('Decrypted string is empty');
    }

    // Try parsing the decrypted string as JSON
    try {
      const decrypted = JSON.parse(decryptedString);
      return decrypted;
    } catch (e) {
      throw new Error('Error parsing decrypted string as JSON: ' + e.message);
    }
  } catch (e) {
    console.error('Decryption Error:', e);
    return null; // Handle error accordingly
  }
}



export { encryptData, decryptData };
