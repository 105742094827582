<template>
  <div class="container-fluid">
    <div class="card card-shape home-box">
      <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 fw-bold text-danger">
          Bulk Withdrawals
        </h6>
        <b-link title="Download Excel Format" @click="downloadFile" class="fw-semibold ms-2 akkurate-danger">
          <font-awesome-icon :icon="['fas', 'download']" /></b-link>

      </div>
      <div class="card-body">
        <div class="err-accounts text-center my-2">
          <errors v-if="message">
            {{ message }}
          </errors>
        </div>
        <div class="card card-shape home-box mb-3 border border-danger">
          <div class="card-body py-2">
            <div class="row mb-3">
              <div class="col-md-10">
                <transaction-form :deposit_or_withdrawal="'Withdrawal'" :form="form" :gl_options="gl_credit_options"
                  @showExcelUploads="showExcelUploads">
                </transaction-form>
              </div>
              <div class="col-md-2 mt-auto">
                <b-button :disabled="isSaving" class="btn-size w-75 akkurate-red-btn"
                  @click="saveBulkWithdrawal"><font-awesome-icon class="small" :icon="['fas', 'money-bill']" />
                  Withdraw</b-button>
              </div>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <vue-good-table styleClass="vgt-table bordered striped" mode="remote" ref="allAccountTable"
              :columns="account_columns" :rows="account_rows" :isLoading.sync="isLoading" :totalRows="totalRecords"
              :select-options="{
                enabled: true,
                disableSelectInfo: true, // disable the select info panel on top
              }">
              <!-- Add your custom delete button column -->
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'member_fullname'">
                  <span>
                    {{ props.row.customer?.first_name }}
                    {{ props.row.customer?.last_name }}
                  </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <!-- <span v-if="props.column.field == 'action'">
                        <div class="dropdown no-arrow d-inline">
                          <a
                            class="btn btn-sm me-2 akkurate-ash text-white dropdown-toggle"
                            id="dropdownMenu"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <font-awesome-icon :icon="['fas', 'money-bill']" />
                            <span>deposit</span>
                          </a>
                          <div
                            class="dropdown-menu shadow animated--fade-in"
                            aria-labelledby="dropdownMenu"
                          >
                            <div class="dropdown-header">Deposit</div>
                            <a class="dropdown-item" href="#">Cash</a>
                            <a class="dropdown-item" href="#">Cheque</a>
                            <a class="dropdown-item" href="#">Mobile Money</a>
                            <a class="dropdown-item" href="#">Direct Debit</a>
                          </div>
                        </div>

                        <div class="dropdown no-arrow d-inline">
                          <a
                            class="btn btn-sm me-2 akkurate-ash text-white dropdown-toggle"
                            id="dropdownMenu"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <font-awesome-icon :icon="['fas', 'money-bill']" />
                            <span>withdraw</span>
                          </a>
                          <div
                            class="dropdown-menu shadow animated--fade-in"
                            aria-labelledby="dropdownMenu"
                          >
                            <div class="dropdown-header">Withdraw</div>
                            <a class="dropdown-item" href="#">Cash</a>
                            <a class="dropdown-item" href="#">Cheque</a>
                            <a class="dropdown-item" href="#">Mobile Money</a>
                            <a class="dropdown-item" href="#">Direct Debit</a>
                          </div>
                        </div>

                        <button
                          class="btn btn-sm me-2 akkurate-ash text-white"
                          @click="confirmDeleteUser(props.row)"
                        >
                          <font-awesome-icon :icon="['fas', 'share']" />
                          <span>transfer</span>
                        </button>
                      </span> -->
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TransactionForm from "./form/TransactionForm.vue";
import ApiService from "@/core/services/api.service";
import config from '@/config.js'

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    TransactionForm,
  },
  data() {
    return {
      ...this.initialState(),
      message: null,
      // jsonData:null,
      account_columns: [
        {
          label: "New Cust. Code",
          field: "new_customer_code",
          // width: '180px',
        },
        {
          label: "Old Cust. Code",
          field: "old_customer_code",
          // width: '180px',
        },
        {
          label: "Amt. (GHS)",
          field: "amount",
          // width: '10px',
        },
        {
          label: "GL Debit",
          field: "product_type",
          type: "string",
        },
        // {
        //   label: "GL Credit",
        //   field: "gl_credit",
        //   type: "string",
        // },
        {
          label: "Date",
          field: "transaction_date",
          type: "string",
        },
        {
          label: "Description",
          field: "description",
          type: "string",
        }
      ],
      account_rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {},
        sort: [
          {
            field: "", // example: 'name'
            type: "", // 'asc' or 'desc'
          },
        ],
        page: 1, // what page I want to show
        perPage: 20, // how many items I'm showing per page
      },
    };
  },
  mounted() {
    this.getAccountDropdown();
    this.loadAllAccount();
  },
  methods: {
    async getAccountDropdown() {
      this.$Progress.start();
      this.isLoading = true;
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // console.log(response);
          this.form.branch_option = response.data.branches;
          this.gl_credit_options = response.data.coa_subheads;
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    async loadAllAccount(searchFilter = null) {
      var branch_id = "";
      var acc_id = "";
      if (this.form.branch != null) {
        branch_id = this.form.branch.id;
      }
      if (this.form.acc_types != null) {
        acc_id = this.form.acc_types.id;
      }
      this.$Progress.start();
      this.isLoading = true;
      // await ApiService.post("/accounts/paginate", {
      //   page: this.serverParams.page,
      //   per_page: this.serverParams.pagePage,
      //   filter: this.form.account_number,
      //   branch_id: branch_id,
      //   account_type_id: acc_id,
      //   account_number: this.form.account_number,
      //   phone_number: this.form.phone,
      //   start_date: this.form.start_date,
      //   end_date: this.form.end_date,
      //   status: this.form.status, //Inactive,	Active, Dormant, Closed
      // })
      //   .then((response) => {
      //     console.log(response.data.data);
      //     this.account_rows = response.data.data;
      //     this.totalRecords = response.data.meta.total;
      //     this.$Progress.finish();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      this.isLoading = false;
    },

    filterTable() {
      this.loadAllAccount();
    },
    async saveBulkWithdrawal() {
      this.isSaving = true;

      const bulk_withdrawal = {
        transaction_date: this.form.transaction_date,
        description: this.form.description,
        gl_credit: this.form.gl_debit,
        accounts: this.account_rows,
      };
      await ApiService.post("/transactions/withdrawals/bulk/store", bulk_withdrawal)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();

          // toast.fire({
          //   icon: "success",
          //   title: response.data.message,
          // });
          swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showCancelButton: false, // Show the "Cancel" button
            confirmButtonText: 'OK', // Customize the text for the "OK" button
            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
            customClass: {
              confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
            }
          });

          this.isSaving = false;
          // this.form.reset();
          this.form = this.initialState().form;
          this.account_rows = [];
          this.form.gl_debit = "";
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          console.log(error.response.data.message);
          if (error.response.data) {
            this.message = error.response.data.message
          }
        });
      // console.log(bulk_deposit);
    },
    showExcelUploads(account_rows) {
      this.account_rows = account_rows
    },
    initialState() {
      return {
        totalRecords: 0,
        searchFilter: "",
        form: {
          branch: null,
          branch_option: [],
          acc_types: "",
          gl_debit: "",
          // gl_debit_option: [],
          phone: "",
          account_number: "",
          start_date: "",
          end_date: "",
          status: "",
          description: "",
          transaction_date: "",
          transaction_type: "Withdrawal",
          status_option: ["Active", "Inactive", "Dormant", "Closed"],
        },
        gl_credit_options: [],
        isLoading: true,
        isSaving: false,
        add_or_edit: "add",
      };
    },
    downloadFile() {
      // Replace 'example.pdf' with the filename you want to download from your public directory
      const fileName = 'BULK_DEPOSITS_UPLOAD_TEMPLATE.xlsx';

      // console.log(fileName);  

      // Construct the URL to the file in the public directory
      const fileUrl = `${config.base_url}/assets/${fileName}`;

      //   console.log(fileUrl);

      // Create a link element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
    },
  },
};
</script>
