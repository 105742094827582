<template>
    <form @submit.prevent="filterTable">
        <div class="row mb-3">
            <div class="col-md-5">
                <label class="akkurate-dark small" for="filter">Search</label>
                <b-form-group id="filter" label-for="filter" class="akkurate-dark">
                    <b-form-input id="filter" class="mt-2 field-container fields" type="text" v-model="form.filter"
                        placeholder="Search by code, name, phone ...">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="acc_types">Account type </label>
                <multiselect class="mt-2 field-container" v-model="form.acc_types" :options="form.acc_types_option"
                    track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                </multiselect>
            </div>

            <div class="col">
                <label class="akkurate-dark small" for="account_number">Account number</label>
                <b-form-group id="account_number" label-for="account_number" class="akkurate-dark">
                    <b-form-input id="account_number" class="mt-2 field-container fields" type="text"
                        v-model="form.account_number" placeholder="Enter Account number">
                    </b-form-input>
                </b-form-group>
            </div>
            <!-- <div class="col-12 col-md-2">
                <label class="akkurate-dark" for="loan-re-date">Start Date</label>
                    <b-form-group
                    id="loan-re-date"
                    label-for="loan-re-date">
                    <b-form-input
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="form.start_date"
                    required
                    ></b-form-input>
                    </b-form-group>
            </div>
            <div class="col-12 col-md-2">
                <label class="akkurate-dark" for="loan-re-date">End Date</label>
                    <b-form-group
                    id="loan-re-date"
                    label-for="loan-re-date">
                    <b-form-input
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="form.end_date"
                    required
                    ></b-form-input>
                    </b-form-group>
            </div> -->
            
            <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                        class="fas fa-search me-2"></i>Search</b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>
        </div>
        <div class="row mb-3" v-if="more_details">
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" v-model="form.branch" :options="form.branch_option"
                    track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="acc_types">Account type </label>
                <multiselect class="mt-2 field-container" v-model="form.acc_types" :options="form.acc_types_option"
                    track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="type">Status</label>
                <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option"
                    selectLabel="" deselectLabel="">
                </multiselect>
            </div>
            

        </div>
    </form>

</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form', 'filterTable'],
    data() {
        return {
            transaction_option: ['Deposit', 'Withdrawal'],
            debit_option: ['Account One', 'Account Two'],
            credit_option: ['Account One', 'Account Two'],
            more_details: false,

        }
    },
    methods: {
        show_more() {
            this.more_details = !this.more_details
        }
    }
}

</script>