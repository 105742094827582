UsersIndex

<template>
    <div id="wrapper">
        <SidebarView :user="true" :user-visible="true" :attendance="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="mx-3">
                    <!-- begin row  -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <div class="card card-shape home-box">
                                    <div class="card-header d-flex flex-row align-items-center justify-content-between">
                                        <h6 class="m-0 fw-bold text-green">All Attendance</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class=" home-box border border-success p-3">
                                            <form @submit.prevent="filterTable">
                                                <div class="row">
                                                    <div class="col">
                                                        <label class="akkurate-dark small" for="search_term">Search
                                                            Term</label>
                                                        <b-form-group id="search_term" label-for="search_term">
                                                            <b-form-input class="mt-2 field-container fields"
                                                                type="text" v-model="searchFilter"
                                                                placeholder="Search by firstname, lastname, phone number, email">
                                                            </b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                    <div
                                                        class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                                                        <b-button type="submit"
                                                            class="akkurate-green-btn akkurate-auth-size w-100"><i
                                                                class="fas fa-search me-2"></i>Search</b-button>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="table_text_size">
                                            <b-table striped bordered responsive="md" id="attendance-table"
                                                :busy.sync="isBusy" :items="rows" :fields="fields"
                                                :tbody-tr-class="rowClass" show-empty>
                                                <template #table-busy>
                                                    <div class="text-center text-danger my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong>Loading...</strong>
                                                    </div>
                                                </template>
                                                <template #empty="scope">
                                                    <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                                                </template>
                                                <!-- A custom formatted column -->
                                                <template #cell(fullname)="data">
                                                    <div>{{ data.item.users.first_name }} {{ data.item.users.surname }}</div>
                                                </template>
                                                <template #cell(checkin)="data">
                                                    <span class="d-flex">
                                                        <span v-if="data.item.attendance?.timein">
                                                            <div>{{ data.item.attendance?.timein }}</div>
                                                        </span>
                                                        <span v-else>
                                                            <b-link @click="checkAction(data.item.users?.id, 'in')"
                                                                class="fw-semibold akkurate-green">
                                                                <font-awesome-icon
                                                                    :icon="['fas', 'door-open']" /></b-link>
                                                        </span>
                                                    </span>

                                                </template>
                                                <template #cell(checkout)="data">
                                                    <span class="d-flex">
                                                        <span v-if="data.item.attendance?.timeout">
                                                            <div>{{ data.item.attendance?.timeout }}</div>
                                                        </span>
                                                        <span v-else>
                                                            <b-link @click="checkAction(data.item.users?.id, 'out')"
                                                                class="fw-semibold akkurate-danger">
                                                                <font-awesome-icon
                                                                    :icon="['fas', 'door-open']" /></b-link>
                                                        </span>
                                                    </span>
                                                </template>
                                            </b-table>
                                            <div class="d-flex justify-content-between">
                                                <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                                                    :total-rows="totalRecords" :per-page="perPage"
                                                    aria-controls="attendance-table"></b-pagination>
                                                <div class="col text-end mt-1">
                                                    <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                                                    <b-form-group label="Per Page" label-size="sm" class="mb-0">
                                                        <b-form-select @change="selectPerPage" id="per-page-select"
                                                            v-model="perPage" :options="per_page_options"
                                                            size="sm"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <!-- end row  -->
                </div>
            </div>
            <Footer></Footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

// import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';

// import * as XLSX from 'xlsx';
// import FileSaver from 'file-saver';
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";
// import UsersView from '../view/UsersView.vue'

export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        // UsersView
    },
    mounted() {
        let token = localStorage.getItem('token');
        if (!token) {
            this.$router.push({ path: '/login' });
        }
        this.loadItems()
    },
    data() {
        return {
            totalRecords: 0,
            searchFilter: "",
            isBusy: true,
            currentPage: 1,
            perPage: 10,
            per_page_options: ['10', '20', '50', '100'],
            per_page_selected: "10",
            attendance: [],
            rows: [],
            fields: [
                {
                    label: 'ID',
                    key: 'users.id',
                },
                {
                    label: 'Full_name',
                    key: 'fullname',
                },
                {
                    label: 'Role(s)',
                    key: 'users.roles[0].name',
                },
                {
                    label: 'Branch',
                    key: 'users.branch.name',
                },
                {
                    label: 'Checkin',
                    key: 'checkin',
                },
                {
                    label: 'Checkout',
                    key: 'checkout',
                },
                // {
                //     label: 'Action',
                //     field: 'action',
                //     html: true,
                //     //   formatFn: this.renderActions,
                // },
            ],
            users: [],

        };
    },
    methods: {
        navigatePage(index) {
            this.isBusy = true;
            this.currentPage = index;
            this.loadItems()
        },
        selectPerPage(index) {
            this.perPage = index
            this.loadItems()
        },
        filterTable() {
            this.loadItems();
        },

        rowClass() {
            return 'custom-row-height'
        },
        // load items is what brings back the rows from server
        // async loadItems(searchFilter = null) {
        //     //   getFromServer(this.serverParams).then(response => {
        //     //      this.totalRecords = response.totalRecords;
        //     //      this.rows = response.rows;
        //     //   });
        //     this.isLoading = true;
        //     await ApiService.post("users/paginate",
        //         {
        //             "page": this.serverParams.page,
        //             "per_page": this.serverParams.perPage,
        //             "filter": this.searchFilter,
        //         })
        //         .then(response => {
        //             // console.log(response.data.data)
        //             this.users = response?.data?.data;
        //             this.rows = response?.data?.data?.map(index => {
        //                 const attendance = this.filterAttenders(index.id);
        //                 return {
        //                     users: index,
        //                     attendance: attendance
        //                 }
        //             })
        //             console.log(this.rows);

        //         }).catch(() => {
        //             // console.log(error);
        //         })
        //         .finally(() => {
        //             // this.isLoading = false
        //         });
        // },
        async loadItems() {
            // this.isLoading = true;
            this.isBusy = true;

            try {
                const response = await ApiService.post("users/paginate", {
                    "page": this.currentPage,
                    "per_page": this.perPage,
                    "filter": this.searchFilter,
                });

                // Ensure the response structure is correct before accessing it
                if (response && response.data && response.data.data) {
                    this.users = response.data.data;

                    // Use Promise.all to wait for all async filterAttenders calls to complete
                    const rows = await Promise.all(
                        this.users.map(async user => {
                            const attendance = await this.filterAttenders(user.id);
                            return {
                                users: user,
                                attendance: attendance?.[0]
                            };
                        })
                    );

                    this.rows = rows;
                    console.log(this.rows); // This should now log an array of user and attendance objects
                    this.isBusy = false;
                } else {
                    console.error('Invalid response structure', response);
                }
            } catch (error) {
                // Log any errors during the request
                console.error('API request failed:', error);
            } finally {
                // Reset loading state after the request is complete
                // this.isLoading = false;
                this.isBusy = false;
            }
        },


        async filterAttenders(user_id = null) {
            // this.isLoading = true; // If you're only handling this for one user at a time, this is okay, but consider if you need to manage this globally

            try {
                const response = await ApiService.query(`/attendances`, {
                    params: {
                        user_id: user_id,
                        start_date: new Date().toISOString().split("T")[0],
                        end_date: new Date().toISOString().split("T")[0],
                    }
                });

                // Return attendance data so that it's used in the map function
                // console.log(response.data.data);
                return response?.data?.data || [];
            } catch (error) {
                console.error('Attendance fetch failed:', error);
                return []; // Return an empty array if there's an error
            } finally {
                // You can also add a global isLoading reset here if needed
                // this.isLoading = false;
            }
        },


        async checkAction(user_id = null, check) {
            const { value: date } = await swal.fire({
                title: check === 'in' ? "select checkin time" : "select checkout time",
                input: "time",
                didOpen: () => {
                    const today = (new Date()).toISOString();
                    swal.getInput().min = today.split("T")[1];
                },
                customClass: {
                    confirmButton: check === 'in' ? 'btn akkurate-green-btn modal-btn-width text-white me-2' : 'btn akkurate-danger-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
            });
            if (date) {
                const form = {
                    user_id: user_id,
                    action: check === 'in' ? 'timein' : 'timeout',
                    timein_date_time: check === 'in' ? date : null,
                    timeout_date_time: check === 'out' ? date : null,
                }
                console.log(form);
                ApiService.post(`/attendances`, form)
                    .then((response) => {
                        console.log(response);
                        swal.fire({
                            title: "Success",
                            text: response.data.message,
                            icon: "success",
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                        });
                        this.loadItems();

                    }).catch(() => {

                    })
            }
        },
        // async checkoutAction(user_id = null) {
        //     const { value: date } = await swal.fire({
        //         title: "select checkin date",
        //         input: "date",
        //         confirmButtonColor: "#3085d6",
        //         didOpen: () => {
        //             const today = (new Date()).toISOString();
        //             swal.getInput().min = today.split("T")[0];
        //         }
        //     });
        //     if (date) {
        //         swal.fire("Departure date", date);
        //     }
        // }

        // confirmDeleteUser(user) {

        //   swal.fire({
        //     title: "Disable User",
        //     text: `Are you sure you want to disable ${user.username}?`,
        //     icon: "warning",
        //     showCancelButton: false,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Ok",
        //     allowOutsideClick: false
        //   }).then(result => {
        //     if (result.value) {
        //       //   window.location.reload();
        //     }
        //   });

        // },
        // resetPassword(user) {
        //   swal.fire({
        //     title: "Reset User Password",
        //     text: `Are you sure you want to reset the password for ${user.first_name} ${user.surname}?`,
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Yes",
        //     cancelButtonText: "No",
        //     buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        //     customClass: {
        //       confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
        //       cancelButton: 'btn akkurate-danger-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
        //     }
        //     // allowOutsideClick: false
        //   }).then(result => {
        //     if (result.value) {
        //       //   window.location.reload();
        //       //send put request to reset password
        //       ApiService.put(`users/password/reset/${user.id}`)
        //         .then(response => {
        //           swal.fire({
        //             title: "Success",
        //             text: response.data.message,
        //             icon: "success",
        //             showCancelButton: false,
        //             confirmButtonColor: "#3085d6",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: "Ok",
        //             allowOutsideClick: false
        //           });
        //         })
        //         .catch(error => {
        //           swal.fire({
        //             title: "Error",
        //             text: error.response.data.message,
        //             icon: "error",
        //             showCancelButton: false,
        //             confirmButtonColor: "#3085d6",
        //             cancelButtonColor: "#d33",
        //             confirmButtonText: "Ok",
        //             // allowOutsideClick: false
        //           });
        //         });
        //     }
        //   });

        // },
        // exportToExcel() {
        //   const workbook = XLSX.utils.book_new();

        //   // Get the table data
        //   const tableData = this.$refs.userTable.filteredRows[0].children;

        //   // Convert the table data to a worksheet
        //   const worksheet = XLSX.utils.json_to_sheet(tableData);

        //   // Add the worksheet to the workbook
        //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        //   // Generate the Excel file buffer
        //   const excelBuffer = XLSX.write(workbook, {
        //     type: 'array',
        //     bookType: 'xlsx'
        //   });

        //   // Save the Excel file
        //   FileSaver.saveAs(
        //     new Blob([excelBuffer], { type: 'application/octet-stream' }),
        //     'table_data.xlsx'
        //   );
        // }
    }
    // methods: {
    //   getUserOptions() {
    //     this.$Progress.start();
    //     this.isLoading = true;
    //     // axios
    //     //   .get("roles/dropdown")

    //     $(this.$refs.dataTable).DataTable({
    //       serverSide: true,
    //       ajax: {
    //         url: config.api_url + '/users/paginate', // Replace with your server-side endpoint URL
    //         type: 'POST',
    //         beforeSend: function (xhr) {
    //           // Set your authorization header here
    //           xhr.setRequestHeader('Authorization', 'Bearer ' + JwtService.getToken());
    //         },
    //         data: function (data) {

    //           //   this.isLoading = false;
    //           // Customize the request parameters as needed
    //           //   data.page = data.start / data.length + 1;
    //           //   data.pageSize = data.length;
    //           // Add any additional parameters you need, such as sorting information
    //         },
    //         dataSrc: function (json) {
    //           // Handle the server's response here
    //           // Update the DataTables table with the received data
    //           return json.data;
    //         },
    //       },
    //       columns: [
    //         // Define your table columns here
    //         // { data: 'id', title: 'ID' },
    //         { data: 'first_name', title: 'First Name' },
    //         { data: 'surname', title: 'Sur Name' },
    //         { data: 'email', title: 'Email' },
    //         { data: 'username', title: 'Username' },
    //         // Add more columns as needed
    //       ],
    //     });


    //   },
    // }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
