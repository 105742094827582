<template>
    <div>
        <a title="View" class="btn btn-sm akkurate-warning text-white" v-b-modal="data.id + view">
            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'eye']" /></span>
        </a>

        <b-modal
            :id="data.id + view"
            ref="modal"
            size="xl">
            <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5>Product Type View</h5>
            <b-button variant="white" @click="close()">
                &times;
            </b-button>
            </template>
                <div class="row">
                    <div class="col-12 col-md-1">
                        <label class="akkurate-dark" for="title">ID</label>
                            <b-form-group
                            id="id"
                            label-for="id">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.id"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-3">
                        <label class="akkurate-dark" for="name">Name</label>
                            <b-form-group
                            id="name"
                            label-for="name">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.name"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="base_type">Base Type</label>
                            <b-form-group
                            id="base_type"
                            label-for="base_type">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.base_type"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="search_term">Code</label>
                            <b-form-group
                            id="code"
                            label-for="code">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.code"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="w_allowed">Withdrawal Allowed</label>
                            <b-form-group
                            id="w_allowed"
                            label-for="w_allowed">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="w_allowed"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="d_allowed">Deposit Allowed</label>
                            <b-form-group
                            id="d_allowed"
                            label-for="d_allowed">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="d_allowed"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="m_balance">Minimum Balance</label>
                            <b-form-group
                            id="m_balance"
                            label-for="m_balance">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="m_balance"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="s_sms">Send Sms</label>
                            <b-form-group
                            id="s_sms"
                            label-for="s_sms">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="s_sms"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    
                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="daily_deposit_amount_limit">Daily Deposit Amount Limit (GHS)</label>
                            <b-form-group
                            id="daily_deposit_amount_limit"
                            label-for="daily_deposit_amount_limit">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.daily_deposit_amount_limit"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="daily_deposit_frequency_limit">Daily Deposit Frequency Limit</label>
                            <b-form-group
                            id="daily_deposit_frequency_limit"
                            label-for="daily_deposit_frequency_limit">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.daily_deposit_frequency_limit"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="daily_deposit_frequency_limit">Daily Withdrawal Amount Limit (GHS)</label>
                            <b-form-group
                            id="daily_withdrawal_amount_limit"
                            label-for="daily_withdrawal_amount_limit">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.daily_withdrawal_amount_limit"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="daily_withdrawal_frequency_limit">Daily Withdrawal Frequency Limit</label>
                            <b-form-group
                            id="daily_withdrawal_frequency_limit"
                            label-for="daily_withdrawal_frequency_limit">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.daily_withdrawal_frequency_limit"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <label class="akkurate-dark" for="created_at">Created At</label>
                            <b-form-group
                            id="created_at"
                            label-for="created_at">
                            <b-form-input
                            class="mb-3 mt-2 field-container fields"
                            type="text"
                            v-model="data.created_at"
                            disabled
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <template #modal-footer="{ ok }">
                    <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(data.id + view)" type="button" class="akkurate-green-btn modal-btn-width ms-2 float-right"><span class="small"><font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Okay</span></b-button>
                    </div>
                </template>  
        </b-modal>
    </div>
</template>
<script>
export default{
    name: "AccountTypeView",
    props:['data','view'],
    data(){
        return{
            w_allowed: this.data.withdrawal_allowed == 1 ? 'Yes' : 'No',
            d_allowed: this.data.deposit_allowed == 1 ? 'Yes' : 'No',
            m_balance: this.data.minimum_balance == 1 ? 'Yes' : 'No',
            s_sms: this.data.send_sms == 1 ? 'Yes' : 'No',
        }
    }
}
</script>