<template>
    <div class="card card-shape home-box">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col">
                    <h6 class="m-0 fw-bold text-green">Fixed Deposit Statement</h6>
                </div>
                <div class="col text-end d-flex align-items-center justify-content-end">
                    <certificate :data="details" :institution="institution">
                    </certificate>
                    <fd-filter :type="'statement'" :adv_filter="adv_filter" :isSearching="isSearching"
                        :search="search" @filterFixedDeposit="filterFixedDeposit"></fd-filter>
                    <b-link class="fw-semibold ms-2 akkurate-green" @click="printPDF">
                        <font-awesome-icon :icon="['fas', 'file-pdf']"  /></b-link>
                    <b-link class="fw-semibold ms-2 akkurate-green" @click="downloadPDF">
                        <font-awesome-icon :icon="['fas', 'download']"  />
                    </b-link>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="container text-start" ref="pdfContent">
                    <statement-institution :institution="institution"
                        :title="'FIXED DEPOSIT STATEMENT OF ACCOUNT'"></statement-institution>
                    <hr class="mt-0 akkurate-danger" />

                    <div class="row">
                        <div class="col-md-5">
                            <h6 class="akkurate-dark report-l-text">ACCOUNT DETAILS</h6>
                            <hr class="w-100 mt-0" />
                            <div class="row akkurate-small">
                                <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                                    Customer Name
                                </div>
                                <div class="col-md-8 report-s-text">
                                    {{ getFullName }}
                                </div>
                                <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                                    Ext. Code
                                </div>
                                <div class="col-md-8 report-s-text">
                                    {{ this.details.account?.customer.external_code }}
                                </div>
                                <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                                   Fixed Deposit Code
                                </div>
                                <div class="col-md-8 report-s-text">
                                    {{ details?.code }}
                                </div>
                                <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                                    Branch
                                </div>
                                <div class="col-md-8 report-s-text">
                                    {{ getBranch }}
                                </div>
                                <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                                    Currency
                                </div>
                                <div class="col-md-8 report-s-text">
                                    {{ institution?.currency_code }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                        <div class="col-md-5">
                            <h6 class="akkurate-dark report-l-text">ACCOUNT SUMMARY</h6>
                            <hr class="w-100 mt-0" />
                            <div class="row akkurate-small">
                                <div class="col-md-5 fw-bold akkurate-gray">
                                    Principal
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ institution?.currency_code }} {{
                                        displayNumber(details?.principal_amount) }}
                                </div>
                                <div class="col-md-5 fw-bold akkurate-gray">
                                    Expected Interest
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ institution?.currency_code }} {{
                                        displayNumber(details?.interest_expected) }}
                                </div>
                                <div class="col-md-5 fw-bold akkurate-gray">
                                    Interest Due
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ institution?.currency_code }} {{
                                        displayNumber(details?.interest_due) }}
                                </div>
                                <div class="col-md-5 fw-bold akkurate-gray ">
                                    Rate
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ details?.interest_rate }}
                                </div>
                                <div class="col-md-5 fw-bold akkurate-gray ">
                                    Tenure
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ details?.tenor }}
                                </div>
                                <div class="col-md-5 fw-bold akkurate-gray ">
                                    Status
                                </div>
                                <div class="col-md-7 report-s-text">
                                    {{ details.status }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-6">
                            <h6 class="report-l-text">ACCOUNT TRANSACTIONS</h6>
                        </div>
                        <div class="col-6 text-end">
                            <h6 class="report-l-text"><span class="fw-bold">From:</span> {{
                                adv_filter?.start_date }}
                                <span class="fw-bold">To:</span> {{ adv_filter?.end_date }}
                            </h6>
                        </div>
                        <div class="col-12 d-flex my-3 flex-wrap">
                            <div class="d-flex align-items-center">
                                <p class="mb-0 akkurate-extra-small me-2 akkurate-dark">
                                    <font-awesome-icon :icon="['fas', 'circle']" />
                                </p>
                                <span class="akkurate-extra-small me-2">Prev.BAL(Previous
                                    Balance)</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 akkurate-extra-small me-2 akkurate-orange-text">
                                    <font-awesome-icon :icon="['fas', 'circle']" />
                                </p>
                                <span class="akkurate-extra-small me-2">PP(Principal Paid)</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 akkurate-extra-small me-2 akkurate-danger">
                                    <font-awesome-icon :icon="['fas', 'circle']" />
                                </p>
                                <span class="akkurate-extra-small me-2">PB(Principal Balance)</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 akkurate-extra-small me-2 akkurate-green">
                                    <font-awesome-icon :icon="['fas', 'circle']" />
                                </p>
                                <span class="akkurate-extra-small me-2">IP(Interest Paid)</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 akkurate-extra-small me-2 akkurate-blue-text">
                                    <font-awesome-icon :icon="['fas', 'circle']" />
                                </p>
                                <span class="akkurate-extra-small me-2">IB(Interest Balance)</span>
                            </div>
                        </div>
                        <div class="col-md-12 trans_details">
                            <b-table responsive :fields="statement_fields" :items="statement_items">
                                <template #cell(debit)="data">
                                    <p class="mb-0" v-if="data?.item?.transaction_type == 'FD Withdrawal'">{{
                                        displayNumber(data?.item?.trans_amount) }}</p>
                                </template>

                                <template #cell(credit)="data">
                                    <p class="mb-0" v-if="data?.item?.transaction_type == 'FD Deposit'">{{
                                        displayNumber(data?.item?.trans_amount) }}</p>
                                </template>

                                <template #cell(description)="data">
                                    <p class="mb-0">{{ data?.item?.transaction_type }} By {{
                                        data?.item?.transaction_mode }}</p>
                                </template>
                            </b-table>
                        </div>
                        <div class="col-md-12 text-center end_statemt">
                            *********************************
                            END OF STATEMENT
                            *********************************
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import FdFilter from "./FdFilter.vue";
import Certificate from "../../../reports/Certificate.vue";
export default {
    props: ['institution', 'fixed_deposit'],
    components: {
        StatementInstitution,
        FdFilter,
        Certificate,
    },
    data() {
        return {
            adv_filter: {
                start_date: this.getOneMonthBeforeDate(),
                end_date: new Date().toISOString().split('T')[0],
            },
            isSearching: false,
            search: "Search",
            details: {},
            statement_fields: [
                {
                    key: 'trans_date',
                    label: 'Date',
                },

                {
                    key: 'description',
                    label: 'Description',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },

                {
                    key: 'previous_balance',
                    label: 'Prev.BAL',
                },
                {
                    key: 'principal',
                    label: 'Principal',
                },
                {
                    key: 'interest',
                    label: 'Interest Due',
                },
                {
                    key: 'principal_paid',
                    label: 'PP',
                },
                {
                    key: 'principal_balance',
                    label: 'PB',
                },
                {
                    key: 'interest_paid',
                    label: 'IP',
                },
                {
                    key: 'interest_balance',
                    label: 'IB',
                },
            ],
            statement_items: [],
        }
    },
    computed: {
        getFullName() {
            const first_name = this.details?.account?.customer?.first_name != undefined ? this.details?.account?.customer?.first_name : "";
            const middle_name = this.details?.account?.customer?.middle_name != undefined ? this.details?.account?.customer?.middle_name : "";
            const last_name = this.details?.account?.customer?.last_name != undefined ? this.details?.account?.customer?.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getBranch() {
            const branch = this.details?.account?.customer?.branch?.name ? this.details?.account?.customer?.branch?.name : ""
            return branch
        },
    },
    mounted(){
        // console.log('code',this.fixed_deposit)
        if(this.fixed_deposit?.code){
            this.filterFixedDepositStatement();
        }
    },
    methods: {
        filterFixedDeposit(){
            this.filterFixedDepositStatement();
        },
        filterFixedDepositStatement() {
            // console.log(this.filter.start_date);
            // console.log(this.loan.loan_number)
            this.search = "processing...";
            this.isSearching = true;
            ApiService.query("/investments/fixedDepositStatements", {
                params: {
                    fd_code: this.fixed_deposit?.code,
                    start_date: this.adv_filter?.start_date,
                    end_date: this.adv_filter?.end_date,
                }
            }).then((response) => {
                // console.log('details',response.data.data);
                this.details = response.data.data;
                // console.log(this.details);
                this.isLoading = false;
                // console.log(response.data.data.account.transactions);
                this.statement_items = response.data.data.account.transactions.map((index) => {
                    return {
                        id: index.id,
                        trans_date: index.trans_date,
                        transaction_type: index.transaction_type,
                        transaction_mode: index.transaction_mode,
                        trans_amount: index.trans_amount,
                        debit: Math.round(index.debit * 100) / 100,
                        credit: Math.round(index.credit * 100) / 100,
                        previous_balance: Math.round(index.previous_balance * 100) / 100,
                        principal: Math.round(index.principal * 100) / 100,
                        principal_paid: Math.round(index.principal_paid * 100) / 100,
                        principal_balance:Math.round(index.principal_balance * 100) / 100,
                        interest_paid: Math.round(index.interest_paid * 100) / 100,
                        interest_balance: Math.round(index.interest_balance * 100) / 100,
                        interest: Number(index.interest).toFixed(2),
                        denominations: index.denominations,
                        loan_interest_due: index.loan_interest_due,
                        loan_principal_due: index.loan_principal_due,
                        cheque_number: index.cheque_number,
                        code: index.code
                    }
                });
                // this.handleImageLoad();
                this.search = "Search";
                this.isSearching = false;
            }).catch((error) => {
                this.search = "Search";
                this.isSearching = false;
                // console.log(error)
                if (error.response.status == 404) {
                    swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: "Something went wrong!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }
            })
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },
        downloadPDF() {

            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }
                }).save();

        },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }

                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(pdfUrl, '_blank', 'width=800,height=600');
                    printWindow.onload = () => {
                        printWindow.print();
                        // URL.revokeObjectURL(url);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },
    }
}
</script>