<template>
    <div>
        <b-modal size="xl" ref="showMomoWithdrawal">
            <template #modal-title>
                <span class="akkurate-danger fw-bold">Mobile Money Withdrawal</span>
            </template>
            <div class="container-fluid" ref="pdfContent">
                <!-- First Receipt -->
                <div style="border-left: 5px solid #df3f50; border-bottom: 5px solid #df3f50;">

                    <institution :institution="report_data?.institution" :title="'MOMO TRANSACTION RECEIPT'"
                        :report_style="'withdrawal'"></institution>

                    <div class="row ps-0">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">MOBILE MONEY WITHDRAWAL SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                report_data?.branch_name
                            }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                report_data?.receipt_no
                            }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-0">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.transaction_mode }} {{
                                        report_data?.transaction_type }} By Self</p>
                                </div>

                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount)
                                        }} GHANA
                                        CEDIS
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#faeaeb">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-danger fw-bold mb-2">MOBILE MONEY DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">NETWORK NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-danger text-uppercase">{{ report_data?.network
                                        }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">MOBILE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.mobile }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">REFERENCES NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.momo_reference }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">EXTERNAL REFERENCE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.external_reference }}
                                    </p>
                                </div>


                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-danger">GRAND TOTAL</p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-danger">{{
                                        report_data?.institution?.currency_code }}
                                        {{
                                            displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="withdraw-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span>
                                {{ report_data?.institution?.telephone
                                }} or email at {{ report_data?.institution?.email }}</p>
                        </div>
                    </div>

                </div>


                <hr style="border-top: dotted 1px;" class="my-2" />

                <!-- Second Receipt -->
                <div style="border-left: 5px solid #df3f50; border-bottom: 5px solid #df3f50;">

                    <institution :institution="report_data?.institution" :title="'MOMO TRANSACTION RECEIPT'"
                        :report_style="'withdrawal'"></institution>

                    <div class="row ps-0">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">MOBILE MONEY WITHDRAWAL SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                report_data?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                report_data?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-0">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.transaction_mode }} {{
                                        report_data?.transaction_type }} By Self</p>
                                </div>

                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount)
                                        }} GHANA
                                        CEDIS
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#faeaeb">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-danger fw-bold mb-2">MOBILE MONEY DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">NETWORK NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-danger text-uppercase">{{ report_data?.network
                                        }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">MOBILE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.mobile }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">REFERENCES NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.momo_reference }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">EXTERNAL REFERENCE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-danger mb-2">{{ report_data?.external_reference }}
                                    </p>
                                </div>


                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-danger">GRAND TOTAL</p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-danger">{{
                                        report_data?.institution?.currency_code }}
                                        {{
                                            displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="withdraw-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span>
                                {{ report_data?.institution?.telephone
                                }} or email at {{ report_data?.institution?.email }}</p>
                        </div>
                    </div>

                </div>


            </div>

            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="generatePDF" type="button"
                        class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon
                                :icon="['fas', 'book']" /> Print</span></b-button>
                    <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                </div>
            </template>
        </b-modal>

    </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import Institution from '@/views/main/components/institution/Institution.vue'
export default {
    components: {
        Institution,
    },
    props: ['report_data'],
    methods: {
        showModal() {
            this.$refs['showMomoWithdrawal'].show()
        },
        hideModal() {
            this.$refs['showMomoWithdrawal'].hide()
        },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
    },

}
</script>