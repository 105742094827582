<template>
  <div class="container-fluid">
    <div class="row">
      <h5 class="akkurate-dark mb-0">Financial Performance</h5>
    </div>
    <hr class="py-0" />
    <div class="row mt-3">
      <div class="col-md-6 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-primary py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold mb-1 akkurate-green">
                    <span>Receipts</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-green">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold small mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_today)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-green">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold small mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_month)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-green">
                    <span>YTD</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold small mb-1 akkurate-green">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_year)) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-primary py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold mb-1 akkurate-danger">
                    <span>Payments</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-danger">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small fw-bold mb-1 akkurate-danger">
                    <span>{{ convertNumberToCurrency(Number(total_payments_today)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-danger">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase  small fw-bold mb-1 akkurate-danger">
                    <span>{{ convertNumberToCurrency(Number(total_payments_month)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-danger">
                    <span>YTD</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small fw-bold mb-1 akkurate-danger">
                    <span>{{ convertNumberToCurrency(Number(total_payments_year)) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-primary py-2 card-shape home-box">
            <div class="card-body">
              <div class="row align-items-center no-gutters">
                <div class="col-12 col-md-12">
                  <div class="text-uppercase fw-bold mb-1 akkurate-gray">
                    <span>Balance</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-gray">
                    <span>Today</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small fw-bold mb-1 akkurate-gray">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_today)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-gray">
                    <span>Month</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold small mb-1 akkurate-gray">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_month)) }}</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase small mb-1 akkurate-gray">
                    <span>YTD</span>
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  <div class="text-uppercase fw-bold mb-1 small akkurate-gray">
                    <span>{{ convertNumberToCurrency(Number(total_receipts_year)) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
    <!-- <div class="row">
      <h5 class="akkurate-dark my-3">Chart of Account / Transaction Listing</h5>
    </div> -->
    <hr class="py-0" />
    <div class="row">
      <div class="col-md-8 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>

          <div class="card mb-4 card-shape home-box">
            <div class="card-header py-3">
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 fw-bold akkurate-dark">Chart Of Account</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="showDateRange" class="btn-sm akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'filter']" />
                  </b-button>
                  <!-- <b-button @click="printPDF" class="btn-sm mx-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'book']" />
                  </b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </b-button> -->
                </div>
              </div>
              <div>
                <!-- <a type="button" @click="showDateRange" class="me-2 akkurate-gray akkurate-small">
                  <font-awesome-icon :icon="['fas', 'filter']" />
                </a> -->

              </div>
            </div>
            <div class="card-body">
              <form>
                <div class="row mb-3" v-show="date_range">
                  <div class="col" v-show="form.terms">
                    <label class="akkurate-dark small" for="start_date">Terms</label>
                    <b-form-group id="type" label-for="type" class="akkurate-dark">
                      <multiselect class="field-container mt-2" v-model="form.terms" name="terms"
                        placeholder="Search or select a Terms" :options="form.terms_options" @select="changeTerms">
                      </multiselect>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <label class="akkurate-dark small" for="start_date">Start Date</label>
                    <b-form-group id="start_date" label-for="start_date" class="akkurate-dark">
                      <b-form-input id="start_date" class="mt-2 field-container fields" type="date"
                        v-model="form.start_date" placeholder="Start Date">
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col">
                    <label class="akkurate-dark small" for="start_date">End Date</label>
                    <b-form-group id="start_date" label-for="start_date" class="akkurate-dark">
                      <b-form-input id="start_date" class="mt-2 field-container fields" type="date"
                        v-model="form.end_date" placeholder="Start Date">
                      </b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-3 mt-auto">
                    <b-button type="submit" @click="searchCOA" class="akkurate-green-btn float-right border-0 w-100">
                      <font-awesome-icon :icon="['fas', 'search']" />Search
                    </b-button>
                  </div>
                </div>
              </form>
              <b-table class="table-text-small" :fields="fields" :items="items" responsive striped :per-page="perPage"
                :current-page="currentPage" small>
                <!-- <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template> -->
                <template #custom-foot>
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>TOTAL</b-th>
                    <b-th>{{ getDrTotal }}</b-th>
                    <b-th>{{ getCrTotal }}</b-th>
                    <b-th>{{ getTotal }}</b-th>
                  </b-tr>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col-md-12 text-end">
                <b-pagination class="text-end" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                  aria-controls="daily-control-tb" align="right">
                </b-pagination>
                <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-12">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <div class="card border-start-success card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold akkurate-dark">Withdrawal Listing</h6>
              <div></div>
            </div>
            <div class="card-content">
              <div class="card-body">
                <b-list-group>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Pending</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ pending_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(pending_withdrawal_amount) }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Approved</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ approval_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(approval_withdrawal_count) }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Completed</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ completed_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(completed_withdrawal_amount) }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Failed</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ failed_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(failed_withdrawal_amount) }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Reversed</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ reversed_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(reversed_withdrawal_amount) }}
                        </p>
                      </div>
                    </div>

                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="row">
                      <div class="col-4">
                        <p class="mb-0">
                          <span class="me-2">Rejected</span>
                        </p>
                      </div>
                      <div class="col-2">
                        {{ rejected_withdrawal_count }}
                      </div>
                      <div class="col-6">
                        <p class="mb-0">
                          {{ convertNumberToCurrency(rejected_withdrawal_amount) }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>

      <!--   
        <div class="col-xl-3 col-sm-6 col-12">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-card class="card-shape home-box">
                <b-skeleton width="85%" height="20%"></b-skeleton>
                <b-skeleton width="70%" height="20%"></b-skeleton>
                <b-skeleton width="30%" height="20%"></b-skeleton>
                <b-skeleton width="50%" height="20%"></b-skeleton>
                <b-skeleton width="90%" height="20%"></b-skeleton>
              </b-card>
            </template>
            <div class="card mb-4 card-shape home-box">
              <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
              >
                <h6 class="m-0 fw-bold akkurate-dark">Investment Allocation</h6>
              </div>
              <div class="card-body default-chart-size">
                <div class="pie-chart-area">
                  <InvestmentAllocationPieChart :chartData="chartData" />
                </div>
                <hr />
                <div class="d-flex flex-wrap">
                  <span
                    class="me-2 akkurate-small"
                    v-for="(field, index) in color_value"
                    :key="index"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'circle']"
                      :style="{ color: field.color }"
                    />
                    {{ field.name }}
                  </span>
                </div>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div>
        <div class="col-xl-6 col-sm-6 col-12">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <b-card class="card-shape home-box">
                <b-skeleton width="85%" height="20%"></b-skeleton>
                <b-skeleton width="70%" height="20%"></b-skeleton>
                <b-skeleton width="30%" height="20%"></b-skeleton>
                <b-skeleton width="50%" height="20%"></b-skeleton>
                <b-skeleton width="90%" height="20%"></b-skeleton>
              </b-card>
            </template>
            <div class="card mb-4 card-shape home-box">
              <div
                class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
              >
                <h6 class="m-0 fw-bold akkurate-dark">
                  Financial Performance Chart
                </h6>
              </div>
              <div class="card-body default-chart-size">
                <div>
                  <PerformanceLineChart :lineChartData="lineChartData" />
                </div>
              </div>
            </div>
          </b-skeleton-wrapper>
        </div> -->
    </div>
  </div>
</template>
<script>
import DoughnutChart from "../Charts/DoughnutChart.vue";
import LineChart from "../Charts/LineChart.vue";
import ApiService from "@/core/services/api.service";
import InvestmentAllocationPieChart from "../Charts/InvestmentAllocationPieChart.vue";
import PerformanceLineChart from "../Charts/PerformanceLineChart.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    DoughnutChart,
    LineChart,
    InvestmentAllocationPieChart,
    PerformanceLineChart,
    Multiselect
  },
  computed: {
    rows() {
      return this.items.length;
    },
    getDrTotal() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.dr_amount);
      });
      return sum.toFixed(2);
    },
    getCrTotal() {
      let sum = 0;
      this.items.forEach((index) => {
        sum += Number(index.cr_amount);
      });
      return sum.toFixed(2);
    },
    getTotal() {
      const total = Number(this.getDrTotal - this.getCrTotal);
      return total.toFixed(2);
    },
  },
  data() {
    return {
      total_receipts: 0,
      total_payments: 0,
      balance: 0,
      pending_withdrawal_count: 0,
      pending_withdrawal_amount: 0,
      approval_withdrawal_count: 0,
      approval_withdrawal_amount: 0,
      completed_withdrawal_count: 0,
      completed_withdrawal_amount: 0,
      failed_withdrawal_count: 0,
      failed_withdrawal_amount: 0,
      reversed_withdrawal_count: 0,
      reversed_withdrawal_amount: 0,
      rejected_withdrawal_count: 0,
      rejected_withdrawal_amount: 0,
      total_receipts_today: 0,
      total_receipts_month: 0,
      total_receipts_year: 0,
      total_payments_today: 0,
      total_payments_month: 0,
      total_payments_year: 0,
      today_receipt_balance: 0,
      month_receipt_balance: 0,
      year_receipt_balance: 0,
      date_range: false,
      form: {
        start_date: new Date().toISOString().split('T')[0],
        end_date: new Date().toISOString().split('T')[0],
        terms: "YTD",
        terms_options: ["YTD", "Periodic"],
      },
      fields: [
        {
          key: "transaction_date",
          label: "Transaction Date",
        },
        {
          key: "transaction_description",
          label: "Description",
        },
        {
          key: "dr_amount",
          label: "DR",
        },
        {
          key: "cr_amount",
          label: "CR",
        },
        {
          key: "current_amount",
          label: "Balance (GHS)",
        },
      ],
      perPage: 5,
      currentPage: 1,
      items: [],

      loading: false,
    };
  },
  created() {
    // Call your API here
    this.getGenMangDashboardApi();
  },
  methods: {
    async getGenMangDashboardApi() {
      // Your API call logic goes here
      //   console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/teller", {
        params: {
          startDate: this.form.start_date,
          endDate: this.form.end_date,
          terms: this.form.terms, //["YTD","Periodic"]
        },
      })
        .then((response) => {
          this.items = [];
          // console.log(response.data);
          const result = response.data;
          this.total_receipts = result.total_receipts;
          this.total_payments = result.total_payments;
          this.balance = result.balance;
          this.pending_withdrawal_count = result.pending_withdrawal_count;
          this.pending_withdrawal_amount = result.pending_withdrawal_amount;
          this.approval_withdrawal_count = result.approval_withdrawal_count;
          this.approval_withdrawal_amount = result.approval_withdrawal_amount;
          this.completed_withdrawal_count = result.completed_withdrawal_count;
          this.completed_withdrawal_amount = result.completed_withdrawal_amount;
          this.failed_withdrawal_count = result.failed_withdrawal_count;
          this.failed_withdrawal_amount = result.failed_withdrawal_amount;
          this.reversed_withdrawal_count = result.reversed_withdrawal_count;
          this.reversed_withdrawal_amount = result.reversed_withdrawal_amount;
          this.rejected_withdrawal_count = result.rejected_withdrawal_count;
          this.rejected_withdrawal_amount = result.rejected_withdrawal_amount;
          this.total_receipts_today = result.total_receipts_today;
          this.total_receipts_month = result.total_receipts_month;
          this.total_receipts_year = result.total_receipts_year;
          this.total_payments_today = result.total_payments_today;
          this.total_payments_month = result.total_payments_month;
          this.total_payments_year = result.total_payments_year;
          this.today_receipt_balance = result.today_receipt_balance;
          this.month_receipt_balance = result.month_receipt_balance;
          this.year_receipt_balance = result.year_receipt_balance;
          let coa_trans = result.coa_report;
          let balance_bf = 0;
          if (coa_trans && coa_trans.length && coa_trans.length > 0) {
            balance_bf = coa_trans[0].previous_amount ?? 0;
            let increase_by = coa_trans.coasubheads?.coahead?.headtype?.increase_by;
            let bf_cr_amount = ""
            let bf_dr_amount = ""

            if (increase_by == "Credit") {
              let amt = balance_bf;
              //if amount is positive, it is a credit
              if (amt >= 0) {
                bf_cr_amount = balance_bf;
              }
              else {
                // convert to positive number

                bf_dr_amount = balance_bf * -1;
              }
            }
            else {
              let amt = balance_bf;
              //if amount is positive, it is a debit
              if (amt >= 0) {
                bf_dr_amount = balance_bf;
              }
              else {
                bf_cr_amount = balance_bf * -1;
              }
            }

            // let newObj = { transaction_date: this.form.start_date, transaction_description: "BALANCE B/F", coa_subheads: "", cr_amount: bf_cr_amount, dr_amount: bf_dr_amount, current_amount: balance_bf, previous_amount: "" };
            // console.log(newObj);
            // coa_trans.unshift(newObj);
          }
          console.log(coa_trans)
          this.items = coa_trans;

        })
        .catch(function (error) {
          // this.isLoading = false;
          // return error;
          console.log(error)
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeTerms(value) {
      // this.form.terms = value;
      // console.log(value)
      if (value == "YTD" && this.current_financial_year) {
        this.form.start_date = this.current_financial_year.start_date
        this.form.end_date = this.current_financial_year.end_date
        this.disable_dates = true;
      }
      else {
        this.disable_dates = false;
      }
    },
    convertNumberToCurrency(number) {
      const formattedCurrency = number?.toLocaleString("en-US", {
        style: "currency",
        currency: "GHS", // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
      });
      return formattedCurrency;
    },
    showDateRange() {
      this.date_range = !this.date_range;
    },
    searchCOA() {
      this.getGenMangDashboardApi();
    },
  },
};
</script>
<style scoped>
.table-text-small {
  font-size: 13px;
}
</style>