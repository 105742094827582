<template>
    <div class="row">
        <div class="col-12">
            <h6 class="akkurate-green fst-italic text-center fw-bold">Please note : Investment starts running after
                receiving final approval</h6>
        </div>
        <hr />
        <div class="col-4 text-center">
            <img v-if="imageLoaded" v-bind:src="profile_pic" alt="Image" @load="handleImageLoad" style="height:200px"
                class="img-fluid text-center" />
            <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image" style="height:200px"
                class="img-fluid text-center" />
            <h6 class="mt-2 akkurate-dark fw-bold text-center">{{ field.member_name }}</h6>
        </div>
        <div class="col-8">
            <div class="row">
                <form @submit.prevent="searchMember">
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group id="code" label-for="code">
                                <label class="akkurate-dark small" for="search">Code</label>
                                <multiselect class="field-container" v-model="code" :options="code_option" label="name"
                                    selectLabel="" deselectLabel="">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="col">
                            <b-form-group id="search" label-for="search">
                                <label class="akkurate-dark small" for="search">Search</label>
                                <b-form-input class="field-container fields" type="text"
                                    placeholder="Search by name, old code, new code, phone number, account number..."
                                    v-model="search_value"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                            <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                                    class="fas fa-search me-2"></i>{{ search_btn }}</b-button>
                        </div>
                    </div>
                </form>

                <hr class="my-3" />
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="principal_amount">Principal amount <star></star></label>
                    <b-form-group id="principal_amount" label-for="principal_amount" class="akkurate-dark">
                        <b-form-input v-model="form.principal_amount" id="principal_amount"
                            class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter Principal amount"
                            required>
                        </b-form-input>
                        <errors v-if="form.errors.principal_amount">
                            {{ form.errors.principal_amount[0] }}
                        </errors>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="fixed_deposit_scheme_id">Fixed deposit scheme <star></star>
                    </label>
                    <b-form-group id="fixed_deposit_scheme_id" label-for="fixed_deposit_scheme_id"
                        class="akkurate-dark">
                        <multiselect v-model="form.fixed_deposit_scheme_id" :options="fd_scheme"
                            placeholder="Select Fixed Deposit Scheme" track-by="id" class="field-container mt-2"
                            label="name">
                        </multiselect>
                        <errors v-if="form.errors.fixed_deposit_scheme_id">
                            {{ form.errors.fixed_deposit_scheme_id[0] }}
                        </errors>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="interest_rate">Interest rate <star></star></label>
                    <b-form-group id="interest_rate" label-for="interest_rate" class="akkurate-dark">
                        <b-form-input v-model="form.interest_rate" id="interest_rate"
                            class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter interest rate"
                            required>
                        </b-form-input>
                        <errors v-if="form.errors.interest_rate">
                            {{ form.errors.interest_rate[0] }}
                        </errors>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="expected_start_date">Expected Start date <star></star></label>
                    <b-form-group id="expected_start_date" label-for="expected_start_date" class="akkurate-dark">
                        <b-form-input v-model="form.expected_start_date" id="expected_start_date" class="mb-3 mt-2 field-container fields"
                            type="date" required @input="calculateMaturityDate">
                        </b-form-input>
                        <errors v-if="form.errors.expected_start_date">
                            {{ form.errors.expected_start_date[0] }}
                        </errors>
                    </b-form-group>
                </div>


                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="maturity_date">Maturity date <star></star></label>
                    <b-form-group id="maturity_date" label-for="maturity_date" class="akkurate-dark">
                        <b-form-input v-model="maturity_date" id="maturity_date"
                            class="mb-3 mt-2 field-container fields" type="date" disabled>
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="total_interest">Total Interest<star></star></label>
                    <b-form-group id="total_interest" label-for="total_interest" class="akkurate-dark">
                        <b-form-input v-model="total_interest" id="total_interest"
                            class="mb-3 mt-2 field-container fields" type="text" disabled>
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="maturity_value">Maturity Value<star></star></label>
                    <b-form-group id="maturity_value" label-for="maturity_value" class="akkurate-dark">
                        <b-form-input v-model="maturity_value" id="maturity_value"
                            class="mb-3 mt-2 field-container fields" type="text" disabled>
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="payment_mode">Payment mode<star></star></label>
                    <b-form-group id="payment_mode" label-for="payment_mode" class="akkurate-dark">
                        <multiselect v-model="form.payment_mode" :options="payment_mode_options"
                            placeholder="Select Payment mode" @select="selectPaymentMode" class="field-container mt-2">
                        </multiselect>
                        <errors v-if="form.errors.payment_mode">
                            {{ form.errors.payment_mode[0] }}
                        </errors>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <label class="akkurate-dark small" for="fixed_deposit_mandate">Fixed deposit mandate</label>
                    <b-form-group id="fixed_deposit_mandate" label-for="fixed_deposit_mandate" class="akkurate-dark">
                        <multiselect v-model="form.fixed_deposit_mandate" :options="fixed_deposit_mandate_options"
                            placeholder="Select Payment mode" class="field-container mt-2">
                        </multiselect>
                        <errors v-if="form.errors.fixed_deposit_mandate">
                            {{ form.errors.fixed_deposit_mandate[0] }}
                        </errors>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-8 mt-2" v-show="cash_mode">
                    <div>
                        <label>Denomination</label>
                        <denomination :denominations="form.denominations"></denomination>
                    </div>
                </div>
                <div class="col-12 col-md-6" v-show="cheque_mode">
                    <label class="akkurate-dark small" for="cheque_number">Cheque number</label>
                    <b-form-group id="cheque_number" label-for="cheque_number" class="akkurate-dark">
                        <b-form-input v-model="form.cheque_number" id="cheque_number"
                            class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter Cheque number">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-6 mb-3" v-show="transfer_mode">
                    <label class="akkurate-dark small" for="product">Select Product</label>
                    <b-form-group id="product" label-for="product">
                        <multiselect class="mt-2 field-container" v-model="form.product"
                            :options="accounts_options" label="name" selectLabel="" deselectLabel="">
                        </multiselect>
                        <errors v-if="form.errors.product">
                            {{ form.errors.product[0] }}
                        </errors>
                    </b-form-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Denomination from '@/views/main/pages/transactions/form/modal/Denomination.vue';
import Multiselect from 'vue-multiselect';
export default {
    props: ['form', 'isLoading', 'fixed_deposit_scheme_options','fd_scheme'],
    components: {
        Multiselect,
        Denomination
    },
    data() {
        return {
            payment_mode_options: ['Cash', 'Cheque', 'Transfer'],
            fixed_deposit_mandate_options: ['Full Redemption', 'Redeem only Interest', 'Rollover Maturity Value'],
            is_searching: false,
            search_btn: "Search",
            code: { name: "Filter", value: "filter" },
            code_option: [
                { name: "Filter", value: "filter" },
                { name: "Account #", value: "account_number" },
            ],
            search: "",
            field: {
                member_name: "",
                member_pic: "",
                telephone: "",
            },
            imageLoaded: false,
            maturity_date: "",
            interest_value: 0,
            maturity_value: 0,
            total_interest: 0,
            search_value: "",
            cash_mode: false,
            cheque_mode: false,
            transfer_mode: false,
            accounts_options: [],
        }
    },
    methods: {
        customLabel({ first_name, last_name }) {
            return `${first_name} ${last_name}`
        },
        async searchMember() {
            this.is_searching = true;
            this.search_btn = "process...";
            let ac = "";
            let filter = "";
            console.log(this.code.value)

            switch (this.code.value) {
                case 'account_number':
                    ac = this.search_value.toString();
                    break;
                case 'filter':
                    filter = this.search_value.toString()
                    break;
                default:
                    filter = this.search_value.toString();
            }
            await ApiService.post("/accounts/paginate", {
                page: 1,
                per_page: 10,
                filter: filter,
                branch_id: "",
                account_type_id: "",
                account_number: ac,
                phone_number: "",
                start_date: "",
                end_date: "",
                status: "", //Inactive,	Active, Dormant, Closed
            }).then((response) => {
                if (response.data.data.length > 0) {
                    this.field.member_name = response.data?.data[0]?.customer?.first_name + " " + response.data?.data[0]?.customer?.last_name;
                    this.field.member_pic = response.data?.data[0]?.customer?.passport_pic_path;
                    this.field.telephone = response.data?.data[0]?.customer.mobile;
                    this.form.customer = response.data?.data[0]?.customer;
                    this.handleImageLoad();
                    this.accounts_options = response.data.data.map((index) => {
                        return {
                            id:index.id,
                            name: index.account_number + " - " + index?.account_type?.name + " - " + index?.customer?.first_name + " " + index?.customer?.last_name,
                            product_type: index?.account_type?.name,
                            account_number: index.account_number,
                            customer_name: index?.customer?.first_name + " " + index?.customer?.last_name,
                            customer_mobile: index?.customer?.mobile,
                            account_balance: index?.account_balance
                        };
                    });
                } else {
                    swal.fire({
                        icon: "error",
                        title: "Not Found",
                        text: "Member Account/Product Not Found!!!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: "OK", // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton:
                                "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                        },
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }
                this.is_searching = false;
                this.search_btn = "Search";
            })
                .catch((error) => {
                    this.is_searching = false;
                    this.search_btn = "Search";
                });
        },
        handleImageLoad() {
            this.imageLoaded = true;
            this.profile_pic = this.field.member_pic;
        },
        calculateMaturityDate(event) {
            // Assuming the date is provided as a string in the format "YYYY-MM-DD"
            const startDate = new Date(event);  // Use the given date here
            const tenor = this.form.fixed_deposit_scheme_id.tenor;  // 265 in your case

            // Add the tenor (in days) to the startDate
            startDate.setDate(startDate.getDate() + tenor);

            // Now startDate contains the maturity date after adding the tenor (265 days)

            this.maturity_date = startDate.toISOString().split('T')[0];

            const principal = this.form.principal_amount
            const interest_rate = this.form.interest_rate
            const pecent = 100;
            const year = 365;

            this.total_interest = (Number(principal) * Number(interest_rate) / pecent / year * Number(tenor)).toFixed(2)

            this.maturity_value = Number(this.total_interest) + Number(principal)

        },

        selectPaymentMode(mode) {
            switch (mode) {
                case 'Cash':
                    this.cash_mode = true;
                    this.cheque_mode = false;
                    this.transfer_mode = false
                    break;
                case 'Cheque':
                    this.cash_mode = false;
                    this.cheque_mode = true;
                    this.transfer_mode = false
                    break;
                case 'Transfer':
                    this.cash_mode = false;
                    this.cheque_mode = false;
                    this.transfer_mode = true;
                    break;
                default:
                    this.cash_mode = false;
                    this.cheque_mode = false;
                    this.transfer_mode = false;
            }
        }

    }
}
</script>