<template>
  <div id="wrapper">
    <SidebarView :members="true" :all-member="true" :member-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="mx-3">
          <!-- begin row  -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-shape home-box">
                <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 class="m-0 fw-bold text-green">All Members</h6>
                    </div>
                    <div>
                      <b-button class="btn btn-sm akkurate-green-btn" href="/members/create" variant="primary">
                        <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Member</span>
                      </b-button>
                      <b-link @click="exportToExcel" class="fw-semibold ms-2 akkurate-green"> <font-awesome-icon
                          :icon="['fas', 'file-excel']" /></b-link>
                    </div>
                  </div>
                </div>
                <div class="card-body py-3 default_bg" style="border-bottom: 1px solid rgb(1, 134, 115);">
                  <div class="home-box border border-success p-3 bg-white">
                    <member-filter :form="form" :filterTable="filterTable"></member-filter>
                  </div>
                </div>
                <div class="card-body pt-3">
                  <div class="table_text_size">
                    <b-table striped bordered responsive id="member-table" :busy.sync="isBusy"
                      :items="rows" :fields="fields" :tbody-tr-class="rowClass" show-empty>
                      <template #empty="scope">
                        <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <!-- A custom formatted column -->
                      <template #cell(customer)="data">
                        <div>{{ data.item.first_name }} {{ data.item.last_name }}</div>
                      </template>
                      <template #cell(status)="data">
                        <span :class="statusBadge(data.item.status)">{{ data.item.status }}</span>
                      </template>
                      <template #cell(actions)="data">
                        <span class="d-flex">
                          <a title="View" class="btn btn-sm akkurate-warning text-white broder-0 custom-pointer"
                            @click="showCustomerViewModal(data.item.id)">
                            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span>
                          </a>
                          <a title="Edit" class="btn btn-sm akkurate-blue text-white border-0"
                            :href="`/members/${data.item.id}/edit`">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span>
                          </a>
                          <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white border-0 custom-pointer"
                            @click="deleteMember(data.item)" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                          </a>
                          <div class="dropdown no-arrow d-inline custom-pointer">
                            <a title="Status" class="btn btn-sm akkurate-green-btn text-white dropdown-toggle "
                              id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'user']" /></span>
                            </a>
                            <!-- Added dropdown-menu-end to ensure the menu appears outside -->
                            <div class="dropdown-menu dropdown-menu-end shadow animated--fade-in"
                              aria-labelledby="dropdownMenu">
                              <div class="dropdown-header py-2">Status</div>
                              <b-button v-if="data.item.status != 'Active'" class="dropdown-item"
                                @click="setUserStatus('Active', data.item)"><span class="small">Active</span></b-button>
                              <b-button v-if="data.item.status != 'Inactive'" class="dropdown-item"
                                @click="setUserStatus('Inactive', data.item)"><span
                                  class="small">Inactive</span></b-button>
                              <b-button v-if="data.item.status != 'Dormant'" class="dropdown-item"
                                @click="setUserStatus('Dormant', data.item)"><span
                                  class="small">Dormant</span></b-button>
                            </div>
                          </div>
                          <a title="Reset PIN" class="btn btn-sm akkurate-gray-btn text-white border-0 custom-pointer"
                            @click="resetPIN(data.item)">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'key']" /></span>
                          </a>
                        </span>
                      </template>
                    </b-table>


                    <div class="d-flex justify-content-between">
                      <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage" :total-rows="totalRecords"
                        :per-page="perPage" aria-controls="member-table"></b-pagination>
                      <!-- <b-form-select @change="selectPerPage" v-model="per_page_selected" :options="per_page_options"
                        size="sm" class="mt-3"></b-form-select> -->
                      <div class="col text-end mt-1 akkurate-dark">
                        <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                        <b-form-group label="Per Page" label-size="sm" class="mb-0">
                          <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                            :options="per_page_options" size="sm"></b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row  -->

          <b-modal size="xl" id="bv-modal-customer-view" hide-footer>
            <template #modal-title>
              <h6 class="m-0 fw-bold text-green text-sm">Member View</h6>
            </template>
            <member-view :id="member_id"></member-view>
          </b-modal>

        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';


import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import MemberFilter from "../forms/MemberFilter.vue";
import MemberView from '@/views/main/pages/member/view/MemberView.vue'
import 'vue-good-table/dist/vue-good-table.css';
// import $ from 'jquery';
// import 'datatables.net';
// import config from '@/config.js'
// import JwtService from "@/core/services/jwt.service";

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    MemberFilter,
    MemberView,
  },


  data() {
    return {
      member_id: "",
      totalRecords: 0,
      searchFilter: "",
      isBusy: true,
      branch: null,
      branch_option: [],
      currentPage: 1,
      perPage: 10,
      per_page_options: ['10', '20', '50', '100'],
      per_page_selected: "10",
      form: {
        status: "",
        search_term: "",
        from_date: "",
        to_date: "",
        trans_source: "",
        branch: "",
      },

      fields: [
        {
          label: 'Cust.Code',
          key: 'code',
        },
        {
          label: 'Old_Code',
          key: 'external_code',
        },
        {
          label: 'Fullname',
          key: 'customer',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Mobile',
          key: 'mobile',
        },
        {
          label: 'Source',
          key: 'source',
        },
        {
          label: 'Digital_Address',
          key: 'digital_address',
        },
        {
          label: 'Ghana_Card',
          key: 'ghana_card_number',
        },
        {
          label: 'Reg.Date',
          key: 'registered_date',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: [
          {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          }
        ],
        page: 1, // what page I want to show
        perPage: 20 // how many items I'm showing per page
      },
    };
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    this.getAccountDropdown();
    this.loadItems();

  },
  // async created() {
  //   this.loadItems()
  // },
  // computed:{

  // },
  methods: {
    statusBadge(item) {
      if (item === 'Active') {
        return 'badge akkurate-green-bg'
      } else if (item === 'Inactive') {
        return 'badge akkurate-danger-bg'
      } else if (item === 'Dormant') {
        return 'badge akkurate-danger-bg'
      } else if (item === 'Pending') {
        return 'badge akkurate-warning-bg'
      } else {
        return 'badge akkurate-warning-bg'
      }
    },
    rowClass() {
      return 'custom-row-height'
    },
    filterTable() {
      this.loadItems();
    },

    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.loadItems()
    },
    selectPerPage(index) {
      this.perPage = index
      this.loadItems()
    },
    showCustomerViewModal(id) {
      this.member_id = id;
      this.$bvModal.show('bv-modal-customer-view')
    },
    async getAccountDropdown() {
      this.$Progress.start();
      this.isBusy = true;
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // console.log(response);
          this.form.branch_option = response?.data?.branches
          this.form.acc_types_option = response?.data?.account_types
          this.user = response?.data?.user
          this.isBusy = false;
          this.$Progress.finish();
        }).catch((error) => {
          // console.log(error);
        })
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isBusy = true;
      this.$Progress.start();  // Start the progress bar
      await ApiService.post("customers/paginate",
        {
          "page": this.currentPage,
          "per_page": this.perPage,
          "filter": this.form.search_term,
          "branch_id": this.form.branch?.id,
          "status": this.form.status, //Inactive,	Active, Inactive, Dormant, Closed
          "region_id": null
        })
        .then(response => {
          // Handle response and update table data
          this.isBusy = false;  // Set isBusy to false when the data is loaded
          this.$Progress.finish();  // Finish the progress bar
          this.rows = response.data.data;
          this.totalRecords = response.data.meta.total;

        }).catch(() => {
          this.isBusy = false;  // Set isBusy to false in case of an error
          this.$Progress.finish();  // Finish the progress bar even if there's an error

          return [];  // Return an empty array in case of error to prevent the table from breaking
        })
        .finally(() => {
          this.isBusy = false;  // Set isBusy to false in case of an error
          this.$Progress.finish();  // Finish the progress bar even if there's an error

        });
    },

    confirmDeleteUser(user) {

      swal.fire({
        title: "Disable User",
        text: `Are you sure you want to disable ${user.username}?`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
        }
      });

    },
    deleteMember(data) {
      swal.fire({
        title: "Please confirm",
        html: `Are you sure you want to <strong>DELETE</strong> member <strong>${data.first_name} ${data.last_name}</strong>?<br/>This action cannot be reversed`,
        icon: "warning",
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: 'Delete', // Customize the text for the "OK" button
        cancelButtonText: 'No', // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-red-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
        }
      }).then(async result => {
        if (result.isConfirmed) {
          this.$Progress.start();
          await ApiService.delete(`/customers/delete/${data.id}`).then((response) => {
            console.log(response);
            this.$Progress.finish();
            swal.fire({
              // title: response.statusText,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });
            this.loadItems();
          }).catch((error) => {
            this.$Progress.fail();
            // console.log(error);
            swal.fire({
              // title: response.statusText,
              text: error.response.data.message,
              icon: "error",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });

          })
          //
        }
      });

    },

    resetPIN(data) {
      swal.fire({
        title: "Reset Member PIN",
        html: `Are you sure you want to reset the PIN for member <strong>${data.first_name} ${data.last_name}</strong>?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset",
        // allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
          //send put request to reset password
          ApiService.put(`customers/reset-pin/${data.id}`)
            .then(response => {
              swal.fire({
                title: "Success",
                html: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
                allowOutsideClick: false
              });
            })
            .catch(error => {
              swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
                // allowOutsideClick: false
              });
            });
        }
      });

    },
    setUserStatus(status, data) {
      swal.fire({
        title: "Are you sure?",
        html: `You want to set member status to <strong>${status}</strong> for <strong>${data.first_name}`
          + ` ${data.last_name}</strong>?`,
        icon: "warning",
        allowOutsideClick: true,
        allowEscapeKey: true,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: 'Yes', // Customize the text for the "OK" button
        cancelButtonText: 'No', // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
        }
      }).then(async result => {
        if (result.isConfirmed) {
          this.$Progress.start();
          await ApiService.put(`/customers/status-update/${data.id}`, {
            status: status
          }).then((response) => {
            console.log(response);
            this.$Progress.finish();
            swal.fire({
              title: response.statusText,
              text: response.data.message,
              icon: "success",
              allowOutsideClick: true,
              allowEscapeKey: true,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: 'okay', // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
              }
            });
            this.loadItems();
          }).catch((error) => {
            this.$Progress.fail();
            console.log(error);
          })
          //
        }
      });
    },

    viewData(data) {
      this.$router.push('/members/view/' + data.id)
      // console.log(id.id);
    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      // const tableData = this.$refs.memberTable.filteredRows[0].children;
      // console.log(this.$refs.memberTable.filteredRows[0].children)
      const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
        return {
          member_code: index.code,
          name: `${index.first_name} ${index.middlename} ${index.last_name}`,
          mobile: index.mobile,
          address: index.address,
          date_of_birth: index.date_of_birth,
          registered_date: index.registered_date,
          gender: index.gender,
          branch: index.branch.name ?? "",
        }
      });
      // console.log(tableData);

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    },
  }
  // methods: {
  //   getUserOptions() {
  //     this.$Progress.start();
  //     this.isLoading = true;
  //     // axios
  //     //   .get("roles/dropdown")

  //     $(this.$refs.dataTable).DataTable({
  //       serverSide: true,
  //       ajax: {
  //         url: config.api_url + '/users/paginate', // Replace with your server-side endpoint URL
  //         type: 'POST',
  //         beforeSend: function (xhr) {
  //           // Set your authorization header here
  //           xhr.setRequestHeader('Authorization', 'Bearer ' + JwtService.getToken());
  //         },
  //         data: function (data) {

  //           //   this.isLoading = false;
  //           // Customize the request parameters as needed
  //           //   data.page = data.start / data.length + 1;
  //           //   data.pageSize = data.length;
  //           // Add any additional parameters you need, such as sorting information
  //         },
  //         dataSrc: function (json) {
  //           // Handle the server's response here
  //           // Update the DataTables table with the received data
  //           return json.data;
  //         },
  //       },
  //       columns: [
  //         // Define your table columns here
  //         // { data: 'id', title: 'ID' },
  //         { data: 'first_name', title: 'First Name' },
  //         { data: 'surname', title: 'Sur Name' },
  //         { data: 'email', title: 'Email' },
  //         { data: 'username', title: 'Username' },
  //         // Add more columns as needed
  //       ],
  //     });


  //   },
  // }
};
</script>

<!-- <style scoped>
.table-responsive-sm {
  margin-bottom: 0px !important
}

.pagination {
  margin-top: 0px !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style> -->
