<template>
    <div id="wrapper">
        <SidebarView :memacc="true" :memaccVisible="true" :addMemAcc="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <!-- begin loader -->
                            <!-- <beat-loader-component
                        v-if="isLoading"
                        :isLoading="isLoading"
                        ></beat-loader-component> -->
                            <!-- end loader -->
                        </div>
                        <div class="col-12">
                            <div class="card card-shape home-box">
                                <div class="card-header py-3 d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Update Product</h6>
                                </div>
                                <div class="card-body">
                                    <b-form @submit.stop.prevent="updateData">
                                        <account-form :member_options="member_options"
                                            :account_options="account_options" :form='form' :add_or_edit="add_or_edit"
                                            :commissionSeleted="commissionSeleted" :apply_show="apply_show"
                                            :interestSeleted="interestSeleted"
                                            :apply_interest_show="apply_interest_show"></account-form>
                                        <hr />
                                        <div class="text-center py-3">
                                            <update-button :isSaving="isSaving" :processing="processing" />
                                            <button type="reset"
                                                class="btn akkurate-gray-btn text-white btn-size my-2 ms-3">
                                                <font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />
                                                Reset</button>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
    </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
// import AddressView from "../forms/Address";
// import PersonalDetails from "../forms/PersonalDetails";
// import EmployerDetails from "../forms/EmployerDetails";
// import FinancialInformation from "../forms/FinancialInformation";
// import SpouseDetails from "../forms/SpouseDetails";
// import EmergencyContactDetails from "../forms/EmergencyContactDetails";
// // import AddAccount from "../forms/Modal/AddAccount";
// // import NextOfKin from "../forms/Modal/NextOfKin.vue";
// import ApiService from "@/core/services/api.service";
import AccountForm from '../forms/AccountForm.vue';
import ApiService from "@/core/services/api.service";
import UpdateButton from "@/views/main/components/UpdateButton.vue";

export default {
    components: {
        AccountForm,
        UpdateButton,
        SidebarView,
        Footer,
        TopbarView,
    },
    data() {
        return {
            ...this.initialState(),
            account_options: [],
            member_options: [],
            processing: "Update",
            account_number: "",
            errors: [],
            apply_show: false,
            apply_interest_show: false,
        }
    },
    mounted() {
        // console.log(this.$route.params.account_number);
        // console.log(this.accountDetails());
        // console.log(this.loadPageOptions());
        // console.log(this.mountMemberData())
        this.loadPageOptions();
        this.accountDetails();
    },
    methods: {
        loadPageOptions() {
            this.$Progress.start();
            ApiService.get("/customers/dropdown")
                .then((response) => {
                    this.account_types = response.data.account_types;
                    this.regions = response.data.regions;
                    this.branches = response.data.branches;
                    this.isLoading = false;
                    // $emit('coa-head-saved', response.data)
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$Progress.fail();
                });
        },
        accountDetails() {
            console.log(this.$route.params.id)
            ApiService.get(`/accounts/view/${this.$route.params.id}`)
                .then((response) => {
                    // console.log(response.data.account);
                    const details = response.data.account
                    // this.form = response.data.account;
                    this.form.member = details.customer
                    this.form.account_type = details.account_type
                    this.form.balance = details.balance
                    this.form.status = details.status
                    this.form.branch = details.branch
                    this.form.notes = details.notes
                    this.form.withdrawal_limit = details.withdrawal_limit
                    this.form.maximum_withdrawal_frequency = details.maximum_withdrawal_frequency
                    this.form.auto_transfer = details.auto_transfer
                    this.form.interest_frequency = details.interest_frequency
                    this.form.commission = details.commission
                    this.form.commission_frequency = details.commission_frequency
                    this.form.commission = details.commission
                    this.form.interest_rate = details.interest_rate
                    this.account_number = details.account_number
                    this.form.apply_commission = details.apply_commission
                    this.account_interest_start_date = details.account_interest_start_date
                    this.account_commission_start_date = details.account_commission_start_date


                    // After setting apply_commission, call Selected
                    this.commissionSeleted(details.apply_commission);
                }).catch((errors) => {
                    console.log(errors)
                })
        },
        initialState() {
            return {
                form: {
                    branch: {
                        id: "",
                        name: ""
                    },
                    account_type: "",
                    account_option: [],
                    balance: "",
                    status: "Active",
                    account_balance: 0,
                    notes: "",
                    member: "",
                    member_option: [],
                    status_option: ["Active", "Inactive", "Dormant", "Closed"],
                    errors: [],
                    auto_transfer: "0",
                    maximum_withdrawal_frequency: "",
                    commission: "0",
                    commssion_amount: "",
                    commission_frequency: "",
                    apply_commission: "0",
                    account_interest_start_date: "",
                    account_commission_start_date: "",
                },
                isLoading: true,
                isSaving: false,
                add_or_edit: 'add',
            };
        },
        updateData() {
            this.isSaving = true;
            this.$Progress.start();
            ApiService.post(`/accounts/update/${this.account_number}`, this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                });


        },
        commissionSeleted(event) {
            if (event == "1") {
                this.apply_show = true
            } else {
                this.apply_show = false
            }
        },
        interestSeleted(event) {
            if (event == "1") {
                this.apply_interest_show = true
            } else {
                this.apply_interest_show = false
            }
        }
    },

    // name: "MemberEdit",
    // mounted() {
    //     this.loadPageOptions();
    //     this.mountMemberData();
    // },
    // components: {
    //     SidebarView,
    //     Footer,
    //     TopbarView,
    //     AddressView,
    //     PersonalDetails,
    //     EmployerDetails,
    //     FinancialInformation,
    //     SpouseDetails,
    //     EmergencyContactDetails
    // },
    // data() {
    //     return {
    //         form: {
    //             accounts: [],
    //             nextofkins: [],
    //             first_name: "",
    //             middlename: null,
    //             last_name: "",
    //             title: "",
    //             email: "",
    //             mobile: "",
    //             gender: "",
    //             date_of_birth: "",
    //             external_code: "",
    //             marital_status: "",
    //             address: "",
    //             digital_address: "", //not required
    //             home_town: "", //not required
    //             landmark: "",
    //             region: {},
    //             branch: {},
    //             employer_name: null, //not required
    //             ghana_card_number: null, //not required
    //             status: "",
    //             errors: [],
    //             gh_card_back_display: null,
    //             gh_card_front_display: null,
    //             passport_pic_display: null,
    //             signature_display: null,
    //             employer_address: null,
    //             employer_mobile: null,
    //             occupation: null,
    //             employee_position: null,
    //             source_of_funds: null,
    //             bankers_name: null,
    //             bankers_address: null,
    //             bankers_location: null,
    //             monthly_salary: null,
    //             signature_image_path: null,
    //             signature_image_name: null,
    //             passport_pic_path: null,
    //             passport_pic_name: null,
    //             note: null,
    //             mandate: null,
    //             customer_type: null,
    //             bank_account_number: null,
    //             nearest_landmark: null,
    //             spouse_name: null,
    //             spouse_mobile: null,
    //             spouse_email: null,
    //             spouse_address: null,
    //             mobile_secondary: null,
    //             employment_status: null,
    //             reference_name: null,
    //             reference_mobile: null,
    //             reference_email: null,
    //             reference_address: null,
    //         },
    //         regions: [],
    //         branches: [],
    //         account_types: [],
    //         add_or_edit: 'edit',
    //         front_card_path: "",
    //         front_card_name: "",
    //         back_card_path: "",
    //         back_card_name: "",
    //     };
    // },

    // methods: {
    //     async loadPageOptions() {
    //         this.$Progress.start();
    //         await ApiService.get("/customers/dropdown")
    //             .then((response) => {
    //                 this.account_types = response.data.account_types;
    //                 this.regions = response.data.regions;
    //                 this.branches = response.data.branches;
    //                 this.isLoading = false;
    //                 // $emit('coa-head-saved', response.data)
    //                 this.$Progress.finish();
    //             })
    //             .catch((error) => {
    //                 this.isLoading = false;
    //                 this.$Progress.fail();
    //             });
    //     },
    //     async mountMemberData() {
    //         await ApiService.get(`/customers/view/${this.$route.params.id}`)
    //             .then((response) => {
    //                 // console.log(response.data.data);
    //                 let data = response.data.data;
    //                 this.form.title = data.title;
    //                 this.form.first_name = data.first_name;
    //                 this.form.middlename = data.middlename;
    //                 this.form.last_name = data.last_name;
    //                 this.form.gender = data.gender;
    //                 this.form.date_of_birth = data.date_of_birth;
    //                 this.form.marital_status = data.marital_status;
    //                 this.form.email = data.email;
    //                 this.form.mobile = data.mobile;
    //                 this.form.ghana_card_number = data.ghana_card_number;
    //                 this.form.status = data.status;
    //                 this.form.staff_id = data.staff_id;
    //                 this.form.branch = data.branch;
    //                 this.form.region = data.region
    //                 this.form.address = data.address;
    //                 this.form.digital_address = data.digital_address;
    //                 this.form.home_town = data.home_town;
    //                 this.form.external_code = data.external_code;
    //                 this.form.customer_type = data.customer_type;
    //                 this.form.bank_account_number = data.bank_account_number;
    //                 this.form.mandate = data.mandate;
    //                 this.form.note = data.note;
    //                 this.form.employer_name = data.employer_name;
    //                 this.form.employer_address = data.employer_address;
    //                 this.form.employer_mobile = data.employer_mobile;
    //                 this.form.occupation = data.occupation;
    //                 this.form.employee_position = data.employee_position;
    //                 this.form.source_of_funds = data.source_of_funds;
    //                 this.form.bankers_name = data.bankers_name;
    //                 this.form.bankers_address = data.bankers_address;
    //                 this.form.bankers_location = data.bankers_location;
    //                 this.form.monthly_salary = data.monthly_salary;
    //                 this.form.spouse_name = data.spouse_name;
    //                 this.form.spouse_mobile = data.spouse_mobile;
    //                 this.form.spouse_email = data.spouse_email;
    //                 this.form.spouse_address = data.spouse_address;
    //                 this.form.mobile_secondary = data.mobile_secondary;
    //                 this.form.employment_status = data.employment_status;
    //                 this.form.reference_name = data.reference_name;
    //                 this.form.reference_mobile = data.reference_mobile;
    //                 this.form.reference_email = data.reference_email;
    //                 this.form.reference_address = data.reference_address;
    //                 this.form.nearest_landmark = data.nearest_landmark;


    //                 this.form.unapproved_withdrawal_max_amount = data.unapproved_withdrawal_max_amount;
    //                 if (data.gh_card_back_path) {
    //                     this.form.gh_card_back_display = data.gh_card_back_path;
    //                 }

    //                 if (data.gh_card_front_path) {
    //                     this.form.gh_card_front_display = data.gh_card_front_path;
    //                 }

    //                 if (data.passport_pic_path) {
    //                     this.form.passport_pic_display = data.passport_pic_path;
    //                 }

    //                 if (data.signature_image_path) {
    //                     this.form.signature_display = data.signature_image_path;
    //                 }
    //             }).catch((error) => {

    //             })

    //     },

    //     handleResponseFront(message) {
    //         // console.log(message);
    //         this.front_card_path = message.front_ghana_path;
    //         this.front_card_name = message.front_ghana_name;
    //     },
    //     handleResponseBack(message) {
    //         // console.log(message);
    //         this.back_card_path = message.back_ghana_path;
    //         this.back_card_name = message.back_ghana_name;
    //     },
    //     responseSignaturePic(obj) {
    //         // console.log(obj);
    //         this.form.signature_image_path = obj.signature_image_path;
    //         this.form.signature_image_name = obj.signature_image_name;
    //     },
    //     responsePassportPic(obj) {
    //         // console.log(obj);
    //         this.form.passport_pic_path = obj.passport_pic_path;
    //         this.form.passport_pic_name = obj.passport_pic_name;
    //     },
    // },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>