<template>
    <div>
        <div class="row">
            <div class="col">
                <label class="akkurate-dark small" for="search">Search</label>
                <b-form-group id="search" label-for="search" class="akkurate-dark">
                    <b-form-input id="search" class="mt-2 field-container fields" type="text" v-model="form.search"
                        placeholder="Search by ...">
                    </b-form-input>
                </b-form-group>
            </div>


            <div class="col">
                <label class="akkurate-dark small" for="search">Transaction Type</label>
                <b-form-group id="transaction_type" label-for="transaction_type" class="akkurate-dark">
                    <multiselect class="mt-2 field-container" v-model="form.transaction_type"
                        :options="transaction_types" selectLabel="" deselectLabel="">
                    </multiselect>
                </b-form-group>
            </div>

            <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                        class="fas fa-search me-2"></i>Search</b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col" v-show="more_details">
                <label class="akkurate-dark small" for="date">From Date</label>
                <b-form-group label-for="start_date" class="akkurate-dark">
                    <b-form-input id="start_date" class="mt-2 field-container fields" type="date"
                        v-model="form.start_date">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="col" v-show="more_details">
                <label class="akkurate-dark small" for="date">To Date</label>
                <b-form-group label-for="end_date" class="akkurate-dark">
                    <b-form-input id="end_date" class="mt-2 field-container fields" type="date" v-model="form.end_date">
                    </b-form-input>
                </b-form-group>
            </div>


            <div class="col" v-show="more_details">
                <label class="akkurate-dark small" for="status">Status</label>
                <b-form-group id="status" label-for="status" class="akkurate-dark">
                    <multiselect class="mt-2 field-container" v-model="form.status" :options="statuses"
                        placeholder="Select status" selectLabel="" deselectLabel="">
                    </multiselect>
                </b-form-group>
            </div>

            <div class="col" v-show="more_details">
                <label class="akkurate-dark small" for="trans_source">Source</label>
                <multiselect class="field-container mt-2" v-model="form.trans_source" :options="trans_source_options"
                    placeholder="Transaction Source" label="name" selectLabel="" deselectLabel="">
                </multiselect>
            </div>

            <div class="col" v-show="more_details">
                <label class="akkurate-dark small" for="transaction_type">User(s)</label>
                <multiselect class="mt-2 field-container" v-model="form.users" :options="users"
                    placeholder="Select User" selectLabel="" :custom-label="fullName" :multiple="true" deselectLabel=""
                    :limit-text="limitText" :limit="1" track-by="id">
                </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: ['form', 'users'],
    data() {
        return {
            more_details: false,
            // transaction_options: ['Deposit', 'Withdrawal'],
            // sources: ['Mobile', 'WEB', 'USSD']
            statuses: ['COMPLETED', 'PENDING', 'REVERSED', 'REJECTED'],
            transaction_types: ['Deposit', 'Withdrawal',],
            // sources: [{ name: 'USSD', id: "USSD" }, { name: 'WEB', id: "WEB" }, { name: 'MOBILE BANKER', id: "Mobile_Banker" }],
            code_option: [
                { name: "Customer code", value: "filter" },
                { name: "Account #", value: "account_number" },
                { name: "Ref.No", value: "code" },
                { name: "Mobile", value: "phone_number" },
            ],
            trans_source_options: [
                { name: 'USSD', value: "ussd" },
                { name: 'WEB', value: "web" },
                { name: 'MOBILE BANKER', value: "mobile_banker" },
                // { name: 'Deposit', value: "deposit" },
                // { name: 'Withdrawal', value: "withdrawal" },
            ],
            trans_sources: ["Deposit", "Withdrawal"]

        }
    },
    methods: {
        submitFilters() {
            this.$emit("submitFilters");
        },

        limitText(count) {
            return `and ${count} other user(s)`
        },
        fullName({ first_name, surname, code }) {
            let name = ""
            if (first_name) {
                name = name + first_name;
            }

            if (surname) {
                name = name + " " + surname;
            }

            //   if(code)
            //   {
            //     name = name + ` (${code})`;
            //   }

            return `${name}`
        },
        show_more() {
            this.more_details = !this.more_details
        }
    }


}
</script>