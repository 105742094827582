<template>
  <div>
    <b-button class="akkurate-green-btn akkurate-small" id="show-btn" @click="showModal"><i class="fas fa-plus"></i> Add COA Head</b-button>
    <b-modal 
       id="add-chart-of-acc"
       ref="add_coa"
       size="lg"
       @show="resetModal"
       @hidden="resetModal"
       @ok="handleOk"
      >
      <template #modal-title>
         <div class="akkurate-green small fw-bold">Add COA Head</div>
      </template>
      <form ref="form" @submit.stop.prevent="handleSubmit">
         <div class="row">
            <!-- Account type -->
            <div class="col-md-6">
               <b-form-group
               label-for="coa-head"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="acc_type">Account type: <star></star></label>
               </template>
                    <multiselect
                        :disabled="add_or_edit == 'edit'"
                        v-validate="{ required: true }"
                        v-model="form.account_type"
                        name="account_type"
                        :state="validateState('acc_type')"
                        placeholder="Search or select"
                        :options="account_types"
                        class="mt-2 field-container"
                        label="name"
                        required
                    ></multiselect>
                    <errors v-if="form.errors.account_type">
                      {{ form.errors.account_type[0] }}
                    </errors>
               </b-form-group>
            </div>
            <!-- Branch -->
            <div class="col-md-6">
               <b-form-group
               label-for="branch"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="name">Branch <star></star></label>
               </template>
               <multiselect
                  :disabled="add_or_edit == 'edit'"
                  v-validate="{ required: true }"
                  v-model="form.selected_branch"
                  :state="validateState('branch')"
                  name="selected_branch"
                  placeholder="Search or select"
                  :options="branches"
                  class="mt-2 field-container"
                  label="name"
                  required
                ></multiselect>

                <errors v-if="form.errors.selected_branch">
                  {{ form.errors.selected_branch[0] }}
                </errors>
                
               </b-form-group>
            </div>
            <!-- Account Number -->
            <div class="col-md-6">
               <b-form-group
               label-for="branch"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="acc_num">Account Number <star></star>
                  <span v-if="form.account_type">({{form.account_type.number_from}} - {{form.account_type.number_to}}) </span></label>
               </template>
               <b-form-input
                    id="acc_num"
                    name="acc_num"
                    type="number"
                    v-validate="{ required: true }"
                    :state="validateState('acc_num')"
                    class="mt-2 field-container fields"
                    placeholder="Enter account number"
                    v-model="form.account_number"
                    required
                    data-vv-as="Account name"
                ></b-form-input>
                <errors v-if="form.errors.account_number">
                      {{ form.errors.account_number[0] }}
                    </errors>
               </b-form-group>
            </div>
            <!-- Account Name -->
            <div class="col-md-6">
               <b-form-group
               label-for="acc_name"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="acc_name">Account Name <star></star></label>
               </template>
                <b-form-input
                    id="acc_name"
                    name="acc_name"
                    v-validate="{ required: true }"
                    :state="validateState('acc_name')"
                    class="mt-2 field-container fields"
                    :class="errorState"
                    placeholder="Enter account name"
                    v-model="form.account_name"
                    required
                    data-vv-as="Account name"
                ></b-form-input>
                    <b-form-invalid-feedback >
                    {{ veeErrors.first('acc_name') }}
                  </b-form-invalid-feedback>
                   <errors v-if="form.errors.account_name">
                        {{ form.errors.account_name[0] }}
                   </errors>
               </b-form-group>
            </div>
          
            <!-- Description -->
            <div class="col-md-12">
               <b-form-group
               label-for="descripton"
               class="mb-3">
               <template #label>
                  <label class="akkurate-dark" for="address">Description <star></star></label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  name="desc"
                  v-validate="{ required: true }"
                  :state="validateState('desc')"
                  v-model="form.description" 
                  id="textarea-lazy"
                  placeholder="Enter Description..."
                  data-vv-as="Description"
                  required
                  lazy-formatter
              ></b-form-textarea>
              <b-form-invalid-feedback >
                    {{ veeErrors.first('desc') }}
              </b-form-invalid-feedback>
              <errors v-if="form.errors.description">
                      {{ form.errors.description[0] }}
                    </errors>
               </b-form-group>
            </div>
          
          </div>
       </form>
       <template #modal-footer="{ ok }">
            <div class="w-100">
               <!-- begin loader -->
               <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component>
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide('add-chart-of-acc')" type="button" class="border-0 akkurate-grey-btn btn-size me-2 float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
               <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save</b-button>
             </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
  components: { Multiselect },

  mounted() {
    this.loadPageOptions();
  },
  data() {
    return {
      errorState: "",
      isLoading: false,
      form: {
        account_number: "",
        account_name: "",
        description: "",
        account_type: "",
        selected_branch: "",
        errors: [],
      },
      account_types: [],
      branches: [],
      isSaving: false,

      add_or_edit: "add",
    };
  },
  methods: {
    showModal() {
      this.$refs['add_coa'].show()
    },
    hideModal() {
      this.$refs['add_coa'].hide()
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetModal() {
        this.form.coa_head = this.coa_head;
        this.form.account_number = ""
        this.form.account_name = ""
        this.form.description = ""
        this.form.account_type = ""
        this.form.account_category = ""
        this.errorState = ''
    },
    
    loadPageOptions() {
      this.$Progress.start();
      this.isLoading = true;
      ApiService.get("/finance/chart-of-accounts/account-types/dropdown")
        .then((response) => {
          this.account_types = response.data.account_types;
          this.branches = response.data.branches;
          this.isLoading = false;
          $emit('coa-head-saved', response.data)
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
       this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        this.isSaving = true;
      //   this.$Progress.start();
      this.form.errors = [];
      ApiService.post("/finance/chart-of-accounts/postCOAHead", this.form)
        .then((response) => {
          this.isSaving = false;
          //   this.$Progress.finish();

          $("#coaHeadModal").modal("hide");
          toast.fire({
            icon: "success",
            title: response.data.message,
          });

          this.$emit("coa-head-saved", response.data.data);
          this.$nextTick(() => {
              this.$bvModal.hide('add-chart-of-acc')
            })

          // window.location.reload()
          //   this.isSaving = false;
          //   this.form.phone_number = "";
          //   this.form.email = "";
          //   this.form.selected_payment_method = "";
        })
        .catch((error) => {
          this.form.errors = [];
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
          this.isSaving = false;
        });

       });
      
    },
  },
};
</script>