<template>
    <div>
        <a title="View" class="btn btn-sm akkurate-warning text-white broder-0 custom-pointer" v-b-modal="String(data.id)">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
        </a>

        <b-modal :id="String(data.id)" size="xl">
            <template #modal-title>
                <h6 class="akkurate-green fw-bold mb-0">View User Profile</h6>
            </template>
            <div class="row">
            <div class="col-12 col-md-4 mt-4">
            <label class="akkurate-dark" for="first_name">First Name <star></star></label>
                <b-form-group
                id="first_name"
                label-for="first_name">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="first_name"
                type="text"
                placeholder="Enter First Name"
                required
                disabled
                v-model="data.first_name"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="middle_name">Middle Name <star></star></label>
                <b-form-group
                id="middle_name"
                label-for="middle_name">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="middle_name"
                type="text"
                placeholder="Enter Middle Name"
                v-model="data.middle_name"
                required
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="surname">Surname <star></star></label>
                <b-form-group
                id="surname"
                label-for="surname">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="surname"
                type="text"
                placeholder="Enter Surname"
                v-model="data.surname"
                required
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="phone">Phone Number <star></star></label>
                <b-form-group
                id="phone"
                label-for="phone">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="phone"
                type="text"
                placeholder="Enter Phone Number"
                required
                disabled
                v-model="data.phone_number"
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="email">Email Address <star></star></label>
                <b-form-group
                id="email"
                label-for="email">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="email"
                type="email"
                placeholder="Enter Email Address"
                required
                v-model="data.email"
                readonly
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="username">Username <star></star></label>
                <b-form-group
                id="username"
                label-for="username">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="username"
                type="text"
                placeholder="Enter Username"
                v-model="data.username"
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="address">Address <star></star></label>
                <b-form-textarea
                id="address"
                placeholder="Enter Address..."
                rows="3"
                max-rows="6"
                v-model="data.address"
                required
                disabled
                ></b-form-textarea>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <label class="akkurate-dark" for="roles">Roles <star></star></label>
                <b-form-group
                id="roles"
                label-for="roles">
                <b-form-input
                class="mb-3 mt-2 field-container fields"
                id="username"
                type="text"
                placeholder="Enter Username"
                v-model="data.roles['0'].display_name"
                disabled
                ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-4 mt-4">
                <b-form-group>
                    <template #label>
                        <label class="akkurate-dark" for="status">Status <star></star></label>
                    </template>
                    <b-form-radio disabled v-model="data.is_active" name="status" value="1">Active</b-form-radio>
                    <b-form-radio disabled v-model="data.is_active" name="status" value="2">Inactive</b-form-radio>
                </b-form-group>
            </div>
        </div>
        <template #modal-footer>
                <div class="w-100">
                <!-- begin loader -->
                <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                <!-- end loader -->
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button @click="$bvModal.hide(String(data.id))" type="button" class="akkurate-gray-btn btn-size ms-2 float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
                </div>
            </template> 
        </b-modal>
    </div>
    
  </template>
<script>
export default {
    props: ["data"],
    data(){
        return {
            options:[],
            selected: ['name'],
        }
    },
}
</script>