<template>
    <div>
        <form @submit.prevent="filterTable">
            <div class="row">
                <div class="col-12 col-md-5">
                    <label class="akkurate-dark small d-none d-lg-block" for="search">Search</label>
                    <b-form-group id="filter" label-for="filter" class="akkurate-dark">
                        <b-form-input id="filter" class="mt-2 field-container fields" type="text" v-model="form.filter"
                            placeholder="Search by code...">
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="col">
                    <label class="akkurate-dark small d-none d-lg-block" for="account_number">Account number</label>
                    <b-form-group id="account_number" label-for="account_number" class="akkurate-dark">
                        <b-form-input id="account_number" class="mt-2 field-container fields" type="text"
                            v-model="form.account_number" placeholder="Enter Account number">
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="col mb-2 mb-md-0">
                    <label class="akkurate-dark small d-none d-lg-block" for="type">Status</label>
                    <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option">
                    </multiselect>
                </div>
                <div class="mt-2 d-grid gap-2 col-md-2 d-flex align-content-center justify-content-center mt-auto ">
                    <b-button type="submit"
                        class="akkurate-green-btn akkurate-auth-size w-100 d-flex align-items-center justify-content-center"><i
                            class="fas fa-search "></i><span class="d-none d-lg-block ms-2">Search</span></b-button>
                    <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                        <i class="fas fa-bars akkurate-small"></i>
                    </b-button>
                </div>
            </div>
            <div class="row mt-2" v-if="more_details">
                <div class="col-12 col-md-3">
                    <label class="akkurate-dark small" for="loan-re-date">Start Date</label>
                    <b-form-group id="loan-re-date" label-for="loan-re-date">
                        <b-form-input class="mt-2 field-container fields" type="date"
                            v-model="form.start_date"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-3">
                    <label class="akkurate-dark small" for="loan-re-date">End Date</label>
                    <b-form-group id="loan-re-date" label-for="loan-re-date">
                        <b-form-input class="mt-2 field-container fields" type="date"
                            v-model="form.end_date"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12 col-md-3">
                    <label class="akkurate-dark small" for="branch">Branch</label>
                    <multiselect class="mt-2 field-container" v-model="form.branch" :options="form.branch_option"
                        track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                    </multiselect>
                </div>
                <div class="col-12 col-md-3">
                    <label class="akkurate-dark small" for="acc_types">Account type </label>
                    <multiselect class="mt-2 field-container" v-model="form.acc_types" :options="form.acc_types_option"
                        track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                    </multiselect>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['form', 'filterTable'],
    data() {
        return {
            status_options: ['Cash', 'Cheque'],
            more_details: false,

        }
    },
    methods: {
        show_more() {
            this.more_details = !this.more_details
        },

    }
}

</script>