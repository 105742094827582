<template>
    <div>
        <a title="Reedemed" class="btn btn-sm akkurate-blue text-white" v-b-modal="String(data.id)">
            <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" /></span>
        </a>
        <!-- <b-button class="akkurate-green-btn" >Advance Search</b-button> -->
        <b-modal :id="String(data.id)" size="lg" @show="modalShown" @ok="handleOk">
            <template #modal-title>
                <p class="m-0 akkurate-blue-text fw-bold">Fixed Term Deposit Redemption</p>
            </template>
            <div class="row">
                <div class="col-12 text-center">
                    <p class="akkurate-danger">{{ this.message }}</p>
                </div>
                <div class="col-md-6 mb-3">
                    <label class="akkurate-dark" for="mandate">Mandate</label>
                    <b-form-group label-for="mandate" id="mandate" name="mandate" :state="validateState('mandate')"
                        data-vv-as="Mandate">
                        <multiselect id="mandate" name="mandate" class="mt-2 field-container"
                            v-validate="{ required: true }" v-model="form.mandate" :options="mandate_options">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('mandate') }}</b-form-invalid-feedback>
                </div>
                <div class="col-md-6 mb-3">
                    <label class="akkurate-dark" for="mode">Mode</label>
                    <b-form-group label-for="mode" id="mode" name="mode" :state="validateState('mode')"
                        data-vv-as="Mode">
                        <multiselect id="mode" name="mode" class="mt-2 field-container" v-validate="{ required: true }"
                            v-model="form.mode" @select="selectedMode" :options="mode_options">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('mode') }}</b-form-invalid-feedback>
                </div>
                <div class="col-md-6">
                    <label class="akkurate-dark" for="amount">Amount</label>
                    <b-form-group label-for="amount" class="mb-3">
                        <b-form-input id="amount" name="amount" class="mt-2 field-container fields"
                            v-model="form.amount" v-validate="{ required: true }" :state="validateState('amount')"
                            data-vv-as="amount" :disabled="mandate_amt" type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('amount') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6 mb-3" v-show="cash">
                    <label class="akkurate-dark" for="liquid_fund">Select Source of funds</label>
                    <b-form-group label-for="liquid_fund" id="liquid_fund" name="liquid_fund"
                        :state="validateState('liquid_fund')" data-vv-as="Liquid funds">
                        <multiselect id="liquid_fund" label='name' name="liquid_fund" :custom-label="customLabel"
                            class="mt-2 field-container" v-validate="{ required: false }" v-model="form.liquid_fund"
                            :options="liquid_funds">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('liquid_fund') }}</b-form-invalid-feedback>
                </div>
                <div class="col-md-6 mb-3" v-show="cheque">
                    <label class="akkurate-dark" for="bank_current">Select Bank GL</label>
                    <b-form-group label-for="bank_current" id="bank_current" name="bank_current"
                        :state="validateState('bank_current')" data-vv-as="Bank Current">
                        <multiselect id="bank_current" label='name' name="bank_current" :custom-label="customLabel"
                            class="mt-2 field-container" v-validate="{ required: false }" v-model="form.bank_current"
                            :options="bank_currents">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('bank_current') }}</b-form-invalid-feedback>
                </div>
                <div class="col-md-6 mb-3" v-show="transfer">
                    <label class="akkurate-dark" for="member_product">Select Member Product</label>
                    <b-form-group label-for="member_product" id="member_product" name="member_product"
                        :state="validateState('member_product')" data-vv-as="Member product">
                        <multiselect id="member_product" label='name' name="member_product" :custom-label="customLabelMember"
                            class="mt-2 field-container" v-validate="{ required: false }" v-model="form.member_product"
                            :options="member_products">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('bank_current') }}</b-form-invalid-feedback>
                </div>

                <div class="col-md-6" v-show="cheque">
                    <label class="akkurate-dark" for="cheque_number">Cheque number</label>
                    <b-form-group label-for="cheque_number" class="mb-3">
                        <b-form-input id="cheque_number" placeholder="Enter Cheque number" name="cheque_number" class="mt-2 field-container fields"
                            v-model="form.cheque_number" v-validate="{ required: false }" :state="validateState('cheque_number')"
                            data-vv-as="cheque_number" type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('cheque_number') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>

                <!-- <div class="col-md-6 mb-3" v-show="transfer">
                    <label class="akkurate-dark" for="account">Account</label>
                    <b-form-group label-for="account" id="account" name="account" :state="validateState('account')"
                        data-vv-as="mode">
                        <multiselect id="account" name="account" class="mt-2 field-container"
                            v-validate="{ required: false }" v-model="form.account" :options="account_options">
                        </multiselect>
                    </b-form-group>
                    <b-form-invalid-feedback>{{ veeErrors.first('account') }}</b-form-invalid-feedback>
                </div> -->
                <div class="col-md-6" v-show="transfer">
                    <label class="akkurate-dark" for="note">Note</label>
                    <b-form-group label-for="note" class="mb-3">
                        <b-form-textarea id="note" name="note" class="mt-2 fields" :state="validateState('note')"
                            v-validate="{ required: false }" v-model="form.note" data-vv-as="note"
                            placeholder="Enter Note..."></b-form-textarea>
                        <b-form-invalid-feedback>{{ veeErrors.first('note') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6" v-show="credit_and_momo">
                    <label class="akkurate-dark" for="mobile">Mobile No.</label>
                    <b-form-group label-for="mobile" class="mb-3">
                        <b-form-input id="mobile" name="mobile" :state="validateState('mobile')"
                            v-validate="{ required: false }" data-vv-as="mobile" class="mt-2 field-container fields"
                            type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('mobile') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                <div class="col-md-6" v-show="credit_and_momo">
                    <label class="akkurate-dark" for="reference">Reference</label>
                    <b-form-group label-for="reference" class="mb-3">
                        <b-form-input id="reference" name="reference" :state="validateState('reference')"
                            v-validate="{ required: false }" data-vv-as="reference" class="mt-2 field-container fields"
                            type="text"></b-form-input>
                        <b-form-invalid-feedback>{{ veeErrors.first('reference') }}</b-form-invalid-feedback>
                    </b-form-group>
                </div>
                {{ this.getAccuredAmount }}
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <!-- <b-button @click="$bvModal.hide('advance_search')" type="button" class="akkurate-grey-btn btn-size float-right border-0">Close</b-button> -->
                    <b-button @click="ok()" class="akkurate-blue btn-size me-2 float-right border-0"><font-awesome-icon
                            class="me-2" :icon="['fas', 'save']" />Pay</b-button>
                </div>
            </template>

        </b-modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import ApiService from "@/core/services/api.service";
export default {
    props: ['data', 'refresh_items', 'liquid_funds', 'bank_currents'],
    components: {
        Multiselect
    },
    data() {
        return {
            form: {
                mandate: "Full Redemption",
                mode: "Cash",
                liquid_fund: "",
                bank_current:"",
                cheque_number:"",
                member_product:"",
            },
            mandate_options: ['Full Redemption'],
            mode_options: ['Cash', 'Cheque', 'Transfer', 'Direct Credit', 'Momo Pay'],
            // account_options: ['Saving'],
            member_products:[],
            cash: true,
            transfer: false,
            cheque: false,
            credit_and_momo: false,
            mandate_amt: true,
            fixed_deposit: {},
            message: "",
        }
    },
    computed: {
        getAccuredAmount() {
            if (this.form.mandate == "Full Redemption") {
                this.form.amount = Number(Number(this.fixed_deposit.principal_amount) + Number(this.fixed_deposit.interest_due)).toFixed(2);
            } else if (this.form.mandate == "Redeem only Interest") {
                this.form.amount = Number(this.fixed_deposit.interest_due).toFixed(2);
            } else {
                this.form.amount = Number(this.fixed_deposit.principal_amount) + Number(this.fixed_deposit.interest_due);
            }

        }
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        // dispatchAction(value) {
        //     if (value == 'Full Redemption') {
        //         this.mandate_amt = true
        //     } else {
        //         this.mandate_amt = false
        //     }

        // },
        customLabel({ name, amount }) {
            return `${name} (${amount}) `
        },
        customLabelMember({ account_number, account_type }) {
            return `${account_type.name} (${account_number}) `
        },
        resetForm() {
            this.form = {
                mandate: null,
                mode: null
            };

            this.$nextTick(() => {
                this.$validator.reset();
            });
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                this.redeemFixedTermDeposit();

            });
        },
        async memberAccountSearch() {
            try {
                const response = await ApiService.post("/accounts/paginate", {
                    "page": 1,
                    "per_page": 10,
                    "filter": this.data?.customer?.code,
                    "branch_id": "",
                    "account_type_id": "",
                    "account_number": "",
                    "start_date": "",
                    "end_date": "",
                    "status": "" // Active, Inactive, etc.
                });

                // Handle response and update table data
                this.member_products = response.data.data;  // Return data to populate the table
                console.log(this.member_products);
            } catch (error) {
                console.error(error);
                this.isBusy = false;  // Set isBusy to false in case of an error
                this.$Progress.finish();  // Finish the progress bar even if there's an error

                return [];  // Return an empty array in case of error to prevent the table from breaking
            }
        },

        async redeemFixedTermDeposit() {
            const payload = {
                fixed_deposit_id: this.fixed_deposit.code,
                fixed_deposit_scheme_id: this.fixed_deposit.fixed_deposit_scheme_id,
                fixed_deposit_mandate: this.form.mandate,
                principal_amount: this.fixed_deposit.principal_amount,
                interest_due: this.fixed_deposit.interest_due,
                transaction_mode: this.form.mode,
                liquid_fund: this.form.liquid_fund,
                bank_current: this.form.bank_current,
                cheque_number : this.form.cheque_number,
                member_product: this.form.member_product
            }
            swal.fire({
                title: "Fixed Term Deposit Redeem",
                text: 'Are you sure you want to redeem ' + this.fixed_deposit.code + '-' + this.data?.customer?.first_name + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(async (result) => {
                if (result.value) {
                    this.$Progress.start();
                    await ApiService.post("/investments/redeem-fixed-deposit", payload)
                        .then((response) => {
                            this.isLoadingSaving = false;
                            this.$Progress.finish();
                            // console.log(response);
                            swal.fire({
                                icon: "success",
                                title: "Success",
                                text: response.data.message,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'OK', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });

                            this.isSaving = false;
                            this.refresh_items();
                            // this.form.reset();
                            this.form = this.initialState().form;
                            this.rows = [];
                            // this.form.gl_debit = "";
                        })
                        .catch((error) => {
                            this.isSaving = false;
                            this.$Progress.fail();
                            // console.log('error_msg', error?.response?.data?.message);
                            if (error?.response?.data) {
                                this.message = error.response.data.message
                            }
                        });
                }
            });



        },
        selectedMode(index) {
            console.log(index);
            switch (index) {
                case "Cash":
                    this.cash = true;
                    this.cheque = false;
                    this.transfer = false;
                    this.credit_and_momo = false;
                    break;
                case "Cheque":
                    this.cash = false;
                    this.cheque = true;
                    this.transfer = false;
                    this.credit_and_momo = false;
                    break;
                case "Transfer":
                    this.cash = false;
                    this.cheque = false;
                    this.transfer = true;
                    this.credit_and_momo = false;
                    break;
                case "Direct Credit":
                    this.cash = false;
                    this.cheque = false;
                    this.transfer = false;
                    this.credit_and_momo = true;
                    break;
                case "Momo Pay":
                    this.cash = false;
                    this.cheque = false;
                    this.transfer = false;
                    this.credit_and_momo = true;
                    break;


            }
        },
        modalShown() {
            console.log(this.data);
            this.fixed_deposit = this.data.fixed_deposit;
            this.memberAccountSearch();
        }
    }
}
</script>