UsersIndex

<template>
  <div id="wrapper">
    <SidebarView :users="true" :user-visible="true" :all-users="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="mx-3">
          <!-- begin row  -->
          <div class="row">
            <div class="col-lg-12">
              <div>
                <div class="card card-shape home-box">
                  <div class="card-header d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 fw-bold text-green">All Users</h6>
                    <div class="d-flex align-items-center">
                      <b-button size="sm" class="fw-semibold akkurate-green-btn" type="button" href="/users/create">
                        <span class="akkurate-small"><i class="fas fa-plus"></i> Add User</span>
                      </b-button>
                      <b-link @click="exportToExcel" class="fw-semibold float-end me-3 ms-2 akkurate-green">
                        <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="home-box border border-success p-3">
                      <form @submit.prevent="filterTable">
                        <div class="row">
                          <div class="col">
                            <label class="akkurate-dark small" for="search_term">Search Term</label>
                            <b-form-group id="search_term" label-for="search_term">
                              <b-form-input class="mt-2 field-container fields" type="text" v-model="searchFilter"
                                placeholder="Search by firstname, lastname, phone number, email">
                              </b-form-input>
                            </b-form-group>
                          </div>
                          <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                            <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                                class="fas fa-search me-2"></i>Search</b-button>
                          </div>
                        </div>
                      </form>

                    </div>

                  </div>
                  <div class="card-body pt-0">
                    <div class="table_text_size">
                      <b-table striped bordered responsive id="users-table" :busy.sync="isBusy" :items="rows"
                        :fields="fields" :tbody-tr-class="rowClass" show-empty>
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template #empty="scope">
                          <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                        </template>
                        <template #cell(actions)="data">
                          <span class="d-flex">
                            <users-view :data="data.item"></users-view>
                            <a title="Edit" class="btn btn-sm border-0 akkurate-blue text-white"
                              :href="`/users/${String(data.item.uuid)}/edit`">
                              <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'edit']" /></span>
                            </a>
                            <a title="Reset Password" class="btn btn-sm border-0 akkurate-teal text-white custom-pointer"
                              @click="resetPassword(data.item)">
                              <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'key']" /></span>
                            </a>

                            <a title="Delete" class="btn btn-sm border-0 akkurate-danger-btn text-white custom-pointer"
                              @click="confirmDeleteUser(data.item)">
                              <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'trash']" /></span>
                            </a>
                          </span>

                        </template>
                      </b-table>
                      <div class="d-flex justify-content-between">
                        <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                          :total-rows="totalRecords" :per-page="perPage" aria-controls="users-table"></b-pagination>
                        <div class="col text-end mt-1">
                          <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                          <b-form-group label="Per Page" label-size="sm" class="mb-0">
                            <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                              :options="per_page_options" size="sm"></b-form-select>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row  -->
        </div>
      </div>
      <Footer></Footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import UsersView from '../view/UsersView.vue'

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    UsersView
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    this.loadItems();
  },
  data() {
    return {
      totalRecords: 0,
      searchFilter: "",
      isLoading: true,
      isBusy: true,
      currentPage: 1,
      perPage: 10,
      per_page_options: ['10', '20', '50', '100'],
      per_page_selected: "10",
      fields: [
        {
          label: '#',
          key: 'id',
        },
        {
          label: 'First Name',
          key: 'first_name',
        },
        {
          label: 'Surname',
          key: 'surname',
        },
        {
          label: 'Username',
          key: 'username',
        },
        {
          label: 'Mobile',
          key: 'phone_number',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Role(s)',
          key: 'roles[0].name',
        },
        {
          label: 'Branch',
          key: 'branch.name',
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
      rows: [],
    };
  },
  methods: {

    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.loadItems()
    },
    selectPerPage(index) {
      this.perPage = index
      this.loadItems()
    },
    filterTable() {
      this.loadItems();
    },
    rowClass() {
      return 'custom-row-height'
    },
    // load items is what brings back the rows from server
    async loadItems() {
      //   getFromServer(this.serverParams).then(response => {
      //      this.totalRecords = response.totalRecords;
      //      this.rows = response.rows;
      //   });
      this.isLoading = true;
      await ApiService.post("users/paginate",
        {
          "page": this.currentPage,
          "per_page": this.perPage,
          "filter": this.searchFilter,
        })
        .then(response => {
          // Handle response and update table data
          this.isBusy = false;  // Set isBusy to false when the data is loaded
          this.$Progress.finish();  // Finish the progress bar

          this.totalRecords = response.data.meta.total;
          this.rows = response.data.data;  // Return data to populate the table

        }).catch(() => {
          console.error(error);
          this.isBusy = false;  // Set isBusy to false in case of an error
          this.$Progress.finish();  // Finish the progress bar even if there's an error

          return [];  // Return an empty array in case of error to prevent the table from breaking
        })
        .finally(() => {
          this.isBusy = false;  // Set isBusy to false in case of an error
          this.$Progress.finish();  // Finish the progress bar even if there's an error

        });
    },

    confirmDeleteUser(user) {

      swal.fire({
        title: "Disable User",
        text: `Are you sure you want to disable ${user.username}?`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
        }
      });

    },
    resetPassword(user) {
      swal.fire({
        title: "Reset User Password",
        text: `Are you sure you want to reset the password for ${user.first_name} ${user.surname}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-danger-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
        }
        // allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          //   window.location.reload();
          //send put request to reset password
          ApiService.put(`users/password/reset/${user.id}`)
            .then(response => {
              swal.fire({
                title: "Success",
                text: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
                allowOutsideClick: false
              });
            })
            .catch(error => {
              swal.fire({
                title: "Error",
                text: error.response.data.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
                // allowOutsideClick: false
              });
            });
        }
      });

    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.userTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    }
  }
  // methods: {
  //   getUserOptions() {
  //     this.$Progress.start();
  //     this.isLoading = true;
  //     // axios
  //     //   .get("roles/dropdown")

  //     $(this.$refs.dataTable).DataTable({
  //       serverSide: true,
  //       ajax: {
  //         url: config.api_url + '/users/paginate', // Replace with your server-side endpoint URL
  //         type: 'POST',
  //         beforeSend: function (xhr) {
  //           // Set your authorization header here
  //           xhr.setRequestHeader('Authorization', 'Bearer ' + JwtService.getToken());
  //         },
  //         data: function (data) {

  //           //   this.isLoading = false;
  //           // Customize the request parameters as needed
  //           //   data.page = data.start / data.length + 1;
  //           //   data.pageSize = data.length;
  //           // Add any additional parameters you need, such as sorting information
  //         },
  //         dataSrc: function (json) {
  //           // Handle the server's response here
  //           // Update the DataTables table with the received data
  //           return json.data;
  //         },
  //       },
  //       columns: [
  //         // Define your table columns here
  //         // { data: 'id', title: 'ID' },
  //         { data: 'first_name', title: 'First Name' },
  //         { data: 'surname', title: 'Sur Name' },
  //         { data: 'email', title: 'Email' },
  //         { data: 'username', title: 'Username' },
  //         // Add more columns as needed
  //       ],
  //     });


  //   },
  // }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
