<template>
    <div>
        <b-modal ref="showChequeDeposit" size="xl">
            <template #modal-title>
                <span class="akkurate-green fw-bold">Cheque Deposit</span>
            </template>
            <div class="container-fluid" ref="pdfContent">
                <!-- First Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <institution :institution="report_data?.institution" :title="'CHEQUE TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>
                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">CHEQUE DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                    report_data?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                    report_data?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">CHEQUE DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">NARRATION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green text-uppercase">{{
                                        report_data?.narration }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">CHEQUE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">{{ report_data?.cheque_number }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green akkurate-green mb-2">BANK NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green">{{ report_data?.bank_name }}</p>
                                </div>
                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-green">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-green">{{
                                        report_data?.institution?.currency_code }} {{
                                            displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                report_data?.institution?.telephone }} or email at {{ report_data?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>


                <hr style="border-top: dotted 1px;" class="my-2" />

                <!-- Second Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">
                    <institution :institution="report_data?.institution" :title="'CHEQUE TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>
                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">CHEQUE DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                    report_data?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                    report_data?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>

                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-12 p-0">
                                    <p class="report-s-text akkurate-green fw-bold mb-2">CHEQUE DETAILS</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">NARRATION</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green text-uppercase">{{
                                        report_data?.narration }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">CHEQUE NUMBER</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green mb-2">{{ report_data?.cheque_number }}</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text akkurate-green akkurate-green mb-2">BANK NAME</p>
                                </div>
                                <div class="col-md-6 p-0">
                                    <p class="report-s-text mb-2 akkurate-green">{{ report_data?.bank_name }}</p>
                                </div>
                                <div class="col-md-9 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-green">GRAND TOTAL </p>
                                </div>
                                <div class="col-md-3 p-0 mt-3">
                                    <p class="report-s-text fw-bold akkurate-green">{{
                                        report_data?.institution?.currency_code }} {{
                                            displayNumber(report_data?.amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                report_data?.institution?.telephone }} or email at {{ report_data?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>

            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                 class="float-left"
                 v-if="isLoading"
                 :isLoading="isLoading"
                 ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="generatePDF" type="button"
                        class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon
                                :icon="['fas', 'book']" /> Print</span></b-button>
                    <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                </div>
            </template>
        </b-modal>

    </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import Institution from '@/views/main/components/institution/Institution.vue'
export default {
    props: ['report_data'],
    components: {
        Institution,
    },
    computed: {
        // getLogo() {
        //     var logo = this.report_data?.institution?.logo;
        //     if (logo) {
        //         var split_logo = logo.split(".");
        //         var extension = split_logo[1];
        //         let new_logo = '';
        //         if (extension == 'jpeg') {
        //             new_logo = split_logo[0] + '.' + 'jpg';
        //         }
        //         console.log(new_logo);
        //         return `https://ggbccuapi.akkuratebank.com/${new_logo}`;
        //     }
        // }
    },
    methods: {
        showModal() {
            this.$refs['showChequeDeposit'].show()
        },
        hideModal() {
            this.$refs['showChequeDeposit'].hide()
        },
        // numberToWords(number) {
        //     const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        //     const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        //     const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        //     if (number === 0) return 'zero';

        //     function convertLessThanThousand(n) {
        //         let word = '';
        //         if (n == 100 || n == 200 || n == 300 || n == 400 || n == 500 || n == 600 || n == 700 || n == 800 || n == 900) {
        //             word += units[Math.floor(n / 100)] + ' hundred ';
        //             n %= 100;
        //         }else if (n > 100){
        //             word += units[Math.floor(n / 100)] + ' hundred and ';
        //             n %= 100;
        //         }
        //         if (n >= 20) {
        //             word += tens[Math.floor(n / 10)] + ' ';
        //             n %= 10;
        //         }
        //         if (n > 0) {
        //             if (n < 10) word += units[n] + ' ';
        //             else word += teens[n - 10] + ' ';
        //         }
        //         return word.trim();
        //     }

        //     let result = '';
        //     let billions = Math.floor(number / 1000000000);
        //     let millions = Math.floor((number % 1000000000) / 1000000);
        //     let thousands = Math.floor((number % 1000000) / 1000);
        //     let remainder = number % 1000;

        //     if (billions > 0) {
        //         result += convertLessThanThousand(billions) + ' billion ';
        //     }
        //     if (millions > 0) {
        //         result += convertLessThanThousand(millions) + ' million ';
        //     }
        //     if (thousands > 0) {
        //         result += convertLessThanThousand(thousands) + ' thousand ';
        //     }
        //     if (remainder > 0) {
        //         result += convertLessThanThousand(remainder);
        //     }

        //     return result.trim();
        // },


        // convertNumberToCurrency(number) {
        //     const formattedCurrency = number?.toLocaleString('en-US', {
        //         style: 'currency',
        //         currency: 'GHS' // Change this to your desired currency code (e.g., 'EUR', 'GBP', 'JPY', etc.)
        //     });
        //     return formattedCurrency;

        // },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
    },
}
</script>