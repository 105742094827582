<template>
    <div id="wrapper">
        <SidebarView :loan="true" :add-loan="true" :loan-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid mb-3">
                    <div class="card card-shape home-box">
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h6 class="m-0 fw-bold text-green">Account Validation</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="verifyLoan()">
                                <div class="row">
                                    <loan-filter :filter="filter" :scheme_options="scheme_options"></loan-filter>
                                    <div class="mt-auto col">
                                        <b-button type="submit" :disabled="isVerifying"
                                            class="akkurate-green-btn akkurate-auth-size w-100 mb-3"><font-awesome-icon
                                                class="me-2" :icon="['fas', 'search']" />Validate</b-button>
                                    </div>
                                </div>
                            </form>

                            <div :class="{ 'akkurate-danger-bg': notQualify, 'akkurate-green-bg': isQualify }"
                                class="row akkurate-gray-bg d-flex align-items-center py-2">
                                <div class="col-6 col-md-6">
                                    <h6 class="m-0 fw-bold text-white">ACCOUNT PREVIEW</h6>
                                </div>
                                <div class="col-6 col-md-6 d-flex align-items-center justify-content-end">
                                    <font-awesome-icon v-show="isQualify" class="akkurate-icon-check me-2"
                                        :icon="['fass', 'circle-check']" />
                                    <font-awesome-icon v-show="notQualify" class="akkurate-icon-danger me-2"
                                        :icon="['fas', 'ban']" />
                                    <h6 v-if="isQualify" class="mb-0 fw-bold text-white text-uppercase">{{ this.success
                                        }}</h6>
                                    <h6 v-if="notQualify" class="mb-0 fw-bold text-white text-uppercase">{{ title }}
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-12 text-center">
                                    <img src="@/assets/img/user-avater.jpg" style="height:200px" class="img-fluid"
                                        alt="user-profile" />
                                    <p class="mt-2 akkurate-green fw-bold">Profile Image</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                    <img v-if="imageLoaded" v-bind:src="frontImgUrl" alt="Image" @load="handleImageLoad"
                                        style="height:200px" class="img-fluid text-center" />
                                    <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                        style="height:200px" class="img-fluid text-center" />
                                    <p class="mt-2 akkurate-green fw-bold">ID Card - Front</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                    <img v-if="imageLoaded" v-bind:src="backImgUrl" alt="Image" @load="handleImageLoad"
                                        style="height:200px" class="img-fluid text-center" />
                                    <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                        style="height:200px" class="img-fluid text-center" />
                                    <p class="mt-2 akkurate-green fw-bold">ID Card - Back</p>
                                </div>
                                <div class="mt-auto col-md-3 col-12 akkurate-green">
                                    <h6 class="fw-bold">ADDRESSS</h6>
                                    <p class="mb-0"> {{ this.details.customer?.address }} {{
                                        this.details.customer?.home_town }}</p>
                                    <p class="mb-0">TEL: {{ this.details.customer?.mobile }}</p>
                                    <p>EMAIL: {{ this.details.customer?.email }}</p>
                                    <div class="d-flex">
                                        <p class="fw-bold">TEL: <span class="fw-light me-3"> {{
                                            this.details.customer?.mobile }}</span></p>
                                        <p class="fw-bold">STAFF ID: <span class="fw-light">9858958</span></p>
                                    </div>
                                </div>
                            </div>
                            <div class="row akkurate-skyblue-bg py-2 d-flex align-items-center mb-3">
                                <div class="col">
                                    <h6 class="m-0 text-white">Saving: {{ institution.currency_code + ' ' +
                                        Number(details?.savings_account_balances).toLocaleString() }}</h6>
                                </div>
                                <div class="col">
                                    <h6 class="m-0 text-white">Shares: {{ institution.currency_code + ' ' +
                                        Number(details?.shares_account_balances).toLocaleString() }}</h6>
                                </div>
                                <div class="col">
                                    <h6 class="m-0 text-white">Credit {{ getCreditPercentage }}</h6>
                                </div>
                                <div class="col">
                                    <h6 class="m-0 text-white">Current Loan: {{ getCurrentLoan }}</h6>
                                </div>
                                <div class="col">
                                    <h6 class="m-0 text-white">Risk Level:</h6>
                                </div>
                                <!-- <div class="col-2 col-md-2">
                                            <b-button class="akkurate-gray-btn w-100" :disabled="apply_loan">Apply Loan</b-button>
                                        </div> -->
                            </div>
                            <div class="row">
                                <div v-show="reason" class="col-12 col-md-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <h5 class="fw-bold akkurate-skyblue">Reasons</h5>
                                        </div>
                                        <div class="col-12 col-md-12">
                                            <ul class="border border-danger">
                                                <li class="akkurate-danger" v-for="(item, index) in detail"
                                                    :key="index">
                                                    {{ item }}
                                                </li>
                                                <li class="akkurate-danger" v-for="(item, index) in account_errors"
                                                    :key="index">
                                                    {{ item }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="title">Fullname</label>
                                    <b-form-group id="title" label-for="title">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.fullname" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="search_term">Account Number</label>
                                    <b-form-group id="search_term" label-for="search_term">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.account_number" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="gender">Gender</label>
                                    <b-form-group id="gender" label-for="gender">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.customer.gender" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="registration_date">Registration Date</label>
                                    <b-form-group id="registration_date" label-for="registration_date">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.created_at" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="relation_officer">Relationship Officer</label>
                                    <b-form-group id="relation_officer" label-for="relation_officer">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.relation_officer" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="branch">Branch</label>
                                    <b-form-group id="branch" label-for="branch">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.branch.name" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark" for="dob">Date of Birth</label>
                                    <b-form-group id="dob" label-for="dob">
                                        <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="details.customer.date_of_birth" disabled></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-8">
                                    <label class="akkurate-dark" for="marital_status">Mandate</label>
                                    <div class="row" style="border:1px solid #ccc !important;">
                                        <div class="col-md-6">
                                            <b-form-group>
                                                <b-form-radio name="status" value="1">ANY OF THE
                                                    FOLLOWING</b-form-radio>
                                                <b-form-radio name="status" value="2">ANY TWO OF THE
                                                    FOLLOWING</b-form-radio>
                                                <b-form-radio name="status" value="4">ALL OF THE
                                                    FOLLOWING</b-form-radio>
                                                <b-form-radio name="status" value="5">THE PRINCIPAL SIGNATORY AND ANY
                                                    OTHER</b-form-radio>
                                            </b-form-group>

                                        </div>
                                        <div class="col-md-6">
                                            <!-- <ol>
                                                    <li>COLLINS AMOAKO ADDAE - PRINCIPAL</li>
                                                    <li>ERIC OWUSU ABABIO</li>
                                                    <li>FRED MACLEAN</li>
                                                    <li>JUSTUS AIDOO</li>
                                                    <li>KWAKU SINTIM</li>
                                                </ol> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-body">
                            <b-container>
                                <b-row>
                                    <b-col md="12" class="d-flex">
                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="authorization.id + 'authorization'">Authorization</b-button>
                                        <loan-authorization :institution="institution" :data="authorization"
                                            :authorization="'authorization'"></loan-authorization>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="repayment.id + 'repayment'">Repayment</b-button>
                                        <loan-repayment :institution="institution" :data="repayment"
                                            :repayment="'repayment'"></loan-repayment>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="statement.id + 'statement'">Loan Statement</b-button>
                                        <loan-statement :institution="institution" :data="statement"
                                            :statement="'statement'"></loan-statement>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="entries.id + 'entries'">Loan Entries</b-button>
                                        <loan-entries :institution="institution" :data="entries"
                                            :entries="'entries'"></loan-entries>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="adjustment.id + 'adjustment'">Adjustment</b-button>
                                        <loan-adjustment :institution="institution" :data="adjustment"
                                            :adjustment="'adjustment'"></loan-adjustment>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="topup.id + 'topup'">Top Up</b-button>
                                        <loan-topup :institution="institution" :data="topup"
                                            :topup="'topup'"></loan-topup>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            @click="loanRefinancing">Refinance</b-button>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="writeoff.id + 'writeoff'">Write Off</b-button>
                                        <loan-write-off :institution="institution" :data="writeoff"
                                            :writeoff="'writeoff'"></loan-write-off>

                                        <b-button class="akkurate-green-btn w-100 akkurate-small"
                                            v-b-modal="status.id + 'status'">Change Status</b-button>
                                        <loan-status :institution="institution" :data="status"
                                            :status="'status'"></loan-status>

                                      </b-col>
                                </b-row>
                            </b-container>
                        </div> -->
                        <hr class="m-0" />
                        <div v-show="show_application">
                            <loan-term :account_info="details" :product_info="products" @loan_no="loanNumber"
                                :guarantors="guarantors" @loanSaved="resetForm" :loan_premium_plan="filter.scheme?.loan_premium_plan"
                                :loan_processing_fee="filter.scheme?.loan_processing_fee"></loan-term>
                        </div>
                        <div v-show="show_refinancing">
                            <div class="card-header py-3">
                                <h6 class="m-0 fw-bold text-green">Loan Refinancing</h6>
                            </div>
                            <div class="card-body">
                                <loan-refinance :account_info="details" :product_info="products"
                                    @loan_no="loanNumber"></loan-refinance>
                            </div>
                        </div>
                        <div class=card-body>
                            <div>
                                <b-row>
                                    <div>
                                        <b-tabs justified>
                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">Guarantor(s)</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <loan-guarantors @addMoreGuarantors="addMoreGuarantors"
                                                            :loan_number="loan_number" :account_info="details"
                                                            :guarantors="guarantors" @addGuaranter="addGuaranter"
                                                            @removeGuarantor="removeGuarantor"
                                                            @getGuarantorAccount="getGuarantorAccount"></loan-guarantors>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">Collatoral</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <collatoral :loan_number="loan_number"></collatoral>
                                                    </div>
                                                </div>
                                            </b-tab>

                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">Other Attachments</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <attachments :loan_number="loan_number"></attachments>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">Comment</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <comment></comment>
                                                        <div class="text-end py-3">
                                                            <button type="submit"
                                                                class="btn akkurate-green-btn text-white btn-size my-2 me-2">
                                                                <span class="small">
                                                                    <font-awesome-icon class="me-2"
                                                                        :icon="['fas', 'save']" />Save
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">History</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <history></history>
                                                        <div class="text-end py-3">
                                                            <button type="submit"
                                                                class="btn akkurate-green-btn text-white btn-size my-2 me-2"><font-awesome-icon
                                                                    class="me-2" :icon="['fas', 'save']" />Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-tab>
                                            <b-tab>
                                                <template #title>
                                                    <span class="small fw-bold">Contract</span>
                                                </template>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <contract :details="details" :institution="institution">
                                                        </contract>
                                                        <div class="text-end py-3">
                                                            <button type="submit"
                                                                class="btn akkurate-green-btn text-white btn-size my-2">
                                                                <span class="small">
                                                                    <font-awesome-icon class="me-2"
                                                                        :icon="['fas', 'save']" />Save
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </b-row>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>

</template>

<script>

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import LoanFilter from '../form/LoanFilter.vue'
import LoanTerm from '../form/LoanTerm.vue'
import Collatoral from '../form/Collatoral.vue'
import Attachments from '../form/Attachments.vue';
import Comment from '../form/Comment.vue';
import History from '../form/History.vue';
import Guarantor from '../form/Guarantor.vue';
import LoanGuarantors from '../form/LoanGuarantors.vue';
import Contract from '../form/Contract.vue';
import LoanRepayment from '../../repayment/LoanRepayment.vue'
import LoanStatement from '../../statements/LoanStatement.vue'
import LoanEntries from '../../entries/LoanEntries.vue'
import LoanAdjustment from '../../adjustment/LoanAdjustment.vue'
import LoanTopup from '../../topup/LoanTopup.vue'
import LoanWriteOff from '../../writeoff/LoanWriteOff.vue'
import LoanStatus from '../../status/LoanStatus.vue'
import LoanAuthorization from '../../authorization/LoanAuthorization.vue'
import LoanDisbursement from '../../disbursement/LoanDisbursement.vue'
import LoanRefinance from '../form/LoanRefinance.vue'
import ApiService from '@/core/services/api.service';

export default {
    name: "LoanApplication",
    components: {
        SidebarView,
        Footer,
        TopbarView,
        LoanFilter,
        LoanTerm,
        Collatoral,
        Attachments,
        Comment,
        History,
        Guarantor,
        LoanGuarantors,
        Contract,
        LoanRepayment,
        LoanStatement,
        LoanEntries,
        LoanAdjustment,
        LoanTopup,
        LoanWriteOff,
        LoanStatus,
        LoanAuthorization,
        LoanDisbursement,
        LoanRefinance,
    },
    data() {
        return {
            ...this.initiateState(),
            authorization: {
                id: 'app_authorization',
            },
            statement: {
                id: 'app_statement',
            },
            entries: {
                id: 'app_entries',
            },
            repayment: {
                id: 'app_repayment'
            },
            adjustment: {
                id: 'app_adjustment'
            },
            disbursement: {
                id: 'app_disbursement'
            },
            topup: {
                id: 'app_topup'
            },
            writeoff: {
                id: 'app_writeoff'
            },
            status: {
                id: 'app_status'
            },

            filter: {
                account_number: "",
                scheme: "",
                principal_amount: "",
            },
            frontImgUrl: "",
            backImgUrl: "",
            imageLoaded: false,
            scheme_options: [],
            notQualify: false,
            isQualify: false,
            isVerifying: false,
            apply_loan: true,
            show_application: false,
            show_refinancing: false,
            account_info: [],
            title: "",
            detail: null,
            institution: {},
            loan_number: "",
            customer: {},
        }
    },
    mounted() {
        this.getScheme();
        this.getInsistution();
    },
    computed: {
        getCreditPercentage() {
            const criteria = [
                this.details.customer.gh_card_back_name,
                this.details.savings_account_balances > 0,
                this.details.customer.address,
                this.details.customer.mobile,
                this.details.customer.passport_pic_name
            ];

            const no_of_pass = criteria.filter(criterion => criterion).length;
            const total_criteria = criteria.length;
            const percentage = (no_of_pass / total_criteria) * 100;

            return `${percentage}%`;
        },
        getCurrentLoan() {
            let currentLoan = "";
            if (this.details.current_loan == 'no') {
                currentLoan = "None"
            } else if (this.details.current_loan == 'yes') {
                currentLoan = "Yes"
            }

            return currentLoan;
        }
    },
    methods: {
        async getScheme() {
            this.$Progress.start();
            await ApiService.get('/loans/loanProductSchemes')
                .then((response) => {
                    this.$Progress.finish();
                    this.scheme_options = response.data.data;
                    // console.log(response.data.data);
                }).catch((error) => {
                    this.$Progress.fail();
                    // console.log(error);
                })

        },
        async getInsistution() {
            await ApiService.get('/settings/institution')
                .then((response) => {
                    this.institution = response.data.data;
                    this.$Progress.finish();
                    // console.log(response.data.data);
                }).catch((error) => {
                    this.$Progress.fail();
                    // console.log(error);
                })
        },
        // getCustomerInfobyAccountNo(account_num) {
        //     ApiService.query(`/accounts/view/${account_num}`).then((response) => {
        //         this.account = response.data.account;
        //         this.account.fullname = response.data.account.customer.first_name + ' ' + response.data.account.customer.last_name;
        //         console.log(response.data.account);

        //     }).catch((error) => {
        //         console.log(error);
        //     })
        // },
        addGuaranter(guarantor) {

        },
        addMoreGuarantors() {
            this.guarantors.push({});
        },
        removeGuarantor(guarantor) {
            const index = this.guarantors.indexOf(guarantor);
            this.guarantors.splice(index, 1);
        },
        getGuarantorAccount(guarantor) {
            // console.log(index)
            // console.log(guarantor)

            const index = this.guarantors.indexOf(guarantor);
            ApiService.query(`/accounts/view/${guarantor.account_number}`)
                .then((response) => {
                    // this.guarantors[index] = response.data.account;
                    // this.guarantors[index].account_balance = response.data.account.account_balance;
                    // this.guarantors[index].customer = response.data.account.customer;
                    // this.guarantors[index].account_type = response.data.account.account_type;

                    // this.$set(this.guarantors[index], 'account_balance', response.data.account.account_balance);
                    // this.$set(this.guarantors[index], 'customer', response.data.account.customer);
                    // this.$set(this.guarantors[index], 'account_type', response.data.account.account_type);
                    this.$set(this.guarantors, index, response.data.account);

                })
                .catch((error) => {

                    // console.log(error);
                    let obj = {
                        account_number: guarantor.account_number
                    }
                    this.$set(this.guarantors, index, obj);

                })
        },
        verifyLoan() {
            // console.log(this.form.account_number);
            // console.log(this.form.scheme.id);
            this.isVerifying = true;
            this.$Progress.start();
            // this.getCustomerInfobyAccountNo(this.form.account_number);
            ApiService.query('/loans/verification/checkQualification', {
                params: {
                    loanproduct_id: this.filter.scheme.id, // Loan Product Scheme id - integer
                    account_number: this.filter.account_number // Stringgh_card_back_name
                }
            }).then((response) => {
                // console.log(response);
                this.isVerifying = false;
                this.isQualify = true;
                this.success = response.data.message;
                this.errors = this.initiateState().errors;
                this.reason = false;
                this.notQualify = false;
                this.details = response.data.data.accountInfo;
                // this.guarantors.push(response.data.data.accountInfo);
                // this.details.guarantors = response.data.data.accountInfo.gu
                if (this.guarantors.length < 1) {
                    this.guarantors.push(response.data.data.accountInfo);
                }
                console.log(response.data.data.accountInfo);
                this.products = response.data.data.loanProduct;
                this.details.fullname = response.data.data.accountInfo.customer.first_name + ' ' + response.data.data.accountInfo.customer.last_name;
                this.details.current_loan = response.data.data.currentLoan
                this.show_application = true;
                this.handleImageLoad();
                this.$Progress.finish();

            }).catch((error) => {
                this.isVerifying = false;
                // console.log(error.response.data.errors[0]);

                if (error) {
                    this.errors = error.response?.data?.errors[0];
                    // this.details = {};
                    // this.errors = error.response?.data?.errors?.account_number
                    this.notQualify = true;
                    this.reason = true;
                    this.isQualify = false;
                    // this.success = this.initiateState().success;
                    this.details = this.initiateState().details;
                    this.show_application = false;
                    this.$Progress.fail();

                    if (error.response?.status == 422) {

                        this.account_errors = error.response?.data?.errors?.account_number;
                        this.title = "INVALID ACCOUNT NUMBER";
                        // console.log(this.account_errors);
                    }
                    if (error.response?.data?.errors[0]?.status == 422) {
                        // console.log(error.response?.data?.errors[0]);
                        this.title = error.response?.data?.errors[0].title;
                        // this.loan_failed = true;
                        this.detail = error.response.data.errors[0].detail;
                        this.reason = true;
                    }
                }

            })
            // .finally((error) => {
            //     // this.notQualify = true;
            //     // this.reason = true;
            //     // this.isQualify = false;
            //     // this.success = this.initiateState().success;
            //     // this.details = this.initiateState().details;
            // })
        },
        handleImageLoad() {
            this.imageLoaded = true;
            this.frontImgUrl = this.details.customer?.gh_card_front_path;
            this.backImgUrl = this.details.customer?.gh_card_back_path;
        },
        loanNumber(loan_number) {
            this.loan_number = loan_number;
            this.show_application = false;
        },
        loanRefinancing() {
            this.show_refinancing = !this.show_refinancing;
            // console.log()
        },
        resetForm() {
            this.details = this.initiateState().details
            this.guarantors = this.initiateState().guarantors
            this.filter.account_number = ""
            this.filter.scheme = ""
        },
        initiateState() {
            return {
                details: {
                    fullname: "",
                    customer: "",
                    account_number: "",
                    gender: "",
                    relationship_officer: "",
                    created_at: "",
                    branch: [],
                    date_of_birth: "",
                    gh_card_front_path: "",
                    gh_card_back_path: "",
                    savings_account_balances: "",
                    shares_account_balances: "",
                    customer: {
                        gh_card_back_name: "",
                        address: "",
                        mobile: "",
                        passport_pic_name: "",
                    },
                    current_loan: "",
                },
                guarantors: [],
                products: {
                    id: "",
                    name: "",
                },
                success: "",
                errors: [],
                reason: false,
                account_errors: [],
            }
        }
    }
}
</script>