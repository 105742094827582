<template>
    <b-modal 
        ref="showChequePrintout" 
        size="xl"  
        @shown="modalShown">
        <template #modal-title>
            <div class="fw-bold akkurate-green">Cheque Print out</div>
        </template>
        <div class="container-fluid" ref="pdfContent">
            <div class="row">
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-2">
                            <!-- <img class="img-fluid img-visibility printout-logo-size" :src="`https://ggbccuapi.akkuratebank.com/${report_data?.institution?.logo}`"> -->
                            <img class="img-fluid img-visibility printout-logo-size" src="../../../../../../assets/img/default_logo.png">
                        </div>
                        <div class="col-md-6 row align-items-center">
                          <div>
                            <p class="mb-0 akkurate-small">{{ report_data?.institution?.name }}</p>
                            <p class="mb-0 akkurate-small">{{ report_data?.institution?.postal }}</p>
                          </div>
                        </div>
                        <div class="col-md-auto  row align-items-center">
                            <div>
                                <p class="mb-0 akkurate-small fw-bold">TRATRA HILL</p>
                                <p class="mb-0 akkurate-small fw-bold">CG-0702-0050</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                       <div class="col-md-12">
                         <p class="mb-0 text-xs">THIS SECURITY CHEQUE WILL HIGHLIGHT ALTERATIONS TO VERIFY HOLD TO THE LIGHT UV PROTECTED</p>
                       </div>
                       <div class="col-md-12 text-center mt-2">
                         <p class="mb-0 fw-bold">660100 1301130001271</p>
                       </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <p class="mb-0 line-one">Pay</p>
                        </div>
                        <div class="col-md-2">
                            <p>or Order</p>
                        </div>
                        <div class="col-md-12 mt-4 text-center">
                            <p class="line-one">{{ report_data.fullname }}</p>
                        </div>
                        <div class="col-md-10 mt-4 text-center text-uppercase">
                            <p class="line-one">{{ convertMoneyToWords(report_data.amount) }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row mt-5">
                        <div class="col-md-12 text-end">
                            <p class="mb-0 akkurate-small" >131130001274 <span class="fw-bold"> 04-01-30</span></p>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 text-end">
                            <div class="d-flex justify-content-end align-items-center">
                                <p class="akkurate-small mb-0 me-2">Date</p>
                                <div class="date-border">{{ report_data.trans_date }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 text-end">
                            <div class="d-flex justify-content-end align-items-center">
                                <!-- <p class="mb-0 me-2 fw-bold">{{ report_data?.institution?.currency_code }}</p> -->
                                <div class="amount-space pt-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 justify-content-end">
                        <div class="col-md-6 text-end">
                            <p class="mb-0">{{ report_data?.institution?.name }}</p>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-auto mt-auto">
                            <p class="mb-0 akkurate-small">Signature</p>
                        </div>
                        <div class="col-md-4 mt-auto">
                            <p class="mb-0 signature-line"></p>
                        </div>
                        <div class="col-md-5 mt-auto">
                            <p class="mb-0 signature-line"></p>
                        </div>
                    </div>
                    

                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-7">
                   <div class="row justify-content-end">
                     <div class="col-md-3">
                        <p class="mb-0 text-xs">Cheque Number</p>
                     </div>
                     <div class="col-md-3">
                        <p class="mb-0 text-xs">Bank Number</p>
                     </div>
                   </div>
                    
                </div>
                <div class="col-md-5">
                    <div class="row justify-content-end">
                     <div class="col-md-3">
                        <p class="mb-0 text-xs">Account Number</p>
                     </div>
                     <div class="col-md-3">
                        <p class="mb-0 text-xs">Trans Code</p>
                     </div>
                     <div class="col-md-6 text-end">
                        <p class="mb-0 text-xs">Do not write below this line</p>
                     </div>
                   </div>
                </div>
            </div>
            <hr/>
            <div class="row my-4">
                <div class="col-md-12 text-center">
                    <p class="mb-0">2544887487478484787478487478478478487478</p>
                </div>
            </div>
            <hr/>

        </div>
        <template #modal-footer>
                 <div class="w-100">
                 <!-- begin loader -->
                 <!-- <beat-loader-component 
                 class="float-left"
                 v-if="isLoading"
                 :isLoading="isLoading"
                 ></beat-loader-component> -->
                 <!-- end loader -->
                 <!-- Emulate built in modal footer ok and cancel button actions -->
                 <b-button @click="generatePDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                 <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
                 </div>
        </template>  
    </b-modal>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
    props: ['report_data'],
    methods:{
        showModal() {
            this.$refs['showChequePrintout'].show()
        },
        hideModal() {
            this.$refs['showChequePrintout'].hide()
        },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
        modalShown(){

        },
    }
}
</script>
<style scoped>
.printout-logo-size{
    height:80px;
}
.line-one{
    border-bottom: 1px solid #929191;
}
.date-border{
    border: 1px solid #929191;
    width: 150px;
    height: 30px;

}
.amount-space {
    background-color: #f0fdb5;
    width: 250px;
    height: 50px; 
}
.signature-line{
    border-bottom: 1px solid #929191;
}
</style>