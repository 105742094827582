<template>
    <div v-if="isLoading" class="col-12">
        <!-- begin loader -->
        <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
        <!-- end loader -->
    </div>
    <div v-else class="row">
        <div class="col-12 col-md-6">
            <label class="akkurate-dark" for="interest_balance">Interest Balance <star></star></label>
            <b-form-group id="interest_balance" label-for="interest_balance">
                <b-form-input class="mb-3 mt-2 field-container fields" type="text" placeholder="Enter Interest Balance"
                    v-model="form.interest_balance"></b-form-input>
                <errors v-if="form.errors.interest_balance">
                    {{ form.errors.interest_balance[0] }}
                </errors>
            </b-form-group>
        </div>
        <div class="col-12 col-md-6">
            <label class="akkurate-dark small" for="lpp_type">LPP Type</label>
            <b-form-group id="lpp_type" name="lpp_type" label-for="lpp_type" :description="lpp_description">
                <multiselect id="lpp_type" name="lpp_type" class="mt-2 field-container" @select="selectLPP" label="name"
                    v-model="form.lpp_type" :options="lpp_type_options">
                </multiselect>
            </b-form-group>
            <b-form-invalid-feedback>
                {{ veeErrors.first('lpp_type') }}
            </b-form-invalid-feedback>
        </div>

        <div class="col-12 col-md-6">
            <b-form-group label-for="lpp_value" :description="interest_expected_description">
                <template #label>
                    <label class="akkurate-dark small" for="lpp_value">LPP Value <star></star></label>
                </template>
                <b-form-input id="lpp_value" name="lpp_value" class="mt-2 field-container fields" type="text"
                    v-model="form.lpp_value" disabled></b-form-input>
                <errors v-if="form.errors.lpp_value">
                    {{ form.errors.lpp_value[0] }}
                </errors>
            </b-form-group>
        </div>
        <div class="col-12 col-md-6">
            <b-form-group label-for="lpf_value" :description="loan_lpf_description">
                <template #label>
                    <label class="akkurate-dark small" for="lpf_value">LPF Value <star></star></label>
                </template>
                <b-form-input id="lpf_value" name="lpf_value" class="mt-2 field-container fields" type="text"
                    v-model="form.lpf_value" disabled></b-form-input>
                <errors v-if="form.errors.lpf_value">
                    {{ form.errors.lpf_value[0] }}
                </errors>
            </b-form-group>
        </div>

        <hr class="mt-4" />

        <div class="col-12 text-end">
            <button type="submit" class="btn akkurate-green-btn text-white btn-size my-2 me-3">
                <span class="small">
                    <font-awesome-icon class="me-2" :icon="['fas', 'save']" />Update
                </span>
            </button>
        </div>

    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            isLoading: false,
            form: {
                interest_balance: this.loan_edit.interest_balance,
                lpp_value: this.loan_edit.lpp_value,
                lpf_value: this.loan_edit.lpf_value,
                errors: [],
            },
            lpp_type_options: [
                { id: 'cua_standard', name: 'CUA STANDARD' },
                { id: 'incure_interest', name: 'INCURE INTEREST' },
                { id: 'custom', name: 'CUSTOM' },
            ],
            lpp_description: "",
            interest_expected_description: "",
            loan_lpf_description: "",
        }
    },
    props: {
        loan_edit: {
            required: true
        }
    },
    methods: {
        selectLPP(value) {
            // console.log(value)
            let principal = this.loan_edit.principal_amount ?? 0;
            let no_of_months = this.lppMonths() ?? 0;
            let tenor_pecent = this.loan_edit.interest_rate / 100;
            let expected_interest = principal * tenor_pecent * this.loan_edit.tenor;
            let principal_interest = Number(principal) + Number(expected_interest);
            // console.log(principal_interest)
            if (principal != 0 && this.loan_edit.tenor != "" && this.loan_edit.tenor_interval != "") {
                switch (value.id) {
                    case 'cua_standard':
                        this.lpp_description = "Principal * Nò of Month/1000"
                        this.interest_expected_description = ""
                        // console.log(this.lppMonths())
                        this.loan_edit.lpp_value = principal * (no_of_months / 1000);
                        break;
                    case 'incure_interest':
                        // console.log('Tenor Pecentage', tenor_pecent);
                        // console.log('Expected Interest', expected_interest);
                        this.interest_expected_description = "Expected Interest : " + expected_interest;
                        this.lpp_description = "Principal + Interest * Nò of Month/1000"
                        this.loan_edit.lpp_value = principal_interest * (no_of_months / 1000);
                        break;
                    case 'custom':
                        this.lpp_description = "Principal * loan_premium_plan %"
                        this.loan_edit.lpp_value = principal * (this.loan_edit.loan_product.loan_premium_plan / 100)
                        this.interest_expected_description = "Loan Premium plan : " + this.loan_edit.loan_product.loan_premium_plan + "%"
                        break;
                }
                this.loan_edit.lpf_value = principal * (this.loan_processing_fee / 100);
                this.loan_lpf_description = "Loan Processing fees : " + this.loan_processing_fee + "%"

            }

        },
        lppMonths() {
            if (this.loan_edit.tenor_interval === 'Months') {
                return this.loan_edit.tenor;
            }
        }
    }

}
</script>