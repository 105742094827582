<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col-md-12 text-end">
        <b-button class="btn-sm me-2 akkurate-green-btn akkurate-small" title="Filter" v-b-modal.report-filter>
          <font-awesome-icon :icon="['fas', 'filter']" />
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <bar-chart :labels="labels" :data="data" />
          </b-card>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="small fw-bold">System Active Users and Session</div>
                <div class="text-end">
                  <a class="text-decoration-none small akkurate-green" href="#">see more
                    <i class="fas fa-arrow-right akkurate-extra-small"></i></a>
                </div>
              </div>
            </template>
            <!-- A custom formatted footer cell for field 'name' -->
            <template #footer>
              <div class="d-flex justify-content-between">
                <span class="small">Total Users <span class="fw-bold">{{ user_count.total_user }}</span></span>
                <span class="small">Active Users <span class="fw-bold">{{ user_count.active_user }}</span></span>
                <span class="small">Inactive Users <span class="fw-bold">{{ user_count.inactive_user }}</span></span>
              </div>
            </template>
            <b-table class="small session-table-width" :fields="login_fields" :items="login_items" hover striped
              responsive small></b-table>
          </b-card>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-4 col-xl-4 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="small fw-bold">Controls Statistic</div>
                <div class="text-end">
                  <a class="text-decoration-none small akkurate-green" href="#">see more
                    <i class="fas fa-arrow-right akkurate-extra-small"></i></a>
                </div>
              </div>
            </template>
            <!-- A custom formatted footer cell for field 'name' -->
            <template #footer>
              <div class="d-flex justify-content-between">
                <span class="small">Total</span>
                <span class="small">{{ total_shares }}</span>
                <span class="small">{{ total_savings }}</span>
              </div>

            </template>
            <b-table class="small session-table-width" :fields="member_total_fields" :items="member_total_items" hover
              striped responsive small></b-table>
          </b-card>
        </b-skeleton-wrapper>
      </div>
      
      <div class="col-md-9 col-xl-9 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="small fw-bold">Activity Monitoring</div>
                <div class="text-end">
                  <a class="text-decoration-none small akkurate-green" href="#">see more
                    <i class="fas fa-arrow-right akkurate-extra-small"></i></a>
                </div>
              </div>
            </template>
            <b-table class="small session-table-width" :fields="activity_fields" :items="activity_items" hover striped
              responsive small></b-table>
          </b-card>
        </b-skeleton-wrapper>
      </div>
      <div class="ol-md-3 col-xl-3 mb-4">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-card class="card-shape home-box mb-4">
              <b-skeleton width="85%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
              <b-skeleton width="70%" height="20%"></b-skeleton>
            </b-card>
          </template>
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <div class="small fw-bold">Branch status</div>
                <div class="text-end">
                  <a class="text-decoration-none small akkurate-green" href="#">see more
                    <i class="fas fa-arrow-right akkurate-extra-small"></i></a>
                </div>
              </div>
            </template>
            <b-table class="small session-table-width" :fields="branch_fields" :items="branch_items" hover striped
              responsive small></b-table>
          </b-card>
        </b-skeleton-wrapper>
      </div>
     
      

    </div>
    <div class="row">

    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BarChart from "../Charts/BarChart.vue";
export default {
  name: "BarCharts",
  components: {
    BarChart,
  },
  created() {
    // Call your API here
    this.getDashboardApi();
  },
  data() {
    return {
      loading: false,
      labels: [],
      data: [],
      login_items: [],
      login_fields: [
        {
          key: "username",
          label: "User",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "last_login_time",
          label: "Last Login Time",
        },
      ],
      activity_items: [],
      activity_fields: [
        {
          key: "user",
          label: "User",
        },
        {
          key: "activity",
          label: "Activity",
          class:"text-truncate"
        },
        {
          key: "date_time",
          label: "Date & Time",
        },
      ],
      branch_items: [],
      branch_fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      member_total_items: [],
      member_total_fields: [
        {
          key: "state",
          label: "Total members"
        },

        {
          key: "shares",
          label: "Shares"
        },
        {
          key: "savings",
          label: "All Savings"
        }
      ],
      user_count: {
        total_user: "",
        active_user: "",
        inactive_user: "",
      },
      total_savings: 0,
      total_shares: 0,
    };
  },
  methods: {
    async getDashboardApi() {
      console.log("GEN Man API called!");
      this.loading = true;
      await ApiService.query("/dashboard/administrator").then((response) => {
          this.labels = response.data.branches?.branch_names;
          this.data = response.data.branches?.branch_total;
          this.branch_items = [response.data.branches?.branch];
          this.login_items = response.data?.recent_logs;
          this.user_count = response.data?.user_count;
          this.activity_items = response.data?.recent_activity;
          this.member_total_items = response.data.member_stat?.control_summary ?? [];
          this.total_shares = response.data.member_stat?.total_shares;
          this.total_savings = response.data.member_stat?.total_savings;
          console.log(response.data.branches?.branch);
          // console.log(this.user_count);
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.session-table-width {
  height: 190px;
}

</style>