<template>
    <div id="wrapper">
        <SidebarView :transaction="true" :transVisible="true" :withdrawalTransaction="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView ref="topbar_ref" />
                <div class="container-fluid">
                    <div class="card card-shape home-box">
                        <div class="card-header d-flex flex-row align-items-center">
                            <h6 class="m-0 fw-bold text-danger">Withdrawal</h6>
                        </div>
                        <div class="card-body py-3 withdrawal_bg" style="border-bottom: 1px solid #dc3545;">
                            <div class="home-box border border-danger p-3 bg-white">
                                <filter-form :form="form" :filterTable="filterTable"></filter-form>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="table_text_size">
                                <b-table striped bordered responsive id="deposit-table" :busy.sync="isBusy"
                                    :items="rows" :fields="fields" :tbody-tr-class="rowClass" show-empty>
                                    <template #empty="scope">
                                        <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                                    </template>
                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <!-- A custom formatted column -->
                                    <template #cell(member_fullname)="data">
                                        <span
                                            :title="data.item.customer?.first_name + ' ' + data.item.customer?.last_name">
                                            {{ data.item.customer?.first_name }} {{
                                                data.item.customer?.last_name }}
                                        </span>
                                    </template>
                                    <template #cell(actions)="data">
                                        <span class="d-flex">
                                            <b-button :key="data.item?.id + 'ViewMember'"
                                                class="btn btn-sm akkurate-warning text-white broder-0" id="show-btn"
                                                @click="showCustomerViewModal(data.item?.customer.id)">
                                                <span class="akkurate-small"> <font-awesome-icon
                                                        :icon="['fas', 'eye']" /></span>
                                            </b-button>
                                            <div class="dropdown no-arrow"
                                                v-if="data.item?.status == 'ACTIVE' || data.item?.status == 'Active'">
                                                <a title="Withdraw"
                                                    class="btn btn-sm akkurate-danger-btn text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'money-bill']" /> </span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Withdraw</div>
                                                    <cash @reloadAccounts="loadItems(); reloadTellerCoa()"
                                                        :key="data.item.id + 'Withdraw'" :data="data.item"
                                                        :transaction="'Cash Withdrawal'" :institution="institution"
                                                        :user="user"></cash>
                                                    <cheque @reloadAccounts="loadItems()"
                                                        :key="data.item.id + 'Withdraw1'" :data="data.item"
                                                        :transaction="'Cheque Withdrawal'" :institution="institution"
                                                        :user="user" :coa_subheads="coa_subheads"></cheque>
                                                    <momo @reloadAccounts="loadItems()" :key="data.item.id + 'Withdraw2'"
                                                        :data="data.item" :transaction="'Mobile Money Withdrawal'"
                                                        :institution="institution" :user="user"></momo>
                                                    <direct-debit @reloadAccounts="loadItems()"
                                                        :key="data.item.id + 'Withdraw3'" :data="data.item"
                                                        :transaction="'Direct Debit Withdrawal'"
                                                        :institution="institution" :user="user"></direct-debit>
                                                    <payment-receipt @reloadAccounts="loadItems()" :key="data.item.id + 'Withdraw3'" :data="data.item"
                                                        :transaction="'Other Receipts'" :institution="institution"
                                                        :user="user" :coa_subheads="coa_subheads"></payment-receipt>
                                                </div>
                                            </div>

                                            <div title="Transfer" class="dropdown no-arrow"
                                                v-if="data.item.status == 'ACTIVE' || data.item.status == 'Active'">
                                                <a title="Transfer"
                                                    class="btn btn-sm akkurate-blue text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'share']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Transfer</div>
                                                    <internal :key="data.item.id + 'Transfer'"
                                                        @reloadAccounts="loadItems();" :data="data.item"
                                                        :transaction="'Inter Account Transfer'"></internal>
                                                    <inter-union @reloadAccounts="loadItems()" :key="data.item.id + 'Transfer1'" :data="data.item"
                                                        :transaction="'Inter-Union Deposit'"></inter-union>
                                                    <gip @reloadAccounts="loadItems()" :key="data.item.id + 'Transfer2'" :data="data.item"
                                                        :transaction="'GIP Deposit'"></gip>
                                                </div>
                                            </div>

                                            <!-- <div class="dropdown no-arrow d-flex">
                                                <account-statement :data="props.row" :institution="institution" :user="user"></account-statement>
                                            </div>   -->
                                            <div title="Account Statement" class="dropdown no-arrow">
                                                <a title="Account Statement"
                                                    class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statements</div>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(data.item?.id + 'statement_preview')">Account
                                                        Entries</a>
                                                    <account-entries :key="data.item.id + 'Statements'"
                                                        :data="data.item" :institution="institution"
                                                        :user="user"></account-entries>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(data.item?.id + 'account_statement')">Account
                                                        Statement</a>
                                                    <account-statement :key="data.item.id + 'Statements1'"
                                                        :data="data.item" :institution="institution"
                                                        :user="user"></account-statement>

                                                </div>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>


                                <div class="d-flex justify-content-between">
                                    <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                                        :total-rows="totalRecords" :per-page="perPage"
                                        aria-controls="member-table"></b-pagination>
                                    <div class="col text-end mt-1 akkurate-dark">
                                        <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                                        <b-form-group label="Per Page" label-size="sm" class="mb-0">
                                            <b-form-select @change="selectPerPage" id="per-page-select"
                                                v-model="perPage" :options="per_page_options" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <b-modal size="xl" id="bv-modal-customer-view" hide-footer>
                        <member-view :id="member_id"></member-view>
                    </b-modal>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import FilterForm from '../form/FilterForm.vue'
import Cash from '../form/modal/Cash.vue'
import Cheque from '../form/modal/Cheque.vue'
import Momo from '../form/modal/Momo.vue'
import DirectDebit from '../form/modal/DirectDebit.vue'
import Internal from '../form/modal/Internal.vue'
import InterUnion from '../form/modal/InterUnion.vue'
import Gip from '../form/modal/Gip.vue'
import ApiService from "@/core/services/api.service";
import AccountEntries from '@/views/main/pages/member/accounts/entries/AccountEntries.vue'
import AccountStatement from '@/views/main/pages/member/accounts/statement/AccountStatement.vue'
import MemberView from '@/views/main/pages/member/view/MemberView.vue'
import PaymentReceipt from '../form/modal/PaymentReceipt.vue';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        FilterForm,
        Cash,
        Cheque,
        Momo,
        DirectDebit,
        Internal,
        InterUnion,
        Gip,
        AccountEntries,
        AccountStatement,
        MemberView,
        PaymentReceipt
    },
    data() {
        return {
            member_id: "",
            ...this.initialState(),
            user: "",
            coa_subheads: "",
            institution: "",
            currentPage: 1,
            perPage: 10,
            per_page_options: ['10', '20', '50', '100'],
            per_page_selected: "10",
            isBusy: true,
            fields: [
                {
                    label: 'Account number',
                    key: 'account_number',
                    // width: '180px',
                },
                {
                    label: 'Product',
                    key: 'account_type.name',
                    // width: '10px',
                },
                {
                    label: 'Balance',
                    key: 'account_balance',
                    // width: '150px',
                },

                {
                    label: 'Member',
                    key: 'member_fullname',
                    // width: '140px',
                },
                {
                    label: 'Mobile',
                    key: 'customer.mobile',
                    // width: '180px',
                },
                {
                    label: 'Branch',
                    key: 'branch.name',
                    // width: '180px',
                },
                {
                    label: 'Status',
                    key: 'status',
                    // width: '180px',
                },
                {
                    label: 'Actions',
                    key: 'actions',
                    // width: '440px',
                },
            ],
            rows: [],
            // serverParams: {
            //     // a map of column filters example: {name: 'john', age: '20'}
            //     columnFilters: {
            //     },
            //     sort: [
            //         {
            //             field: '', // example: 'name'
            //             type: '' // 'asc' or 'desc'
            //         }
            //     ],
            //     page: 1, // what page I want to show
            //     perPage: 20 // how many items I'm showing per page
            // },
        }
    },
    mounted() {
        this.getDepositDropdown();
        this.getInsistution();
        this.loadItems()
    },
    methods: {

        showCustomerViewModal(id) {
            this.member_id = id;
            this.$bvModal.show('bv-modal-customer-view')
        },
        async getDepositDropdown() {
            this.$Progress.start();
            await ApiService.get("/transactions/deposits/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.form.branch_option = response.data?.branches
                    this.form.acc_types_option = response.data?.account_types
                    this.user = response.data?.user;
                    this.coa_subheads = response.data?.coa_subheads;
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    this.$Progress.fail();
                    this.isLoading = false;
                })
        },
        navigatePage(index) {
            this.isBusy = true;
            this.currentPage = index;
            this.loadItems()
        },
        selectPerPage(index) {
            this.perPage = index
            this.loadItems()
        },
        reloadTellerCoa() {
            // this.$emit('reloadTellerCoa');

            this.$refs.topbar_ref.fetchUserProfile();
        },
        async loadItems(searchFilter = null) {
            var branch_id = "";
            var acc_id = "";
            if (this.form.branch != null) {
                branch_id = this.form.branch.id;
            }
            if (this.form.acc_types != null) {
                acc_id = this.form.acc_types.id;
            }
            this.$Progress.start();
            this.isLoading = true;
            this.isBusy = true
            await ApiService.post("/accounts/paginate",
                {
                    "page":  this.currentPage,
                    "per_page": this.perPage,
                    "filter": this.form.filter,
                    "branch_id": branch_id,
                    "account_type_id": acc_id,
                    "account_number": this.form.account_number,
                    "phone_number": this.form.phone,
                    "start_date": this.form.start_date,
                    "end_date": this.form.end_date,
                    "status": this.form.status //Inactive,	Active, Dormant, Closed
                })
                .then((response) => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.meta.total;
                    this.all_data = response.data.data;
                    this.isLoading = false;
                    this.$Progress.finish();
                    this.isBusy = false
                }).catch((error) => {
                    this.isBusy = false
                })
        },
        filterTable() {
            this.loadItems();
        },

        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                this.institution = response.data.data;
            }).catch((error) => {
            })
        },
        initialState() {
            return {
                totalRecords: 0,
                searchFilter: "",
                form: {
                    branch: null,
                    branch_option: [],
                    acc_types: "",
                    acc_types_option: [],
                    phone: "",
                    account_number: "",
                    start_date: "",
                    end_date: "",
                    status: "",
                    status_option: ['ACTIVE', 'INACTIVE', 'DORMANT', 'CLOSED'],
                    all_data: "",
                    filter: "",
                },
                isLoading: true,
                isSaving: false,
                add_or_edit: 'add',
            };
        },
    }
}

</script>