<template>
    <div class="row">
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="spouse_name">Name of spouse</label>
               <b-form-group
               id="spouse_name"
               label-for="spouse_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter name of spouse"
                v-model="form.spouse_name"
               ></b-form-input>
               <errors v-if="form.errors.spouse_name">
                  {{ form.errors.spouse_name[0] }}
                </errors>
               </b-form-group>
           </div>
          
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="spouse_mobile">Phone Number</label>
               <b-form-group
               id="spouse_mobile"
               label-for="spouse_mobile">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="number"
               placeholder="Enter Phone Number Eg. 0244411100"
                v-model="form.spouse_mobile"
               ></b-form-input>
               <errors v-if="form.errors.spouse_mobile">
                  {{ form.errors.spouse_mobile[0] }}
                </errors>
               </b-form-group>
           </div>


           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="spouse_email">Email</label>
               <b-form-group
               id="spouse_email"
               label-for="spouse_email">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="email"
               placeholder="Enter Email Address"
                v-model="form.spouse_email"
               ></b-form-input>
               <errors v-if="form.errors.spouse_email">
                  {{ form.errors.spouse_email[0] }}
                </errors>
               </b-form-group>
           </div>
           
           <div class="col-12 col-md-6">
               <b-form-group
                label-for="spouse_address"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="spouse_address"> Home Address </label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="spouse_address"
                  v-model="form.spouse_address"
                  placeholder="Enter Home Address..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>

    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "SpouseDetails",
  props: ['form', 'roles', 'add_or_edit'],
  components: {
    Multiselect
  },
  data() {
    return {
      status: '',
    }
  },
  methods: {
    
  }
}
</script>