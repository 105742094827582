<template>
    <div id="wrapper">
        <SidebarView :memacc="true" :memaccVisible="true" :allMemAcc="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="mx-3">
                    <div class="card card-shape home-box">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 class="m-0 fw-bold text-green">All Products</h6>
                                </div>
                                <div>
                                    <b-button class="btn btn-sm akkurate-green-btn" href="/members/account/create"
                                        variant="primary">
                                        <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Product</span>
                                    </b-button>
                                    <b-link @click="exportToExcel" class="fw-semibold float-end ms-2  akkurate-green">
                                        <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-body pb-2">
                            <div class="card card-shape home-box border border-success">
                               
                            </div>
                        </div> -->
                        <div class="card-body py-3 default_bg" style="border-bottom: 1px solid rgb(1, 134, 115);">
                            <div class="home-box border border-success p-3 bg-white">
                                <account-filter :form='form' :filterTable="filterTable"></account-filter>
                            </div>
                        </div>
                        <div class="card-body pt-3">
                            <div class="table_text_size">
                                <b-table striped bordered responsive id="account-table" :busy.sync="isBusy"
                                    :items="rows" :fields="fields" :tbody-tr-class="rowClass" show-empty>
                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template #empty="scope">
                                        <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                                    </template>
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template #cell(status)="data">
                                        <span :class="statusBadge(data.item.status)">{{ data.item.status }}</span>
                                    </template>
                                    <!-- A custom formatted column -->
                                    <template #cell(customer)="data">
                                        <div>{{ data.item.customer.first_name }} {{ data.item.customer.last_name }}
                                        </div>
                                    </template>
                                    <template #cell(actions)="data">
                                        <span class="d-flex">
                                            <account-view :data="data.item"></account-view>
                                            <a title="Edit" class="btn btn-sm akkurate-blue text-white border-0"
                                                :href="`/members/account/${data.item.account_number}/edit`">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'edit']" /></span>
                                            </a>

                                            <div title="Account Statement" class="dropdown no-arrow">
                                                <a title="Account Statement"
                                                    class="btn btn-sm akkurate-gray-btn text-white custom-pointer border-0 dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statments</div>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(data.item?.id + 'statement_preview')">Account
                                                        Entries</a>
                                                    <account-entries :data="data.item"
                                                        :institution="institution"></account-entries>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(data.item?.id + 'account_statement')">Account
                                                        Statement</a>
                                                    <account-statement :data="data.item"
                                                        :institution="institution"></account-statement>

                                                </div>
                                            </div>

                                            <a title="Delete" class="btn btn-sm akkurate-danger-btn border-0 text-white custom-pointer"
                                                @click="confirmDeleteUser(props.row)">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'trash']" /></span>
                                            </a>
                                            <!-- <a title="Account Statement" class="btn btn-sm akkurate-gray-btn text-white" v-b-modal="String(props.row?.id + 'account_statement')">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></span>
                                                </a> -->
                                            <!-- <account-statement :data="props.row" :institution="institution"></account-statement> -->
                                        </span>
                                    </template>
                                </b-table>
                                <div class="d-flex justify-content-between">
                                    <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage"
                                        :total-rows="totalRecords" :per-page="perPage"
                                        aria-controls="member-table"></b-pagination>
                                    <div class="col text-end mt-1">
                                        <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                                        <b-form-group label="Per Page" label-size="sm" class="mb-0">
                                            <b-form-select @change="selectPerPage" id="per-page-select"
                                                v-model="perPage" :options="per_page_options" size="sm"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import AccountFilter from '../forms/AccountFilter.vue'
import ApiService from "@/core/services/api.service";
import AccountStatement from '../statement/AccountStatement.vue';
import AccountEntries from '../entries/AccountEntries.vue';
import AccountView from '../view/AccountView.vue'
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        AccountFilter,
        AccountStatement,
        AccountEntries,
        AccountView
    },
    data() {
        return {
            ...this.initialState(),
            totalRecords: 0,
            searchFilter: "",
            isLoading: false,
            isSaving: false,
            add_or_edit: 'add',
            institution: "",
            isBusy: true,
            currentPage: 1,
            perPage: 10,
            per_page_options: ['10', '20', '50', '100'],
            per_page_selected: "10",
            fields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'account_type.name',
                    label: 'Account_type'
                },
                {
                    key: 'account_category',
                    label: 'Account_Category',

                },
                {
                    key: 'account_balance',
                    label: 'Balance(GHS)',

                },
                {
                    key: 'account_number',
                    label: 'Account_Number',

                },
                {
                    key: 'customer',
                    label: 'Member_Name',

                },
                {
                    key: 'customer.external_code',
                    label: 'Member_(Ext.Code)',

                },
                {
                    key: 'customer.mobile',
                    label: 'Member_Mobile',

                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'created_at',
                    label: 'Created',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
            rows: [],
        }
    },
    mounted() {
        this.getAccountDropdown();
        this.loadItems();
        this.getInsistution();
    },
    methods: {
        statusBadge(item) {
            if (item === 'ACTIVE') {
                return 'badge akkurate-green-bg'
            } else if (item === 'CLOSED') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'DORMANT') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'PENDING') {
                return 'badge akkurate-warning-bg'
            } else {
                return 'badge akkurate-warning-bg'
            }
        },
        rowClass() {
            return 'custom-row-height'
        },
        async getAccountDropdown() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/accounts/create/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.form.branch_option = response?.data?.branches
                    this.form.acc_types_option = response?.data?.account_types
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    // console.log(error);
                })
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
       
        async loadItems() {
            this.isBusy = true;  // Set isBusy to true when data is being loaded

            var branch_id = this.form.branch ? this.form.branch.id : '';
            var acc_id = this.form.acc_types ? this.form.acc_types.id : '';

            this.$Progress.start();  // Start the progress bar

            try {
                const response = await ApiService.post("/accounts/paginate", {
                    "page": this.currentPage,
                    "per_page": this.perPage,
                    "filter": this.form.filter,
                    "branch_id": branch_id,
                    "account_type_id": acc_id,
                    "account_number": this.form.account_number,
                    "start_date": this.form.start_date,
                    "end_date": this.form.end_date,
                    "status": this.form.status // Active, Inactive, etc.
                });

                // Handle response and update table data
                this.isBusy = false;  // Set isBusy to false when the data is loaded
                this.$Progress.finish();  // Finish the progress bar

                this.totalRecords = response.data.meta.total;
                this.rows = response.data.data;  // Return data to populate the table
            } catch (error) {
                console.error(error);
                this.isBusy = false;  // Set isBusy to false in case of an error
                this.$Progress.finish();  // Finish the progress bar even if there's an error

                return [];  // Return an empty array in case of error to prevent the table from breaking
            }
        },
        navigatePage(index) {
            this.isBusy = true;
            this.currentPage = index;
            this.loadItems()
        },
        selectPerPage(index) {
            this.perPage = index
            this.loadItems()
        },
        filterTable() {
            console.log(this.form.filter)
            this.loadItems();
        },
        // async loadItems(ctx) {
        //     console.log("Here");
        //     var branch_id = "";
        //     var acc_id = "";
        //     if (this.form.branch != null) {
        //         branch_id = this.form.branch.id;
        //     }
        //     if (this.form.acc_types != null) {
        //         acc_id = this.form.acc_types.id;
        //     }
        //     this.$Progress.start();
        //     this.isLoading = true;
        //     await ApiService.post("/accounts/paginate",
        //         {
        //             "page": 1,
        //             "per_page": 10,
        //             "filter": this.form.filter,
        //             "branch_id": branch_id,
        //             "account_type_id": acc_id,
        //             "account_number": this.form.account_number,
        //             "start_date": this.form.start_date,
        //             "end_date": this.form.end_date,
        //             "status": this.form.status //Inactive,	Active, Dormant, Closed
        //         }).then((response) => {
        //             // this.rows = ;
        //             this.isLoading = false;
        //             this.$Progress.finish();
        //             console.log(response.data.data);
        //             return(response.data.data)

        //             // this.totalRecords = response.data.meta.total;

        //         }).catch((error) => {
        //             console.log(error);
        //         })
        // },
        exportToExcel() {
            const workbook = XLSX.utils.book_new();

            // Get the table data
            // const tableData = this.$refs.memberTable.filteredRows[0].children;
            const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
                return {
                    account_type: index.account_type?.name,
                    account_balance: index.account_balance,
                    account_number: index.account_number,
                    member_name: index.customer.first_name + '' + index.customer.last_name,
                    mobile: index.customer.mobile,
                    status: index.status,
                    created_at: index.created_at,
                }
            });
            // console.log(tableData);

            // Convert the table data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate the Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx'
            });

            // Save the Excel file
            FileSaver.saveAs(
                new Blob([excelBuffer], { type: 'application/octet-stream' }),
                'table_data.xlsx'
            );
        },

        initialState() {
            return {
                form: {
                    branch: {},
                    branch_option: [],
                    acc_types: "",
                    acc_types_option: [],
                    filter: "",
                    account_number: "",
                    start_date: "",
                    end_date: "",
                    status: "",
                    status_option: ['ACTIVE', 'INACTIVE', 'DORMANT', 'CLOSED'],
                },

            };
        },
    }
}

</script>

<style>
/* Customize the pagination page number color */
/* .pagination {
    --bs-pagination-active-bg: rgb(1, 134, 115) !important;
    --bs-pagination-active-border-color: rgb(1, 134, 115) !important;
    --bs-pagination-focus-box-shadow: none !important;
}

.page-link {
    color: rgb(1, 134, 115) !important;
}

.page-link:hover {
    color: rgb(1, 134, 115) !important;
}

.page-link.active,
.active>.page-link {
    color: #fff !important;
} */
</style>
