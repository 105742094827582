<template>
  <div id="wrapper">
    <Sidebar :mobile="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Topbar />
        <MobileMenu />
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <h4 class="akkurate-danger">Withdrawals</h4>
            </div>
            <div class="col-6 text-end">
              <h4 class="akkurate-dark fw-bold">GHS {{ account_balance }}</h4>
            </div>
            <hr />
            <form @submit.prevent="searchMember">
              <div class="row">
                <div class="col">
                  <label class="akkurate-dark small" for="search">Search</label>
                  <b-form-group id="search" prepend="$" label-for="search">
                    <b-input-group class="mt-2">
                      <template #prepend>
                        <b-input-group-text class="py-0 px-2 h-100 bg-white">
                          <font-awesome-icon class="icon_color" :icon="['fas', 'search']" />
                        </b-input-group-text>
                      </template>
                      <b-form-input class="field-container fields border-start-0" type="text" v-model="filter.search"
                        placeholder="Search by old code, customer code ..."></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col">
                  <label class="akkurate-dark small" for="account_number">Account number</label>
                  <b-form-group id="account_number" label-for="account_number" class="akkurate-dark">
                    <b-form-input id="account_number" class="mt-2 field-container fields" type="text"
                      v-model="filter.account_number" placeholder="Search by Account number">
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="col-2 mt-auto">
                  <div class="d-grid gap-2">
                    <b-button type="submit" :disabled="is_searching"
                      class="fw-semibold akkurate-auth-size akkurate-green-btn">
                      <!-- {{ search_btn }} -->
                      <!-- <span
                      class="input-group-text py-0 px-2 "
                      id="basic-addon1"
                      ><font-awesome-icon
                        :icon="['fas', 'search']"
                    /></span> -->
                      <span class="small"><font-awesome-icon :icon="['fas', 'search']" /> </span>
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
            <form @submit.prevent="saveWithdrawal">
              <div class="row mt-3">
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="account_number">Account Number <star></star></label>
                  <b-form-group id="account_number" label-for="account_number">
                    <multiselect class="mt-2 field-container" v-model="form.account_number" :options="accounts_options"
                      label="name" selectLabel="" @select="accountSelected" deselectLabel="">
                    </multiselect>
                    <errors v-if="form.errors.account_number">
                      {{ form.errors.account_number[0] }}
                    </errors>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="member_name">Member Name <star></star></label>
                  <b-form-group id="member_name" label-for="member_name">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="field.member_name"
                      placeholder="Enter Member Name" disabled required>
                    </b-form-input>
                    <errors v-if="field.errors.member_name">
                      {{ field.errors.member_name[0] }}
                    </errors>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="telephone">Tel <star></star></label>
                  <b-form-group id="telephone" label-for="telephone">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="field.telephone"
                      placeholder="Enter Telephone" disabled required>
                    </b-form-input>
                    <errors v-if="field.errors.telephone">
                      {{ field.errors.telephone[0] }}
                    </errors>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="product">Product <star></star></label>
                  <b-form-group id="product" label-for="product">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="field.product"
                      placeholder="Enter Product" disabled required>
                    </b-form-input>
                    <errors v-if="field.errors.product">
                      {{ field.errors.product[0] }}
                    </errors>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="trans_amount">Amount <star></star></label>
                  <b-form-group id="trans_amount" label-for="trans_amount">
                    <b-form-input class="mb-3 mt-2 field-container fields" type="text" v-model="form.trans_amount"
                      placeholder="Enter Amount" required>
                    </b-form-input>
                    <errors v-if="form.errors.trans_amount">
                      {{ form.errors.trans_amount[0] }}
                    </errors>
                  </b-form-group>
                </div>
               
                <div class="col-12 col-md-6 mb-3">
                  <label class="akkurate-dark small" for="notes">Notes <star></star></label>
                  <b-form-textarea name="notes" class="field-container fields mt-2" id="notes" v-model="form.notes"
                    placeholder="Enter notes..." rows="3" max-rows="6"></b-form-textarea>
                  <errors v-if="field.errors.notes">
                    {{ field.errors.notes[0] }}
                  </errors>
                </div>
                <div class="col-12 col-md-6 mb-4">
                  <div class="d-grid gap-2">
                    <b-button type="submit" :disabled="isSaving"
                      class="fw-semibold akkurate-auth-size akkurate-danger-btn">
                      <font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Save
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    <cash-withdrawal ref="showCashWithdrawal" :report_data="report_data">
    </cash-withdrawal>
  </div>
</template>
<script>
import Topbar from "@/views/main/components/Topbar.vue";
import Sidebar from "@/views/main/components/Sidebar.vue";
import MobileMenu from "../../../components/MobileMenu.vue";
import CashWithdrawal from "../reports/cash/CashWithdrawal.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
  components: {
    MobileMenu,
    Topbar,
    Sidebar,
    CashWithdrawal,
    Multiselect,
  },
  data() {
    return {
      ...this.initialState(),
      field: {
        member_name: "",
        telephone: "",
        product: "",
        errors: [],
      },
      filter: {
        search: "",
        account_number: "",
      },
      is_searching: false,
      search_btn: "Search",
      user: "",
      mobile_menu: false,
      isSaving: false,
      transaction: "Cash Withdrawal",
      report_data: [],
      institution: {},
      accounts_options: [],
      account_balance: 0,
      user: "",
    };
  },
  mounted() {
    this.getDepositDropdown();
    this.getInsistution();
  },
  computed: {
    getTeller() {
      let first_name = this.user?.first_name != null ? this.user?.first_name : '';
      let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
      let surname = this.user?.surname != null ? this.user?.surname : '';
      return first_name + ' ' + middle_name + ' ' + surname;
    },
  },
  methods: {
    mobileToggle() {
      console.log("mobile-togle");
      this.mobile_menu = !this.mobile_menu;
    },
    async getDepositDropdown() {
      // this.$Progress.start();
      await ApiService.get("/transactions/deposits/dropdown")
        .then((response) => {
          // console.log(response);
          // this.form.branch_option = response.data?.branches;
          // this.form.acc_types_option = response.data?.account_types;
          this.user = response.data?.user;
          console.log(response);
          // this.coa_subheads = response.data?.coa_subheads;
          // this.isLoading = false;
          // this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.isLoading = false;
        });
    },

    async getInsistution() {
      await ApiService.get("/settings/institution")
        .then((response) => {
          // console.log(response);
          this.institution = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    accountSelected(event) {
      this.field.product = event?.product_type
      this.field.member_name = event?.customer_name
      this.field.telephone = event?.customer_mobile
      this.account_balance = event.account_balance

      // const value = this.accounts_options.find(event.name);
    },
    async searchMember() {
      this.is_searching = true;
      this.search_btn = "processing...";
      await ApiService.post("/accounts/paginate", {
        page: 1,
        per_page: 10,
        filter: this.filter.search.toString(),
        branch_id: "",
        account_type_id: "",
        account_number: this.filter.account_number.toString(),
        start_date: "",
        end_date: "",
        status: "", //Inactive,	Active, Dormant, Closed
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.field.member_name = response.data?.data[0]?.customer?.first_name + " " + response.data?.data[0]?.customer?.last_name;
            this.field.telephone = response.data?.data[0]?.customer.mobile;
            this.accounts_options = response.data.data.map((index) => {
              return {
                name: index.account_number + " - " + index?.account_type?.name + " - " + index?.customer?.first_name + " " + index?.customer?.last_name,
                product_type: index?.account_type?.name,
                account_number: index.account_number,
                customer_name: index?.customer?.first_name + " " + index?.customer?.last_name,
                customer_mobile: index?.customer?.mobile,
                account_balance: index?.account_balance
              };
            });
            // this.form.account_number = response.data?.data[0]?.account_number;
            // this.field.product = response.data?.data[0]?.account_type?.name;
          } else {
            swal.fire({
              icon: "error",
              title: "Not Found",
              text: "This account was not found please try again!!!",
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
          this.is_searching = false;
          this.search_btn = "Search";
        })
        .catch((error) => {
          this.is_searching = false;
          this.search_btn = "Search";
        });
    },
    async saveWithdrawal() {
      this.$Progress.start();
      this.isSaving = true;
      this.form.denominations = [
        {
          denom_amount: this.form.trans_amount,
          denom_frequency: 1,
          sub_total: this.form.trans_amount,
        },
      ];
      this.form.account_number = this.form.account_number.account_number;
      await ApiService.post("/transactions/withdrawals/store", this.form)
        .then((response) => {
          // this.$emit("reloadAccounts");
          this.$Progress.finish();
          this.isSaving = false;
          this.form = this.initialState().form;
          this.accounts_options = [];
          this.field.member_name = "";
          this.field.telephone = ""
          this.field.product = ""
          this.field.errors = []
          this.report_data = {};

          swal
            .fire({
              title: response.data.transaction.trans_status,
              // title: "Successfully Saved",
              text: "CODE : " + response.data.transaction.code,
              // text: 'CODE : 12558896555',
              confirmButtonColor: "#018673",
              icon: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCancelButton: true, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              cancelButtonText: "Print", // Text for the custom button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
                cancelButton: "btn akkurate-ash modal-btn-width text-white", // Custom class for the custom button
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$bvModal.hide(this.form.account_number + this.transaction);
              } else if (result.isDismissed) {
                this.$bvModal.hide(this.form.account_number + this.transaction);
                this.$refs.showCashWithdrawal.showModal();
                let data = response.data?.transaction;
                let account = response.data?.account;
                this.report_data = {
                  'trans_date': data.trans_date,
                  'institution': this.institution,
                  'account_number': account.account_number,
                  'fullname': account.customer.first_name + '' + account.customer.last_name,
                  'transaction_mode': data.transaction_mode,
                  'transaction_type': data.transaction_type,
                  'amount': data.trans_amount,
                  'cheque_no': "not avaliable",
                  'bank_name': "not avaliable",
                  'branch_name': this.user.branch?.name,
                  'narration': data.narration,
                  'institution': this.institution,
                  'receipt_no': data.id,
                  'teller': this.getTeller,
                  'denomination': this.form.denominations ?? [],
                  'mobile': account.customer?.mobile,
                  'network': data.network,
                  'external_reference': data.external_reference,
                  'momo_reference': data.momo_reference,
                }
              }
            });
        })
        .catch((error) => {
          this.$Progress.finish();
          this.isSaving = false;
          if (error.response.status) {
            swal.fire({
              icon: "error",
              title: "Unable to process request",
              text: error.response?.data?.message,
              showCancelButton: false, // Show the "Cancel" button
              confirmButtonText: "OK", // Customize the text for the "OK" button
              buttonsStyling: false, // Disable SweetAlert2 styling for buttons
              customClass: {
                confirmButton:
                  "btn akkurate-green-btn modal-btn-width text-white me-2", // Custom class for the "OK" button
              },
              // footer: '<a href="#">Why do I have this issue?</a>'
            });
          }
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
          this.$Progress.fail();
          this.isSaving = false;
        });
    },
    initialState() {
      return {
        form: {
          account_number: "",
          trans_amount: "",
          source: "Web",
          network: "",
          narration: "",
          transaction_mode: "Cash",
          external_reference: null,
          trans_date: new Date().toISOString().split('T')[0],
          momo_reference: "",
          currency: "GHS",
          denominations: [
            { denom_amount: "1", denom_frequency: "", sub_total: "" },
          ],
          errors: [],
          notes: "",
          denom_errors: "",
        },
      }

    }
  },
};
</script>

<style scoped>
.input-group>.custom-group {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.icon_color {
  color: #c1c2c4;
}
</style>