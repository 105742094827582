<template>
    <div>
        <a title="Reject" v-if="data.status == 'PENDING'" class="btn btn-sm akkurate-danger-btn custom-pointer text-white" v-b-modal="data.code">
        <span class="akkurate-small"><font-awesome-icon  :icon="['fas', 'ban']" /></span>
       </a>

       <b-modal
        :id="data.code"
        title="Reject Journal"
        ref="modal"
        size="xs"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        >
           <form ref="form" @submit.stop.prevent="handleSubmit" >
               <div class="row">
                <div class="col-12 col-md-12">
                <b-form-group
                label-for="reason"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="reason">Reason <star></star></label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="reason"
                  v-validate="{ required: true }"
                  :state="validateState('reason')"
                  name="reason"
                  v-model="form.reason"
                  placeholder="Enter Reason..."
                  required
                ></b-form-textarea>
              </b-form-group>
              
           </div>
                   
               </div>
           </form>
          <template #modal-footer="{ ok }">
               <div class="w-100">
               <!-- begin loader -->
               <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
               <!-- end loader -->
               <!-- Emulate built in modal footer ok and cancel button actions -->
               <b-button @click="$bvModal.hide(data.code)" type="button" class="akkurate-grey-btn btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
               <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right border-0"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save</b-button>     
               </div>
           </template>
           
       </b-modal>
    </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
    name: "Reject",
    props: ['data'],
    data() {
        return {
            form: {
                reason: "",
                errors: []
            }

        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.form.reason = ''
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }
                this.$Progress.start();
                this.isSaving = true;
                ApiService.post(`/journals/reject/${this.data.id}`, this.form)
                    .then((response) => {
                        this.isSaving = false;
                        this.$Progress.finish();
                        toast.fire({
                            icon: "success",
                            title: response.data.message,
                        });
                        

                        this.resetModal();
                        this.isSaving = false;
                        this.$emit('rejected', response.data);
                        this.$bvModal.hide(this.data.code);
                    }).catch((error) => {
                        this.isSaving = false;
                        this.$Progress.fail();
                        // console.log(error);
                        if (error.response.data) {
                            this.form.errors = error.response.data.message;
                        }

                        //error message
                        if (error.response.data.message) {
                            this.form.message = error.response.data.message;
                        }
                        // console.log(error.response.data)

                    }).finally((error) => {

                    })


            });
            // Hide the modal manually

        }
    }
}
</script>