<template>
    <div>
        <errors v-if="error_message" style="text-align: center;">
            {{ error_message }}
        </errors>
        <form @submit.prevent="saveLoanTerm()">
            <div class="card">
                <div class="card-header">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="m-0 fw-bold text-green">Loan Term</h6>
                        <b-button class="btn btn-sm akkurate-green-btn" type="submit" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-plus"></i> {{ save }}</span>
                        </b-button>
                    </div>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_method">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_method">Interest Method <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_method" :options="interest_method_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" @select="interestMethod"
                                    class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.interest_method">
                                    {{ form.errors.interest_method[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="amount">
                                <template #label>
                                    <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                                </template>
                                <b-form-input id="amount" name="amount" disabled class="mt-2 field-container fields"
                                    type="text" v-model="form.principal_amount" readonly></b-form-input>
                                <errors v-if="form.errors.principal_amount">
                                    {{ form.errors.principal_amount[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate">Interest Rate % <star></star>
                                    </label>
                                </template>
                                <b-form-input id="interest_rate" name="interest_rate"
                                    class="mt-2 field-container fields" type="text" step="any"
                                    v-model="form.interest_rate"></b-form-input>
                                <errors v-if="form.errors.interest_rate">
                                    {{ form.errors.interest_rate[0] }}
                                </errors>

                            </b-form-group>
                        </div>


                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_rate_frequency">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_rate_frequency">Interest Rate
                                        Frequency
                                        <star></star>
                                    </label>
                                </template>
                                <multiselect v-model="form.interest_rate_frequency"
                                    :options="interest_rate_frequency_options" selectLabel="" deselectLabel=""
                                    :show-labels="false" class="mt-2 field-container" placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.interest_rate_frequency">
                                    {{ form.errors.interest_rate_frequency[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="interest_start_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="interest_start_date">Interest Start Date
                                        <star>
                                        </star>
                                    </label>
                                </template>
                                <b-form-input id="interest_start_date" name="interest_start_date"
                                    class="mt-2 field-container fields" type="date"
                                    v-model="form.interest_start_date"></b-form-input>
                                <errors v-if="form.errors.interest_start_date">
                                    {{ form.errors.interest_start_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <label class="akkurate-dark small">Tenure <star></star></label>
                            <b-form-group>
                                <b-form-input id="tenor" name="tenor" class="mt-2 field-container fields" type="number"
                                    v-model="form.tenor"></b-form-input>
                                <errors v-if="form.errors.tenor">
                                    {{ form.errors.tenor[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="tenor_interval">
                                <template #label>
                                    <label class="akkurate-dark small" for="tenor_interval">Tenure Interval <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.tenor_interval" :options="tenor_interval_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.tenor_interval">
                                    {{ form.errors.tenor_interval[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="repayment_cycle">
                                <template #label>
                                    <label class="akkurate-dark small" for="repayment_cycle">Repayment Cycle <star>
                                        </star>
                                    </label>
                                </template>
                                <multiselect v-model="form.repayment_cycle" :options="repayment_cycle_options"
                                    selectLabel="" deselectLabel="" :show-labels="false" class="mt-2 field-container"
                                    placeholder="Pick a value">
                                </multiselect>
                                <errors v-if="form.errors.repayment_cycle">
                                    {{ form.errors.repayment_cycle[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="maturity_date">
                                <template #label>
                                    <label class="akkurate-dark small" for="maturity_date">Maturity Date</label>
                                </template>
                                <b-form-input id="maturity_date" name="maturity_date" v-model="form.maturity_date"
                                    class="mt-2 field-container fields" type="date" disabled></b-form-input>
                                <errors v-if="form.errors.maturity_date">
                                    {{ form.errors.maturity_date[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="penalty_rate">
                                <template #label>
                                    <label class="akkurate-dark small" for="penalty_rate">Penalty Rate <star></star>
                                    </label>
                                </template>
                                <b-form-input id="penalty_rate" name="penalty_rate" class="mt-2 field-container fields"
                                    type="number" v-model="form.penalty_rate"></b-form-input>
                                <errors v-if="form.errors.penalty_rate">
                                    {{ form.errors.penalty_rate[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4 mb-2">
                            <b-form-group label-for="reason">
                                <template #label>
                                    <label class="akkurate-dark small" for="reason">Reason <star></star></label>
                                </template>
                                <b-form-textarea class="mt-2 fields" id="reason" v-model="form.reason"
                                    placeholder="Enter Reason..."></b-form-textarea>
                                <errors v-if="form.errors.reason">
                                    {{ form.errors.reason[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-12 col-md-4">
                            <label class="akkurate-dark small" for="purpose">Purpose</label>
                            <b-form-group id="type" label-for="purpose" class="akkurate-dark">
                                <multiselect class="field-container mt-2 mb-3" v-model="form.purpose" name="purpose"
                                    placeholder="Search or select a Purpose" :options="purpose_options">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <label class="akkurate-dark small" for="lpp_type">LPP Type</label>
                            <b-form-group id="lpp_type" name="lpp_type" label-for="lpp_type"
                                :description="lpp_description">
                                <multiselect id="lpp_type" name="lpp_type" class="mt-2 field-container"
                                    @select="selectLPP" label="name" v-model="form.lpp_type"
                                    :options="lpp_type_options">
                                </multiselect>
                            </b-form-group>
                            <b-form-invalid-feedback>
                                {{ veeErrors.first('lpp_type') }}
                            </b-form-invalid-feedback>
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <b-form-group label-for="lpp_value" :description="interest_expected_description">
                                <template #label>
                                    <label class="akkurate-dark small" for="lpp_value">LPP Value <star></star></label>
                                </template>
                                <b-form-input id="lpp_value" name="lpp_value" class="mt-2 field-container fields"
                                    type="text" v-model="form.lpp_value" disabled></b-form-input>
                                <errors v-if="form.errors.lpp_value">
                                    {{ form.errors.lpp_value[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <b-form-group label-for="lpf_value" :description="loan_lpf_description">
                                <template #label>
                                    <label class="akkurate-dark small" for="lpf_value">LPF Value <star></star></label>
                                </template>
                                <b-form-input id="lpf_value" name="lpf_value" class="mt-2 field-container fields"
                                    type="text" v-model="form.lpf_value" disabled></b-form-input>
                                <errors v-if="form.errors.lpf_value">
                                    {{ form.errors.lpf_value[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <b-form-group label-for="other_charges_description">
                                <template #label>
                                    <label class="akkurate-dark small" for="other_charges_description">Other Charges
                                        (Description)</label>
                                </template>
                                <b-form-input id="other_charges_description" name="other_charges_description"
                                    class="mt-2 field-container fields" type="text"
                                    v-model="form.other_charges_description"></b-form-input>
                                <errors v-if="form.errors.other_charges_description">
                                    {{ form.errors.other_charges_description[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <b-form-group label-for="other_charges">
                                <template #label>
                                    <label class="akkurate-dark small" for="other_charges">Other Charges (Fee)</label>
                                </template>
                                <b-form-input id="other_charges" name="other_charges"
                                    class="mt-2 field-container fields" type="text"
                                    v-model="form.other_charges"></b-form-input>
                                <errors v-if="form.errors.other_charges">
                                    {{ form.errors.other_charges[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                    </div>

                </div>
            </div>
        </form>


    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect
    },
    props: {
        account_info: Object,
        product_info: Object,
        guarantors: Array,
        loan_premium_plan: "",
        loan_processing_fee: "",
        principal: "",
        show_application: ""
    },
    watch: {
        principal(newValue) {
            this.form.principal_amount = newValue;
        }
    },
    // props: ['account_info', 'product_info', 'guarantors', 'loan_premium_plan', 'loan_processing_fee','principal'],
    data() {
        return {
            ...this.initialState(),
            interest_method_options: ["straight-line", "reducing-balance", "hybrid"],
            interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            fixed_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            variable_interest_rate_frequency_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            variable_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            tenor_interval_options: ['Days', 'Weeks', 'Months', 'Years'],
            repayment_cycle_options: ['Daily', 'Weekly', 'Monthly', 'Annually'],
            purpose_options: ['Education', 'Agriculture', 'Trading', 'Realestate', 'Car', 'Health', 'Marriage', 'Funeral', 'Litigation', 'Other Purpose'],
            straight_line: false,
            reducing_balance: false,
            hybrid: true,
            save: "Forward for approval",
            isSaving: false,
            error_message: "",
            lpp_type_options: [
                { id: 'cua_standard', name: 'CUA STANDARD' },
                { id: 'incure_interest', name: 'INCURE INTEREST' },
                { id: 'custom', name: 'CUSTOM' }
            ],
            lpp_description: "",
            interest_expected_description: "",
            loan_lpf_description: "",
            loan_calulator: {
                total_payment: "",
                total_interest: "",
                interest_rate: "",
                interest_rate_per_interval: "",
                daily_interest_amount: "",
                daily_interest: "",
                principal_amount: this.principal,
                number_of_payments: "",
                payment: "",
            }
        }
    },
    methods: {
        saveLoanTerm() {
            this.isSaving = true;
            this.$Progress.start();
            this.save = "Forwarding..."
            this.form.account_number = this.account_info.account_number;
            this.form.loanproduct_id = this.product_info.id;
            this.form.guarantors = this.guarantors;
            ApiService.post('/loans', this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    this.save = "Forward for approval"
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    let loan_id = response.data?.data?.id;
                    this.$emit('loan_no', loan_id);
                    this.$emit('loanSaved');
                    this.form = this.initialState().form;
                    this.error_message = "";

                }).catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.save = "Forward for approval"
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                        // console.log(error)
                    }

                    if (error.response.data.message) {
                        this.error_message = error.response.data.message;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    account_number: "",
                    loanproduct_id: "",
                    interest_method: "straight-line", // ['straight_line', 'reducing-balance', 'hybrid']
                    principal_amount: this.pr,
                    interest_rate: "",
                    interest_rate_frequency: "", // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    interest_start_date: "",
                    lpp_type: { id: '', name: '' },
                    lpp_value: "",
                    lpf_value: "",
                    // expected_interest: "",
                    // fixed_interest_rate: "",
                    // fixed_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
                    // fixed_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    // fixed_duration: "",
                    // variable_interest_rate: "",
                    // variable_interest_rate_frequency: "", //['Daily', 'Weekly', 'Monthly', 'Annually']
                    // variable_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    // variable_duration: "",
                    tenor: "", // integer 
                    tenor_interval: "", //['Days', 'Weeks', 'Months', 'Years']
                    repayment_cycle: "", // ['Daily', 'Weekly', 'Monthly', 'Annually']
                    maturity_date: "",
                    penalty_rate: "", // Penalty fee
                    reason: "",
                    credit_officer: 1, // "User ID"
                    recovery_officer: 2, // "User ID"
                    // insurance: "", // integer
                    // supervisor_approval_limit: "", // integer
                    // branch_approval_limit: "", // integer
                    errors: []
                }

            }
        },
        interestMethod(value) {
            // console.log(value);
            // switch (value) {
            //     case 'straight-line':
            //         this.straight_line = false;
            //         this.hybrid = true;
            //         break;
            //     case 'reducing-balance':
            //         this.straight_line = true;
            //         this.hybrid = true;
            //         break;
            //     case 'hybrid':
            //         this.straight_line = true;
            //         this.hybrid = false;
            //         break;
            // }
        },
        selectLPP(value) {
            let rate = "";
            this.form.lpp_value = "";
            // switch (this.form.interest_rate_frequency) {
            //     case 'Daily':
            //         if (this.form.tenor_interval == 'Days') {
            //             rate = this.form.interest_rate;
            //         } else if (this.form.tenor_interval == 'Weeks') {
            //             rate = this.form.interest_rate * 7;
            //         } else if (this.form.tenor_interval == 'Months') {
            //             rate = this.form.interest_rate * 30.4375;
            //         } else if (this.form.tenor_interval == 'Years') {
            //             rate = this.form.interest_rate * 365.25;
            //         }
            //         break;
            //     case 'Weekly':
            //         if (this.form.tenor_interval == 'Days') {
            //             rate = this.form.interest_rate / 7;
            //         } else if (this.form.tenor_interval == 'Weeks') {
            //             rate = this.form.interest_rate;
            //         } else if (this.form.tenor_interval == 'Months') {
            //             rate = this.form.interest_rate * 4.348;
            //         } else if (this.form.tenor_interval == 'Years') {
            //             rate = this.form.interest_rate * 52.1785;
            //         }
            //         break;
            //     case 'Monthly':
            //         if (this.form.tenor_interval == 'Days') {
            //             rate = this.form.interest_rate / 30.4375;
            //         } else if (this.form.tenor_interval == 'Weeks') {
            //             rate = this.form.interest_rate / 4.348;
            //         } else if (this.form.tenor_interval == 'Months') {
            //             rate = this.form.interest_rate
            //         } else if (this.form.tenor_interval == 'Years') {
            //             rate = this.form.interest_rate * 12;
            //         }
            //         break;
            //     case 'Annually':
            //         if (this.form.tenor_interval == 'Days') {
            //             rate = this.form.interest_rate / 365.25;
            //         } else if (this.form.tenor_interval == 'Weeks') {
            //             rate = this.form.interest_rate / 52.1785;
            //         } else if (this.form.tenor_interval == 'Months') {
            //             rate = this.form.interest_rate / 12
            //         } else if (this.form.tenor_interval == 'Years') {
            //             rate = this.form.interest_rate;
            //         }
            //         break;
            // }

            // console.log(value)
            this.calculateLoans();
            let principal = this.principal ?? 0;
            // this.form.principal_amount = this.principal
            // let no_of_payment = this.lppNoPayment() ?? 0;
            // let tenor_pecent = rate / 100;
            // let expected_interest = principal * tenor_pecent * this.form.tenor;
            let principal_interest = Number(this.principal) + Number(this.loan_calulator.total_interest);
            // console.log(principal_interest)
            if (this.form?.principal_amount != 0 && this.form?.tenor != "" && this.form?.tenor_interval != "") {
                switch (value.id) {
                    case 'cua_standard':
                        this.lpp_description = "Principal * Nò of Month/1000"
                        this.interest_expected_description = ""
                        // console.log(this.lppMonths())
                        this.form.lpp_value = principal * (this.loan_calulator?.number_of_payments / 1000);
                        break;
                    case 'incure_interest':
                        // console.log('Tenor Pecentage', tenor_pecent);
                        // console.log('Expected Interest', expected_interest);
                        this.interest_expected_description = "Expected Interest : " + this.loan_calulator.total_interest;
                        this.lpp_description = "Principal + Interest * Nò of Month/1000"
                        this.form.lpp_value = principal_interest * (this.loan_calulator?.number_of_payments / 1000);
                        break;
                    case 'custom':
                        this.lpp_description = "Principal * loan_premium_plan %"
                        this.form.lpp_value = principal * (this.loan_premium_plan / 100)
                        this.interest_expected_description = "Loan Premium plan : " + this.loan_premium_plan + "%"
                        break;
                }
                this.form.lpf_value = principal * (this.loan_processing_fee / 100);
                this.loan_lpf_description = "Loan Processing fees : " + this.loan_processing_fee + "%"

            }

        },
        async calculateLoans() {
            console.log(principal_amount);
            this.isLoading = true
            this.isCalculating = true,
                this.calculate = "Processing..."

            this.term_rows = [];
            this.repayment_rows = [];

            await ApiService.post('/loans/loanCalculator', this.form)
                .then(response => {
                    // this.term_rows.push(response.data.data);
                    // response.data?.data?.amortization_schedule.map((index) => {
                    //     this.repayment_rows.push(index);
                    // })
                    // console.log(this.term_rows);
                    // console.log(this.repayment_rows);
                    // this.isLoading = false;
                    // this.isCalculating = false,
                    // this.calculate = "Calculate"
                    // console.log(response.data.data);
                    this.loan_calulator = response.data?.data
                }).catch((error) => {
                    console.log(error)
                    // this.isLoading = false;
                    // this.isCalculating = false,
                    // this.calculate = "Calculate"
                });
        }
        // lppNoPayment() {
        //     switch (this.form.tenor_interval) {
        //         case 'Days':
        //             if (this.form.repayment_cycle === 'Daily') {
        //                 return this.form.tenor;
        //             } else if (this.form.repayment_cycle === 'Weekly') {
        //                 return this.form.tenor / 7;
        //             } else if (this.form.repayment_cycle === 'Monthly') {
        //                 return this.form.tenor / 30.4375
        //             } else if (this.form.repayment_cycle === 'Annually') {
        //                 return this.form.tenor / 365.25;
        //             } else {
        //                 return this.form.tenor
        //             }
        //         case 'Weeks':
        //             if (this.form.repayment_cycle === 'Daily') {
        //                 return this.form.tenor * 7;
        //             } else if (this.form.repayment_cycle === 'Weekly') {
        //                 return this.form.tenor;
        //             } else if (this.form.repayment_cycle === 'Monthly') {
        //                 return this.form.tenor * 4.348
        //             } else if (this.form.repayment_cycle === 'Annually') {
        //                 return this.form.tenor * 52.1785;
        //             } else {
        //                 return this.form.tenor
        //             }
        //         case 'Months':
        //             if (this.form.repayment_cycle === 'Daily') {
        //                 return this.form.tenor * 365.25 / 12;
        //             } else if (this.form.repayment_cycle === 'Weekly') {
        //                 return this.form.tenor * 52.1785 / 12;
        //             } else if (this.form.repayment_cycle === 'Monthly') {
        //                 return this.form.tenor
        //             } else if (this.form.repayment_cycle === 'Annually') {
        //                 return this.form.tenor * 12;
        //             } else {
        //                 return this.form.tenor
        //             }
        //         case 'Years':
        //             if (this.form.repayment_cycle === 'Daily') {
        //                 return this.form.tenor * 365.25;
        //             } else if (this.form.repayment_cycle === 'Weekly') {
        //                 return this.form.tenor * 52.1785;
        //             } else if (this.form.repayment_cycle === 'Monthly') {
        //                 return this.form.tenor * 12
        //             } else if (this.form.repayment_cycle === 'Annually') {
        //                 return this.form.tenor;
        //             } else {
        //                 return this.form.tenor
        //             }


        //     }
        //     // if (this.form.tenor_interval === 'Days') {
        //     //     if (this.form.repayment_cycle === 'Daily') {
        //     //         return $tenor;
        //     //     } else
        //     // }
        // }
    },
    computed: {
    },
}
</script>