<template>
    <div class="">
        <div v-if="isLoading" class="col-12">
            <!-- begin loader -->
            <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
            <!-- end loader -->
        </div>
        <div v-else class="row">
            <div class="col-md-12">
                <b-tabs justified>
                    <b-tab title="Personal Details" active class="text-xs">
                        <div class="col-md-12">
                            <b-card-group deck>
                                <b-card>
                                    <!-- <template #header>
                                        <div>
                                            <h6 class="m-0 fw-bold text-green">Personal Details</h6>
                                        </div>
                                    </template> -->
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col text-center">
                                                <img v-bind:src="form.passport_pic_path" alt="Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">Passport Pic</p>
                                            </div>
                                            <div class="col text-center">
                                                <img v-if="imageLoaded" v-bind:src="frontImgUrl" alt="Image"
                                                    @load="handleImageLoad" style="height:200px"
                                                    class="img-fluid text-center" />
                                                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">GH Card - Front</p>
                                            </div>
                                            <div class="col text-center">
                                                <img v-if="imageLoaded" v-bind:src="backImgUrl" alt="Image"
                                                    @load="handleImageLoad" style="height:200px"
                                                    class="img-fluid text-center" />
                                                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">GH Card - Back</p>
                                            </div>

                                            <div class="col text-center">
                                                <img v-bind:src="form.signature_image_path" alt="Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">Signature</p>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-1">
                                                <label class="akkurate-dark" for="title">Title</label>
                                                <b-form-group id="title" label-for="title">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.title" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-3">
                                                <label class="akkurate-dark" for="search_term">First Name</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.first_name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Middle Name</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.middlename" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Surname</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.last_name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Gender</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.gender" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Cust. Code</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.code" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Ext. Cust. Code</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.external_code" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="dob">Date of Birth</label>
                                                <b-form-group id="dob" label-for="dob">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.date_of_birth" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="marital_status">Marital Status</label>
                                                <b-form-group id="marital_status" label-for="marital_status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.marital_status" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="email">Email</label>
                                                <b-form-group id="email" label-for="email">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.email" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="mobile">Mobile</label>
                                                <b-form-group id="mobile" label-for="mobile">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.mobile" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Ghana Card Number</label>
                                                <b-form-group id="ghana_card" label-for="ghana_card">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.ghana_card_number" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Status</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.status" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Source</label>
                                                <b-form-group id="source" label-for="source">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.source" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Pin Set</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        :value="form.is_pin_changed == 1 ? 'Yes' : 'No'"
                                                        disabled></b-form-input>
                                                </b-form-group>
                                            </div>


                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="registered_date">Registered
                                                    Date</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        :value="form.registered_date" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-card-group>
                        </div>
                    </b-tab>
                    <b-tab title="Employee Details">
                        <div class="col-12 col-md-12">
                            <div class="card card-shape home-box">
                                <!-- <div class="card-header d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Employer Details</h6>
                                </div> -->
                                <div class="card-body">
                                    <employer-details :form="form" :add_or_edit="add_or_edit"></employer-details>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Financial Info">
                        <div class="col-12 col-md-12">
                            <div class="card card-shape home-box">
                                <!-- <div class="card-header d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Financial Information</h6>
                                </div> -->
                                <div class="card-body">
                                    <financial-information :form="form"
                                        :add_or_edit="add_or_edit">
                                    </financial-information>
                                </div>
                            </div>
                        </div>

                    </b-tab>
                    <b-tab title="Address">
                        <div class="col-md-12">
                            <b-card-group deck>
                                <b-card>
                                    <!-- <template #header>
                                        <div>
                                            <h6 class="m-0 fw-bold text-green">Address</h6>
                                        </div>
                                    </template> -->
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <b-form-group label-for="address" class="mb-2">
                                                    <template #label>
                                                        <label class="akkurate-dark" for="address">Address <star></star>
                                                        </label>
                                                    </template>
                                                    <b-form-textarea class="mt-2 fields" id="address"
                                                        v-model="form.address" disabled></b-form-textarea>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="digital_address">Digital Address
                                                    <star></star>
                                                </label>
                                                <b-form-group id="digital_address" label-for="digital_address">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.digital_address" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Region</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="region.name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Home Town</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.home_town" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Branch</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="branch.name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </b-card-body>


                                </b-card>
                            </b-card-group>
                        </div>
                    </b-tab>
                    <b-tab title="Account">
                        <div class="card">
                            <div class="card-body">
                                <vue-good-table :columns="account_columns" :rows="form.accounts">
                                    <!-- Add your custom delete button column -->
                                    <template slot="table-row" slot-scope="props">
                                        <span class="d-flex" v-if="props.column.field == 'account_balance'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ displayNumber(props.row.loan.interest_balance +
                                                    props.row.loan.principal_balance) }}
                                            </span>
                                            <span v-else>
                                                {{ displayNumber(props.row.account_balance) }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'account_type.name'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ props.row.loan.loan_product.name }}
                                            </span>
                                            <span v-else>
                                                {{ props.row.account_type.name }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'account_number'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ props.row.loan.loan_number }}
                                            </span>
                                            <span v-else>
                                                {{ props.row.account_number }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'action'">
                                            <a :title="props.row.account_type.base_type + ' Statement'"
                                                v-if="props.row.account_type.base_type == 'Savings'"
                                                class="btn btn-sm akkurate-gray-btn text-white"
                                                v-b-modal="String(props.row?.id + 'account_statement')">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'book']" /></span>
                                            </a>
                                            <account-statement :data="props.row"
                                                :institution="institution"></account-statement>
                                            <a :title="props.row.account_type.base_type + ' Statement'"
                                                v-if="props.row.account_type.base_type == 'Loan'"
                                                class="btn btn-sm akkurate-gray-btn text-white"
                                                v-b-modal="String(props.row?.id + 'statement')">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'book']" /></span>
                                            </a>
                                            <loan-statement :data="props.row" :statement="'statement'"
                                                :institution="institution" :branch="props.row?.branch"></loan-statement>

                                        </span>

                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Beneficiaries">
                        <div class="card">
                            <div class="card-body">
                                <vue-good-table :columns="next_columns" :rows="form.beneficiaries">
                                    <!-- Add your custom delete button column -->
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'action'" class="d-flex">
                                            <edit-next-of-kin :form="props.row"
                                                :edit-nextofkin="nextofkin"></edit-next-of-kin>
                                            <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                                                @click="confirmDeleteUser(props.row)">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'trash']" /></span>
                                            </a>
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Transactions">
                        <div class="card">
                            <div class="card-body">

                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import ApiService from "@/core/services/api.service";
import EditNextOfKin from '../edit/modal/EditNextOfKin.vue'
import AccountStatement from '../accounts/statement/AccountStatement.vue'
import LoanStatement from '../../loan/statements/LoanStatement.vue';
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import EmployerDetails from "../forms/EmployerDetails.vue";
import FinancialInformation from "../forms/FinancialInformation";


export default {
    components: {
        SidebarView,
        TopbarView,
        EditNextOfKin,
        BeatLoaderComponent,
        AccountStatement,
        LoanStatement,
        EmployerDetails,
        FinancialInformation,
    },
    created() {
        this.getMemberView();
        this.getInsistution();
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            // id: this.$route.params.id,
            add_or_edit: 'view',
            form: {
                id: 0,
                code: 0,
                first_name: "",
                middlename: "",
                last_name: "",
                title: "",
                email: "",
                mobile: "",
                gender: "",
                date_of_birth: "",
                marital_status: "",
                address: "",
                digital_addres: "",
                home_town: "",
                landmark: "",
                employer_name: "",
                ghana_card_number: "",
                status: "",

                employer_address: null,
                employer_mobile: null,
                occupation: null,
                employee_position: null,
                source_of_funds: null,
                bankers_name: null,
                bankers_address: null,
                bankers_location: null,
                monthly_salary: null,
                signature_image_path: null,
                signature_image_name: null,
                passport_pic_path: null,
                passport_pic_name: null,
                note: null,
                mandate: null,
                errors: [],

            },
            branch: [],
            region: [],
            institution: "",
            imageLoaded: false,
            isLoading: false,

            //Accounts
            account_columns: [
                // {
                //     label: 'Account ID',
                //     field: 'id',
                // },
                {
                    label: 'Account Type Name',
                    field: 'account_type.name',
                },
                {
                    label: 'Account No. /Loan No.',
                    field: 'account_number',
                },
                {
                    label: 'Balance',
                    field: 'account_balance',
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                    type: 'string',
                },
            ],
            account_rows: [],

            //Beneficiaries
            next_columns: [
                {
                    label: '#',
                    field: 'id',
                },
                {
                    label: 'Surname',
                    field: 'surname',
                },
                {
                    label: 'Other names',
                    field: 'first_name',
                },
                {
                    label: 'Email',
                    field: 'email',
                    type: 'email',
                },
                {
                    label: 'Phone number',
                    field: 'phone_number',
                },
                {
                    label: 'Relation',
                    field: 'relation',
                    type: 'string',
                },
                {
                    label: 'Address',
                    field: 'address',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                    type: 'string',
                },
            ],
            next_rows: [],
        };
    },
    methods: {
        async getMemberView() {
            this.isLoading = true;
            await ApiService.get("/customers/view/" + this.id)
                .then(response => {
                    // console.log(response.data.data);
                    this.form = response.data.data;
                    this.region = response.data.data.region != null ? response.data.data.branch : "";
                    this.branch = response.data.data.branch != null ? response.data.data.branch : "";
                    this.handleImageLoad();
                    this.isLoading = false;
                    // console.log(this.form);
                    // this.forms = response.data.data;
                    // this.per_page = response.data.meta.per_page;
                    // console.log(response.data.meta.per_page)
                    // this.totalRecords = response.data.meta.total;

                }).catch((error) => {
                    this.isLoading = false;
                    swal.fire({
                        // title: response.statusText,
                        text: error.response.data.message,
                        icon: "error",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    // this.isLoading = false
                });
        },
        nextofkin() {
            this.getMemberView();
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        handleImageLoad() {
            // console.log(this.form?.gh_card_front_path);
            if (this.form?.gh_card_front_path) {
                this.imageLoaded = true;
                this.frontImgUrl = this.form?.gh_card_front_path;
            }
            if (this.form?.gh_card_back_path) {
                this.imageLoaded = true;
                this.backImgUrl = this.form?.gh_card_back_path;
            }


        },

    }
}
</script>